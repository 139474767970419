import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography
} from '@material-ui/core';
import { EcomOrder } from 'src/types';
import { slices, useAppSelector } from 'src/redux';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { formatCurrency, getOrderStatusColor } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/constants';

const { selectors: ecomOrderSelectors } = slices.ecomOrder;

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  imgPreview: {
    width: undefined,
    height: 50
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

const component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const ecomOrders = useAppSelector(ecomOrderSelectors.selectEcomOrdersList);

  const onOrderPress = (item: EcomOrder) => {
    navigate(`/app/ecom-order/${item?.order_ref_no}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Order Ref No.</TableCell>
                <TableCell>Transaction No.</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Last Updated By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ecomOrders?.map((item: EcomOrder, i: number) => (
                <AlternatingColorTableRow hover key={i}>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onOrderPress(item)}
                      href={`/app/ecom-order/${item?.order_ref_no}`}
                      title={`${item?.id}`}
                    />
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onOrderPress(item)}
                      href={`/app/ecom-order/${item?.order_ref_no}`}
                      title={`${item?.order_ref_no}`}
                    />
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onOrderPress(item)}
                      openInNewTab
                      href={`/app/transaction/${item?.transaction_no}`}
                      title={`${item?.transaction_no}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>{item?.customer_name}</Typography>
                    <Typography>{item?.email}</Typography>
                    <Typography>{item?.contact_no}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {formatCurrency(item?.grand_total_amount)}
                    </Typography>
                    {item?.total_amount ===
                    item?.current_total_amount ? null : (
                      <Typography>
                        current total amount:{' '}
                        {formatCurrency(item?.current_total_amount)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: getOrderStatusColor(item?.status)
                      }}
                    >
                      {item?.status}
                    </Typography>
                  </TableCell>
                  <TableCell>{item?.created_date}</TableCell>
                  <TableCell>
                    {item?.updated_by_name ?? item.updated_by}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const EcomOrdersListTable = React.memo(component);
