import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, FixMeLater } from 'src/types';
import { GetLocationPayload, GetLocationResponse } from './types';
import { google_map_key } from 'src/constants/map-location';

export const getLatAndLngLocationThunk = createAsyncThunk<
  CommonAxiosResponse<FixMeLater>,
  string,
  { state: RootState }
>('location/getLatAndLngLocationThunk', async (address) => {
  const response = await Api.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${google_map_key}`
  );
  return response;
});

export const getRegionsThunk = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload,
  { state: RootState }
>('location/getRegionsThunk', async (data) => {
  const response = await Api.get(`/ecomm/region/get`, {
    ...data,
    limit: 500
  });
  return response;
});

export const getProvincesThunk = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload,
  { state: RootState }
>('location/getProvincesThunk', async (data) => {
  const response = await Api.get(`/ecomm/province/get`, {
    ...data,
    limit: 500
  });
  return response;
});

export const getCitiesThunk = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload,
  { state: RootState }
>('location/getCitiesThunk', async (data) => {
  const response = await Api.get(`/ecomm/city/get`, {
    ...data,
    limit: 500
  });
  return response;
});

export const getBarangaysThunk = createAsyncThunk<
  CommonAxiosResponse<GetLocationResponse>,
  GetLocationPayload,
  { state: RootState }
>('location/getBarangaysThunk', async (data) => {
  const response = await Api.get(`/ecomm/barangay/get`, {
    ...data,
    limit: 500
  });
  return response;
});
