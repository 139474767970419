import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { BranchListDropDown } from 'src/components/dropdown';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';
import { Search as SearchIcon } from 'react-feather';
import { DatePickerRangeComponent } from 'src/components';
import {
  buildQueryString,
  dateToday,
  parseSearch,
  transformerDateField
} from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GetInventoryAuditLogsListingsPayload } from 'src/redux/slices/inventory-audit-logs';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Category } from 'src/types';

interface Props {
  onHandleApplyFilter: (params: GetInventoryAuditLogsListingsPayload) => void;
}

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

const component: FC<Props> = ({ onHandleApplyFilter }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { search } = location;
  const categories = useAppSelector(categorySelectors.selectCategories);

  const [ialKeyword, setIalKeyword] = useState<string>('');
  //TODO: convert later into redux or hooks
  const [logsFilter, setLogsFilter] = useState<
    GetInventoryAuditLogsListingsPayload
  >({});
  const [branchIDs, setBranchIDs] = useState<number[]>([]);
  const [category, setCategory] = useState<Category>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();

  const onHandleIalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogsFilter({ ...logsFilter, ial_no: event?.target?.value });
  };

  const onApplyFilter = useCallback(() => {
    onHandleApplyFilter(logsFilter);
    navigate({ search: buildQueryString(logsFilter) });
  }, [logsFilter, navigate, onHandleApplyFilter]);

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | null, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        setLogsFilter((prev) => ({ ...prev, to_date: transformedDate }));
        setToDate(transformedDate);
      }
      if (field === 'from') {
        setLogsFilter((prev) => ({ ...prev, from_date: transformedDate }));
        setFromDate(transformedDate);
      }
    },
    []
  );

  const handleParams = useCallback(() => {
    setIsLoading(true);
    const params = parseSearch(search);
    const { branch_id } = params;
    let branches = [];
    branch_id && branches.push(+branch_id);

    if (isEmpty(params)) {
      setIsLoading(false);
      return;
    }

    if (params.category_id) {
      const defaultCateg = categories.find((i) => i.id === +params.category_id);
      setCategory(defaultCateg);
    }

    const ial = params?.ial_no || '';

    params.from_date && setFromDate(String(params.from_date));
    params.to_date && setToDate(String(params.to_date));
    // always set ial no. so error can work properly
    setLogsFilter({ ...params, ial_no: String(ial) });
    setIalKeyword(String(ial));
    setBranchIDs(branches);

    // delay to set default values before initializing component
    setTimeout(() => setIsLoading(false), 200);
  }, [categories, search]);

  const getCategories = useCallback(async () => {
    try {
      await dispatch(categoryActions.getCategoriesThunk({ keyword: '' }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    categories[0] && handleParams();
  }, [categories, handleParams]);

  return (
    <Card style={{ padding: '1rem' }}>
      {!isLoading && (
        <Grid
          container
          spacing={3}
          style={{ justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Grid item xs={12} md={3}>
            <TextField
              onChange={onHandleIalChange}
              defaultValue={ialKeyword}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search IAL No. "
              variant="outlined"
              // onKeyPress={onKeyPressEnter}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <div style={{ marginLeft: -9 }}>
              <BranchListDropDown
                defaultValue={branchIDs}
                allowNoSelectedBranch={false}
                isElevated={false}
                onHandleBranchChange={(branchIds?: number[]) =>
                  setLogsFilter((prev) => ({
                    ...prev,
                    branch_id: branchIds && branchIds[0]
                  }))
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <CategoryListDropdown
              defaultValue={category}
              onHandleCategories={(categoryId: number) => {
                setLogsFilter((prev) => ({
                  ...prev,
                  category_id: categoryId
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ mt: -1 }}>
              <DatePickerRangeComponent
                fromDateMax={dateToday()}
                toDateMin={fromDate ? fromDate : undefined}
                fromDateValue={fromDate || null}
                toDateValue={toDate || null}
                dateToLabel="To Date"
                dateFromLabel="From Date"
                onChangeToDate={(date) => {
                  onChangedDate(date, 'to');
                }}
                onChangeFromDate={(date) => {
                  onChangedDate(date, 'from');
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={onApplyFilter}
              style={{
                marginTop: 5,
                height: '55px'
              }}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

export const Toolbar = memo(component);
