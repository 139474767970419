import React, { FC, useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Box,
  Button,
  Card,
  Grid,
  Popper,
  TextField,
  Typography
} from '@material-ui/core';
import { slices, useAppDispatch } from 'src/redux';
import { usePermissions } from 'src/hooks';
import AddIconAdornment from 'src/views/pc-bundle/components/AddIconAdornment';
import useResolution from 'src/hooks/useResolution';

type MouseEvent = React.MouseEvent<HTMLElement>;

interface Props {
  selectedType: string;
  setSelectedType: (val: string) => void;
}

const { actions: transactionPaymentActions } = slices.transactionPayments;

export const PaymentTypes: FC<Props> = ({ selectedType, setSelectedType }) => {
  const { canAddTransactionPaymentType } = usePermissions();
  const { isMobile } = useResolution();

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [paymentTypes, setPaymentTypes] = useState<string[]>([]);
  const [filteredTypes, setFilteredTypes] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [newPayment, setNewPayment] = useState<string>('');

  const onTogglePopper = (e: MouseEvent) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const onChangeNewPayment = (e: any) => {
    const { value } = e.target;
    if (value !== ' ') {
      setNewPayment(value);
    }
  };

  const onChangeKeyword = (e: any) => {
    const { value } = e.target;
    if (value) {
      setFilteredTypes(
        paymentTypes.filter((i) => i.includes(nameToValue(e.target.value)))
      );
    } else {
      setFilteredTypes(paymentTypes);
    }
    setKeyword(value);
  };

  const onSavePaymentType = async () => {
    const newType = nameToValue(newPayment);
    const isUnique = paymentTypes.every((i) => i !== newType);
    if (newPayment && newPayment !== ' ' && isUnique) {
      const updatedTypes = [newType, ...paymentTypes];
      const res = unwrapResult(
        await dispatch(
          transactionPaymentActions.updatePaymentTypesThunk({
            value: updatedTypes.join(',')
          })
        )
      );

      if (res.success) {
        getPaymentTypes();
        onSelectPayment(newType);
        setNewPayment('');
      } else {
        console.error('Failed to save Payment type');
      }
    }
  };

  const valueToName = (val: string) => {
    return val.replace(/_/g, ' ');
  };

  const nameToValue = (val: string) => {
    return val
      .trim()
      .replace(/\s/g, '_')
      .toUpperCase();
  };

  const getPaymentTypes = useCallback(async () => {
    const res = unwrapResult(
      await dispatch(transactionPaymentActions.getPaymentTypesThunk())
    );
    if (res.success) {
      setPaymentTypes(res.originalData.data?.split(',') ?? []);
    }
  }, [dispatch]);

  const onEnterPayment = (e: any) => {
    if (e.key === 'Enter') {
      onSavePaymentType();
    }
  };

  const onSelectPayment = (val: string) => {
    setSelectedType(val);
    setAnchorEl(null);
  };

  const onClickPayment = (val: string) => {
    onSelectPayment(val);
  };

  useEffect(() => {
    !paymentTypes[0] && getPaymentTypes();
    paymentTypes[0] && setFilteredTypes(paymentTypes);
  }, [getPaymentTypes, paymentTypes]);

  return (
    <div>
      <Button
        onClick={onTogglePopper}
        color="primary"
        variant="outlined"
        style={{ width: '15em', height: '4em' }}
      >
        {selectedType ? valueToName(selectedType) : 'Select Payment Type'}
      </Button>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        disablePortal
        style={{ zIndex: 2 }} // prevent  elements behind popper content from showing
      >
        <Box
          style={{
            background: 'white',
            padding: '1em',
            marginTop: '1em',
            boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '1em',
            ...(isMobile && {
              position: 'absolute',
              top: -20,
              left: -100
            })
          }}
        >
          <TextField
            fullWidth
            value={keyword}
            variant="outlined"
            placeholder="Search Payment Type"
            onChange={onChangeKeyword}
          />
          {paymentTypes[0] && (
            <>
              <Grid
                container
                spacing={1}
                style={{
                  marginTop: '1em',
                  background: 'white',
                  flexWrap: 'wrap',
                  width: '17em',
                  maxHeight: '10em',
                  overflowY: 'auto'
                }}
              >
                {filteredTypes.map((i) => (
                  <Grid
                    item
                    xs={12}
                    key={i}
                    style={{
                      display: 'flex',
                      columnGap: '.2em',
                      alignItems: 'center'
                    }}
                  >
                    <Card
                      style={{
                        padding: '.2em',
                        display: 'flex',
                        justifyContent: 'center',
                        flex: 1,
                        cursor: 'pointer'
                      }}
                      onClick={() => onClickPayment(i)}
                    >
                      <div style={{ maxWidth: '70%' }}>
                        <Typography
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {valueToName(i)}
                        </Typography>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {canAddTransactionPaymentType && (
            <TextField
              fullWidth
              value={newPayment}
              variant="outlined"
              placeholder="Add Payment Type"
              onChange={onChangeNewPayment}
              onKeyDown={onEnterPayment}
              style={{ marginTop: '1em' }}
              InputProps={{
                endAdornment: <AddIconAdornment onClick={onSavePaymentType} />
              }}
            />
          )}
        </Box>
      </Popper>
    </div>
  );
};
