/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DeleteDialog, Page } from 'src/components';
import { SupabaseClientService } from 'src/services';
import { useAppDispatch } from 'src/redux';
import {
  TapTapCustomerData,
  TapTapCustomerPayment
} from 'src/redux/slices/taptap-customer/types';
import { taptapGetProfilesThunk } from 'src/redux/slices/taptap-customer';
import CustomerQueueItem from './components/CustomerQueueItem';

export type QueueCustomer = {
  id?: number;
  uuid?: string;
  ticket_no?: string;
  first_name?: string;
  last_name?: string;
  contact_no?: string;
  address?: string;
  initial_concern?: string;
  created_at?: string;
  branch_released?: string;
};

const useStyles = makeStyles(() => ({
  root: {},
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  clearText: {
    color: 'rgb(244, 67, 54)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

// const {
//   selectors: taptapCustomerSelectors,
//   actions: taptapCustomerActions
// } = slices.taptapCustomer;

const CustomersQueueingView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [queueingCustomers, setQueueingCustomers] = React.useState<
    QueueCustomer[]
  >([]);
  const [isCustomersLoading, setIsCustomersLoading] = React.useState<boolean>();
  const [forDeleteCustomerData, setForDeleteCustomerData] = React.useState<
    QueueCustomer
  >();
  // const isCustomersLoading = useAppSelector(

  // const customers = useAppSelector(taptapCustomerSelectors.selectCustomers);
  // const isCustomersLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerLoading
  // );
  // const isNextPageLoading = useAppSelector(
  //   taptapCustomerSelectors.selectTaptapCustomerNextPageLoading
  // );
  /** ============================
   * START OF SUPABASE STUFF
   ============================ */

  //  TODO: Fix any
  const onUpdateLocalCustomerPaymentDetail = async (data: any) => {
    const updatedCustomerPaymentData: TapTapCustomerPayment = data?.new ?? {};
    // dispatch(
    //   taptapCustomerActions.updateTaptapCustomerPaymentDetails(
    //     updatedCustomerPaymentData
    //   )
    // );
  };

  const onDeleteLocalCustomerPayment = (data: any) => {
    const deletedCustomerData: TapTapCustomerData = data?.old ?? {};
    // dispatch(
    //   taptapCustomerActions.removeTaptapCustomerPayment(deletedCustomerData)
    // );
  };

  //  TODO: Fix any
  // const onUpdateLocalCustomerDetail = async (data: any) => {
  //   const updatedCustomerData: TapTapCustomerData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.updateTaptapCustomer(updatedCustomerData));
  // };

  //  TODO: Fix any
  const onUpdateLocalCustomerPaymentList = async (data: any) => {
    const newCustomerPaymentData: TapTapCustomerPayment = data?.new ?? {};

    // dispatch(
    //   taptapCustomerActions.insertNewTaptapCustomerPayment(
    //     newCustomerPaymentData
    //   )
    // );
  };

  // const onInsertOnLocalCustomerList = async (data: any) => {
  //   const newCustomerData: TapTapCustomerData = data?.new ?? {};
  //   dispatch(taptapCustomerActions.insertNewTaptapCustomers(newCustomerData));
  // };

  // const onDeleteLocalCustomer = (data: any) => {
  //   const deletedCustomerData: TapTapCustomerData = data?.old ?? {};
  //   dispatch(taptapCustomerActions.removeTaptapCustomer(deletedCustomerData));
  // };

  /** ============================
   * END OF SUPABASE STUFF
   ============================ */

  /** ============================
   * START OF COMPONENT STUFF
   ============================ */
  const fetchCustomers = async () => {
    setIsCustomersLoading(true);
    // dispatch(taptapCustomerActions.taptapFetchCustomersThunk());

    const { data, error } = await SupabaseClientService.from('queued_customers')
      .select('*')
      .filter('is_deleted', 'eq', false);

    if (error) {
      console.error('Error fetching customers', error);
      alert('Error fetching customers');
      setIsCustomersLoading(false);
      return;
    }

    if (data) {
      setQueueingCustomers(data);
    }
    setIsCustomersLoading(false);
  };

  const fetchCustomersNextPage = () => {
    // if (isCustomersLoading || isNextPageLoading) {
    //   return;
    // }
    // dispatch(taptapCustomerActions.taptapFetchCustomersNextPageThunk());
  };

  const fetchTapTapProfiles = () => {
    dispatch(taptapGetProfilesThunk());
  };

  /** ============================
   * END OF COMPONENT STUFF
   ============================ */

  useEffect(() => {
    fetchCustomers();

    const customersQueueTableChanges = SupabaseClientService.channel(
      'customers_queue_channel'
    ) // TODO: constantize this
      .on(
        'postgres_changes', // TODO: constantize this
        {
          event: '*', // you can specify 'INSERT', 'UPDATE', 'DELETE' etc. // TODO: constantize this
          schema: 'public', // TODO: constantize this
          table: 'queued_customers' // TODO: constantize this
        },
        (payload) => {
          if (payload?.table === 'queued_customers') {
            if (payload?.eventType === 'INSERT') {
              onUpdateLocalCustomerPaymentList(payload);
            }
            if (payload?.eventType === 'UPDATE') {
              // onUpdateLocalCustomerDetail(payload);
              onUpdateLocalCustomerPaymentDetail(payload);
            }
            if (payload?.eventType === 'DELETE') {
              // TODO: Implement this
              onDeleteLocalCustomerPayment(payload);
            }
          }
        }
      )
      .subscribe();

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      SupabaseClientService.removeChannel(customersQueueTableChanges);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const handleScroll = (element: any) => {
    const bottom =
      Math.abs(
        element.target.scrollHeight -
          (element.target.scrollTop + element.target.clientHeight)
      ) <= 1;

    if (bottom) {
      fetchCustomersNextPage();
    }
  };

  const onRemoveCustomerFromQueue = async () => {
    const user = await SupabaseClientService?.auth?.getUser();

    const dataForDeletion = {
      deleted_at: new Date().toISOString(),
      deleted_by: user.data.user?.id,
      is_deleted: true
    };

    const { error } = await SupabaseClientService.from('queued_customers')
      .update(dataForDeletion)
      .eq('id', forDeleteCustomerData?.id)
      .select();

    if (error) {
      console.error('error', error);
      alert('Error removing customer from queue');
      setForDeleteCustomerData(undefined);
      return;
    }

    setForDeleteCustomerData(undefined);
    fetchCustomers();
  };

  return (
    <Page className={classes.root} title="TapTap Customer">
      {/* <LoaderBar isLoading={isCustomersLoading} />
      // <Toolbar /> */}

      <Typography
        color="textPrimary"
        variant="h3"
        style={{ marginLeft: 20, marginTop: 20 }}
      >
        Queued Customers
      </Typography>

      <div style={{ padding: 20 }}>
        <Grid
          onScroll={handleScroll}
          container
          spacing={2}
          style={{
            maxHeight: '85vh',
            overflow: 'auto',
            opacity: isCustomersLoading ? 0.3 : 1
          }}
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          {queueingCustomers?.map((customer: QueueCustomer, index: number) => (
            <CustomerQueueItem
              key={`${index}`}
              customer={customer}
              onDeleteQueueCustomer={() => setForDeleteCustomerData(customer)}
            />
          ))}
        </Grid>
      </div>
      <DeleteDialog
        isVisible={forDeleteCustomerData?.id ? true : false}
        onDelete={onRemoveCustomerFromQueue}
        handleClose={() => setForDeleteCustomerData(undefined)}
        title="Remove Customer from Queue?"
        subTitle={`ARE YOU REALLY SURE YOU WANT TO REMOVE ${forDeleteCustomerData?.first_name?.toUpperCase()} ${forDeleteCustomerData?.last_name?.toUpperCase()} FROM CUSTOMER QUEUE?`}
      />
    </Page>
  );
};

export default CustomersQueueingView;
