import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useCallback, useMemo } from 'react';
import {
  GeocodingResponse,
  LocationTabsType,
  SelectedLocationTab
} from 'src/redux/slices/location/types';
import { google_map_key } from 'src/constants/map-location';
const {
  selectors: locationSelectors,
  actions: locationActions
} = slices.location;

export const useMapLocation = () => {
  const locationTabs: LocationTabsType[] = [
    'Region',
    'Province',
    'City',
    'Barangay'
  ];

  const dispatch = useAppDispatch();
  const regions = useAppSelector(locationSelectors?.selectRegions);
  const provinces = useAppSelector(locationSelectors?.selectProvinces);
  const cities = useAppSelector(locationSelectors?.selectCities);
  const barangays = useAppSelector(locationSelectors?.selectBarangays);

  //this contains usefull data about the selected location
  const locationState = useAppSelector(locationSelectors?.selectLocationState);
  const isLoading = useAppSelector(locationSelectors?.selectIsLoading);

  const completeSelectedLocation = useMemo(
    () =>
      `${locationState?.region?.name}, ${locationState?.province?.name}, ${locationState?.city?.name}, ${locationState?.barangay?.name}`,
    [locationState]
  );

  //fetch all regions
  const resetLocationState = () => {
    dispatch(locationActions?.resetMapLocation());
  };

  const getAddressLatAndLang = useCallback(async (address?: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address || ''
        )}&key=${google_map_key}`
      );
      const data: GeocodingResponse = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        const location = data?.results[0]?.geometry?.location;
        const formatted_address = data?.results[0]?.formatted_address;

        return {
          location,
          formatted_address
        };
      } else {
        console.error('Geocoding failed:', data.status);
        return null;
      }
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
    }
  }, []);

  //fetch all regions
  const fetchRegions = useCallback(async () => {
    try {
      await dispatch(locationActions?.getRegionsThunk({}));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  const fetchProvinces = useCallback(
    async (region_code?: number) => {
      try {
        await dispatch(locationActions?.getProvincesThunk({ region_code }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const fetchCities = useCallback(
    async (province_code?: number) => {
      try {
        await dispatch(locationActions?.getCitiesThunk({ province_code }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const fetchBarangays = useCallback(
    async (city_code?: number) => {
      try {
        await dispatch(locationActions?.getBarangaysThunk({ city_code }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const setLocationTabState = useCallback(
    (location_state: SelectedLocationTab) => {
      dispatch(locationActions?.setTabLocationStateAction(location_state));
    },
    [dispatch]
  );

  return {
    regions,
    provinces,
    cities,
    barangays,
    locationTabs,
    locationState, //contains info for selected location tab
    isLoading,
    completeSelectedLocation,
    fetchRegions,
    fetchProvinces,
    fetchCities,
    fetchBarangays,
    setLocationTabState,
    resetLocationState,
    getAddressLatAndLang
  };
};
