import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  withStyles
} from '@material-ui/core';
import { TapTapCustomerData } from 'src/redux/slices/taptap-customer/types';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { formatDate } from 'src/utils';
import { taptapCustomerField } from 'src/redux/slices/taptap-customer/constants/taptap-fields';
import { colors } from 'src/constants';
import {
  taptapOptionsStatuses,
  taptapStatuses,
  taptapUpdateCustomerViaId
} from 'src/redux/slices/taptap-customer';

interface Props {
  customer: TapTapCustomerData;
}

const StyledSelect = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    paddingLeft: 20,
    marginRight: 0,
    textTransform: 'uppercase'
  },
  icon: {
    color: theme.palette.primary.contrastText // Style the dropdown arrow
  }
}))(Select);

const TapTapForBuildCustomerItem = ({ customer }: Props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };
  const customStatusColor = useMemo(() => {
    const statusBgColor = taptapOptionsStatuses?.find(
      (status) => status.value === customer?.status
    )?.bgColor;

    return statusBgColor || 'inherit';
  }, [customer.status]);

  const updatedCustomerDataOnSupabase = async (
    columnName: string,
    value: any
  ) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const updatedData = { [columnName]: value };

    if (customer?.id) {
      await taptapUpdateCustomerViaId(customer?.id, updatedData);
    }

    setIsLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const onChangeStatus = (value: string) => {
    updatedCustomerDataOnSupabase(taptapCustomerField.STATUS, value);
  };

  const forBuildAllowedStatuses = useMemo(() => {
    // Allowed statues are only. BUT Later on it will change depending on the board.
    return taptapOptionsStatuses?.filter(
      (status) =>
        status.value === taptapStatuses.BUILDING ||
        status.value === taptapStatuses.TESTING ||
        status.value === taptapStatuses.COMPLETED ||
        status.value === taptapStatuses.ON_QUEUE ||
        status.value === taptapStatuses.FOR_FULLPAYMENT ||
        status.value === taptapStatuses.FOR_PACK ||
        status.value === taptapStatuses.READY_FOR_PICKUP
    );
  }, []);

  return (
    <Grid key={customer?.id} item xs={12} md={6}>
      <Card elevation={5}>
        <CardHeader
          style={{ backgroundColor: colors.grey[100] }}
          action={
            <StyledSelect
              variant="standard"
              id={taptapCustomerField.STATUS}
              name={taptapCustomerField.STATUS}
              value={customer?.status}
              style={{ backgroundColor: customStatusColor, borderRadius: 6 }}
              disableUnderline
              onChange={(e) => {
                if (
                  e.target.value &&
                  e.target.value !== customer?.status &&
                  typeof e.target.value === 'string'
                ) {
                  onChangeStatus(e.target.value);
                }
              }}
              inputProps={{ IconComponent: () => null }}
            >
              {forBuildAllowedStatuses?.map((status) => (
                <MenuItem
                  dense
                  key={status.value}
                  value={status.value}
                  style={{ backgroundColor: status.bgColor || 'inherit' }}
                >
                  {status.label}
                </MenuItem>
              ))}
            </StyledSelect>
          }
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <LinkComponent
                openInNewTab
                title={
                  <Typography variant="inherit" style={{ color: colors.link }}>
                    {customer?.first_name} {customer?.last_name}
                  </Typography>
                }
                href={`/app/customer-taptap/${customer?.id}`}
                onClick={() => navigate(`/customer-app/taptap/${customer?.id}`)}
              />

              <LinkComponent
                openInNewTab
                title={
                  <Typography
                    variant="inherit"
                    style={{ marginLeft: 20, color: colors.link }}
                  >
                    {customer?.internal_transaction_no}
                  </Typography>
                }
                href={`/app/transaction/${customer?.internal_transaction_no}`}
                onClick={() =>
                  navigate(
                    `/app/transaction/${customer?.internal_transaction_no}`
                  )
                }
              />
            </div>
          }
          subheader={
            <Typography variant="caption">
              {customer?.branch_released} -{' '}
              {` ${formatDate(customer?.created_at, 'MMM d, yyyy h:mm a')}`}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <Typography
                variant="h6"
                style={{
                  color: customStatusColor
                }}
              >
                {customer?.status}
              </Typography>
              <Typography
                variant="h5"
                style={{ color: customer?.tech ? 'inherit' : colors.error }}
              >
                TECH: {customer?.tech || 'PLEASE ASSIGN A TECH!'}
              </Typography>
              <Typography variant="h6">
                {`${customer?.trial_os ? `${customer?.trial_os} | ` : ''}`}
                {customer?.service_type}
              </Typography>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end'
              }}
            >
              <Typography variant="h6">
                {`${customer?.build_tag ? `${customer?.build_tag} | ` : ''}`}
                {customer?.type_of_build}
              </Typography>
              <Typography variant="h6">
                {`${customer?.source ? `${customer?.source} | ` : ''}`}
                {customer?.courier}
              </Typography>
            </div>
          </div>
          <Typography
            display="inline"
            variant="h4"
            onClick={toggleShowMore}
            style={{
              display: '-webkit-box',
              WebkitLineClamp: showMore ? 'none' : 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            REMARKS: {customer?.remarks || 'N/A'}
          </Typography>
        </CardContent>
        <Divider />
      </Card>
    </Grid>
  );
};

export default TapTapForBuildCustomerItem;
