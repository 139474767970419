import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { taptapFetchCustomers, taptapGetProfiles } from './services';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import {
  GetTapTapCustomersPayload,
  TapTapCustomerData,
  TapTapProfile
} from './types';
import { taptapCustomerField } from './constants/taptap-fields';
import { cloneDeep } from 'lodash';

const getCustomersQueryBuilder = ({
  payloadArg,
  maxRecordCount = 0
}: {
  payloadArg: GetTapTapCustomersPayload;
  maxRecordCount?: number;
}) => {
  const payload = cloneDeep(payloadArg);

  const searchKeyword = payload.search?.trim();
  const logic = payload.filters?.logic || 'and';
  const originalConditions = payload.filters?.conditions || [];

  // Pagination logic
  const pagination = payload.pagination;
  const currentPage = pagination.page;
  const pageSize = pagination?.pageSize;

  // Sorting related
  const sortingPayload = payload.sort || [];

  let query = taptapFetchCustomers();

  // Handle searchKeyword
  if (searchKeyword) {
    const searchConditions = [
      `${taptapCustomerField.FIRST_NAME}.ilike.%${searchKeyword}%`,
      `${taptapCustomerField.LAST_NAME}.ilike.%${searchKeyword}%`,
      `${taptapCustomerField.INTERNAL_TRANSACTION_NO}.ilike.%${searchKeyword}%`,
      `${taptapCustomerField.FULL_NAME}.ilike.%${searchKeyword}%`
    ].join(',');

    query = query.or(searchConditions);
  }

  // Handle "branch_released" condition and remove it from conditions
  const branchReleasedCondition = originalConditions.find(
    (condition) => condition.field === taptapCustomerField.BRANCH_RELEASED
  );

  const filteredConditions = originalConditions.filter(
    (condition) => condition.field !== taptapCustomerField.BRANCH_RELEASED
  );

  if (branchReleasedCondition) {
    const branchReleasedValue = branchReleasedCondition.value;
    query = query.in(taptapCustomerField.BRANCH_RELEASED, branchReleasedValue);
  }

  // Handle filters based on logic (AND/OR)
  if (logic === 'or' && filteredConditions.length > 0) {
    const orConditions = filteredConditions
      .map((filter) => {
        const field = filter?.field?.toLowerCase().replace(/\s/g, '_');
        const operator = filter?.operator;
        const value = filter?.value;

        switch (operator) {
          // case 'like':
          //   return `${field}.like.%${value}%`;
          // case 'ilike':
          // return `${field}.ilike.%${value}%`;

          case 'eq':
          case 'neq':
          case 'lte':
          case 'lt':
          case 'gte':
          case 'gt': // ginagamit and tested
            return `${field}.${operator}.${value}`;
          case 'in': // ginagamit and tested
            return `${field}.in.(${value.join(',')})`;
          default:
            console.error(`Unsupported operator: ${operator}`);
            return null;
        }
      })
      .filter(Boolean) // Remove null or undefined conditions
      .join(',');

    query = query.or(orConditions);
  } else if (logic === 'and' && filteredConditions?.length > 0) {
    filteredConditions.forEach((filter) => {
      const field = filter?.field?.toLowerCase().replace(/\s/g, '_');
      const operator = filter?.operator;
      const value = filter?.value;

      // TINRY KO GAWING DYANAMIC BEFORE TO.. AYAW :'(
      switch (operator) {
        case 'eq':
          query = query.eq(field, value);
          break;
        case 'neq':
          query = query.neq(field, value);
          break;
        case 'is':
          query = query.is(field, value);
          break;
        case 'like':
          query = query.like(field, `%${value}%`);
          break;
        case 'gt': // ginagamit and tested
          query = query.gt(field, value);
          break;
        case 'in': // ginagamit and tested
          query = query.in(field, value);
          break;
        default:
          console.error(`Unsupported operator: ${operator}`);
      }
    });
  }

  // Handle sorting
  if (sortingPayload && sortingPayload.length > 0) {
    sortingPayload.forEach((sort) => {
      query = query.order(sort.field, {
        ascending: sort.direction === 'ASC',
        referencedTable: sort.referencedTable || undefined
      });
    });
  }

  // Handle pagination
  const offset = (currentPage - 1) * pageSize;
  const endRange =
    currentPage === 1
      ? offset + pageSize - 1
      : Math.min(maxRecordCount - 1, offset + pageSize - 1);

  query = query.range(offset, endRange);

  return query;
};

export const taptapFetchCustomersThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersThunk', async (_, thunkApi) => {
  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;

  const response = await getCustomersQueryBuilder({
    payloadArg: filterPayload
  });

  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});

export const taptapFetchCustomersNextPageThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapCustomerData[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapFetchCustomersNextPageThunk', async (_, thunkApi) => {
  const lastPageReached = thunkApi.getState().taptapCustomer.lastPageReached;
  if (lastPageReached) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  const filterPayload = thunkApi.getState().taptapCustomer
    .taptapGetCustomersPayload;
  const clonedFilterPayload = cloneDeep(filterPayload); // cloned becase mutation is not allowed and process wont proceed

  clonedFilterPayload.pagination.page = clonedFilterPayload.pagination.page + 1; // Syempre next page nga eh heheh
  const maxRecordCount = thunkApi.getState().taptapCustomer
    .taptapCustomerMaxCount;

  const response = await getCustomersQueryBuilder({
    payloadArg: clonedFilterPayload,
    maxRecordCount
  });

  return response;
});

export const taptapGetProfilesThunk = createAsyncThunk<
  PostgrestSingleResponse<TapTapProfile[]>,
  undefined,
  { state: RootState }
>('taptapCustomers/taptapGetProfilesThunk', async () => {
  const response = await taptapGetProfiles();
  if (response.error) {
    throw new Error('Failed API Call on taptapFetchCustomersThunk');
  }

  return response;
});
