import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { Page } from 'src/components';
import { GoogleMapComponent } from 'src/components/location';
import { SearchProductDetail, ShippingFeeCard } from 'src/components/shipping';
import {
  NewBranchData,
  ProductsThunkRequest,
  ProductToBeShipCart,
  ShippingInfo,
  TemplateList,
  TemplateProductDetails
} from 'src/types';
import { ProdctsToCalculateList } from 'src/components/shipping/ProductsToCalculate';
import {
  computeShippingFeeJNT,
  computeShippingFeeTechOut,
  getKmDistanceFromPCWorth,
  shippingInfo,
  shippingNotes
} from 'src/constants/map-location';
import { isEmpty, uniqBy } from 'lodash';
import { GoogleLatLng } from 'src/redux/slices/location/types';
import { categories } from 'src/constants';
import { Alert, Autocomplete } from '@material-ui/lab';
import { theme } from 'src/theme';
import { QuotationTemplates } from '../quotation/components';
import TemnplateModules from '../quotation/hooks/TemplatesModule';
import useUserInfo from 'src/hooks/user/use-user-info';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export const ShippingFeeCalculatorPage = () => {
  const classes = useStyles();
  const {
    isLoading,
    onSelectTemplate,
    selectedTemplate,
    onGetTemplateList
  } = TemnplateModules();
  const { defaultValueBranch } = useUserInfo();
  const { userBranches } = useUserInfo();

  const [productsToShip, setProductsToShip] = useState<ProductToBeShipCart[]>(
    []
  );
  const [distanceKmFromPcWorth, setDisatanceKmFromPcWorth] = useState<number>(
    0
  );
  const [shipping_fee, setShippingFee] = useState<ShippingInfo[]>(shippingInfo);
  const [selectedBranch, setSelectedBranch] = useState<number>();
  const [selectedBranchRaw, setSelectedBranchRaw] = useState<number[]>();
  const [originBranch, setOriginBranch] = useState<NewBranchData>();
  const [originLocation, setOriginLocation] = useState<GoogleLatLng>();

  const onHandleAddProductsToShip = (selectedOption?: ProductsThunkRequest) => {
    if (!selectedOption) {
      return;
    }

    const category_name = categories.find(
      (category) => category.id === selectedOption?.category_id
    )?.name;

    const newProduct: ProductToBeShipCart = {
      id: selectedOption?.id,
      name: selectedOption?.name,
      category_id: selectedOption?.category_id,
      category_name: category_name,
      dealers_price: selectedOption?.dealers_price,
      retail_price: selectedOption?.retail_price,
      quantity: 1,
      item_price_base_qty: selectedOption?.retail_price
    };
    const combineProducts = [{ ...newProduct }, ...productsToShip];
    const uniqProducts = uniqBy(combineProducts, 'id');
    setProductsToShip(uniqProducts);
  };

  const onHandleAddQuotationTemplateItems = (
    selectedTemplate?: TemplateProductDetails[] | null | undefined
  ) => {
    if (!selectedTemplate) {
      return;
    }

    const newProducts = selectedTemplate?.map((template) => {
      const category_name = categories.find(
        (category) => category.id === template?.product?.category_id
      )?.name;

      const newProduct: ProductToBeShipCart = {
        id: template?.product?.product_id,
        name: template?.product?.product_name,
        category_id: template?.product?.category_id,
        category_name: category_name,
        dealers_price: template?.product?.retail_price,
        retail_price: template?.product?.retail_price,
        quantity: 1,
        item_price_base_qty: template?.product?.retail_price
      };
      return newProduct;
    });

    const uniqProducts = uniqBy(newProducts, 'id');
    setProductsToShip(uniqProducts);
  };

  const onRemoveProduct = (id?: number) => {
    const filterProducts = productsToShip?.filter(
      (product) => product.id !== id
    );
    setProductsToShip(filterProducts);
  };

  const onChangeMarkedPosition = useCallback(
    async (mark: GoogleLatLng) => {
      if (originLocation) {
        const km = await getKmDistanceFromPCWorth(mark, originLocation);
        setDisatanceKmFromPcWorth(km);
      }
    },
    [originLocation]
  );

  const onQuantityChange = (id?: number, newQuantity?: number) => {
    const newProductsQuantity = productsToShip?.map((product) => {
      if (product?.id === id) {
        return {
          ...product,
          quantity: newQuantity,
          item_price_base_qty:
            product?.retail_price && newQuantity
              ? product?.retail_price * newQuantity
              : product?.retail_price
        };
      }
      return product;
    });

    if (newQuantity === 0) {
      return;
    }

    setProductsToShip(newProductsQuantity);
  };

  const calculateShippingFee = (
    productsToShip: ProductToBeShipCart[],
    distance: number
  ) => {
    const jntShippingFee = computeShippingFeeJNT(productsToShip);
    const techoutShippingFee = computeShippingFeeTechOut(distance);

    const newProductShippingInfo = shippingInfo.map(
      (shipping: ShippingInfo) => {
        if (shipping?.slug === 'tech-out') {
          return {
            ...shipping,
            shipping_fee: techoutShippingFee || 0
          };
        }

        if (shipping?.slug === 'jnt') {
          return {
            ...shipping,
            shipping_fee: jntShippingFee || 0
          };
        }
        return shipping;
      }
    );
    setShippingFee(newProductShippingInfo);
  };

  const onHandleChangeOriginBranch = (value?: NewBranchData) => {
    setOriginBranch(value);
    if (value) {
      setSelectedBranchRaw([value?.id]);
      setSelectedBranch(value?.id);
      onGetTemplateList('', value?.id);
      onSelectTemplate(null);
      setProductsToShip([]);
    }
  };

  const onHandleTemplateChange = (template: TemplateList | null) => {
    onSelectTemplate(template);

    if (!template) {
      setProductsToShip([]);
    }
  };

  useEffect(() => {
    if (productsToShip && distanceKmFromPcWorth) {
      calculateShippingFee(productsToShip, distanceKmFromPcWorth);
    }
  }, [distanceKmFromPcWorth, productsToShip]);

  useEffect(() => {
    if (selectedTemplate) {
      onHandleAddQuotationTemplateItems(selectedTemplate?.products);
    }
  }, [selectedTemplate]);

  //initialize
  useEffect(() => {
    if (userBranches) {
      setOriginBranch(userBranches[0]);
    }
  }, [userBranches]);

  //everytime user set new origin we reset distance and some stuff
  useEffect(() => {
    if (originLocation) {
      setDisatanceKmFromPcWorth(0);
      setProductsToShip([]);
      calculateShippingFee([], 0);
    }
  }, [originLocation]);

  return (
    <Page className={classes.root} title="Shipping Fee Calculator">
      <Container maxWidth={false}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom variant="h3">
            Shipping Fee Calculator
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Paper style={{ padding: '1rem' }}>
              {distanceKmFromPcWorth ? (
                <Typography variant="h3">{`${distanceKmFromPcWorth} km away from ${originBranch?.branch_name}`}</Typography>
              ) : null}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  [theme.breakpoints.down('lg')]: {
                    flexDirection: 'column'
                  },
                  [theme.breakpoints.up('xl')]: {
                    flexDirection: 'row'
                  }
                }}
              >
                {shipping_fee?.map((item, index) => (
                  <ShippingFeeCard
                    key={index}
                    img={item?.img}
                    title={item?.title}
                    details={item?.details}
                    shipping_fee={
                      distanceKmFromPcWorth ? item?.shipping_fee : 0
                    }
                  />
                ))}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={6}>
            {!isEmpty(productsToShip) ? (
              <Alert severity="warning" style={{ marginTop: '1rem' }}>
                <Typography>
                  {`Changing branch or template will remove current item on the list`}
                </Typography>
              </Alert>
            ) : null}
            <Paper style={{ padding: '1rem' }}>
              {userBranches && (
                <Autocomplete
                  defaultValue={userBranches[0]}
                  options={userBranches}
                  getOptionLabel={(option: NewBranchData) =>
                    option?.branch_name
                  }
                  onChange={(event, value: any) =>
                    onHandleChangeOriginBranch(value)
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="Select Origin Branch"
                      variant="outlined"
                    />
                  )}
                />
              )}

              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} style={{ marginTop: '1rem' }}>
                  <SearchProductDetail
                    onOptionSelected={(option?: ProductsThunkRequest) => {
                      onHandleAddProductsToShip(option);
                    }}
                    branch_id={selectedBranchRaw || defaultValueBranch}
                  />
                </Grid>
                <Grid xs={12} item style={{ marginTop: -20 }}>
                  <Typography style={{ textAlign: 'center' }}>
                    {'-- OR --'}
                  </Typography>{' '}
                </Grid>
                <Grid item xs={12} lg={12} style={{ marginTop: -30 }}>
                  <QuotationTemplates
                    selectedTemplate={selectedTemplate ?? null}
                    variant="outlined"
                    onTemplateChange={(template) =>
                      onHandleTemplateChange(template)
                    }
                    branchId={selectedBranch}
                    isCategoryLoading={isLoading}
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                {productsToShip && productsToShip?.length >= 1 ? (
                  <ProdctsToCalculateList
                    products={productsToShip || []}
                    onRemoveProduct={(id?: number) => onRemoveProduct(id)}
                    onHandleQuantity={(id?: number, newQuantity?: number) =>
                      onQuantityChange(id, newQuantity)
                    }
                  />
                ) : (
                  <Alert severity="info" style={{ marginTop: '1rem' }}>
                    {shippingNotes?.map((note, index) => (
                      <Typography key={index}>- {note}</Typography>
                    ))}
                  </Alert>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper style={{ padding: '1rem' }}>
              <Box>
                <GoogleMapComponent
                  newMapLocationOrigin={originBranch}
                  getLatandLng={(mark) => {
                    onChangeMarkedPosition(mark);
                  }}
                  getLatAndLngOrigin={(mark) => setOriginLocation(mark)}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
