import React, { FC, memo } from 'react';
import {
  Card,
  CardContent,
  colors,
  makeStyles,
  Typography
} from '@material-ui/core';
import { CommonReceivedStatusProducts } from 'src/redux/slices/stock-transfer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.up('xs')]: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'lightgray'
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'white'
      }
    }
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    textAlign: 'right'
  }
}));

interface Props {
  data: CommonReceivedStatusProducts;
}

const Component: FC<Props> = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Status:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.status === 'received' ? (
              <CheckCircleIcon style={{ color: colors.green[700] }} />
            ) : (
              <CancelIcon color="secondary" />
            )}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Serial No:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.serial_no || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Product Name:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.product_name || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Category:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.category || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Origin Branch:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.origin_branch || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Processed By:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.processed_by || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            ST No:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.st_no || '---'}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export const ReceivingCard = memo(Component);
