import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { LoaderBar, Page } from 'src/components';
import { ProductsThunkRequest } from 'src/types/product';

import { Products } from './Products';
import { slices, useAppDispatch } from 'src/redux';
import { Toolbar } from './Toolbar';
import { CustomInputEvent } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { scrollToTop } from 'src/utils';
import { FilterStockEnum } from 'src/enums/product/status';

const { actions: productActions } = slices.product;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    // minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [searchInput, setSearchInput] = useState<string>('');
  const [productCategory, setProductCategory] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [products, setProducts] = useState<any[]>([]);
  const [productsLastPage, setProductsLastPage] = useState<number>(1);
  const [filterStocks, setFilterStocks] = useState<FilterStockEnum>(
    FilterStockEnum.All
  );

  const getProducts = useCallback(
    async (request: ProductsThunkRequest) => {
      scrollToTop();
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(productActions.getProductsThunk(request)).finally(() =>
          setIsLoading(false)
        )
      );
      if (response.success && response.originalData?.data) {
        setProducts(response.originalData.data || []);
        if (response.originalData?.last_page) {
          setProductsLastPage(response.originalData?.last_page || 1);
        }
        if (response.originalData?.current_page) {
          setPageNumber(response.originalData?.current_page || 1);
        }
      }
    },
    [dispatch]
  );

  const onSearchProduct = (keyword?: string, category_id?: number) => {
    setPageNumber(1);
    getProducts({
      page_number: 1,
      keyword,
      category_id,
      filter_stocks: filterStocks
    });
  };

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPageNumber(value);
    getProducts({
      page_number: value,
      keyword: searchInput,
      category_id: productCategory,
      filter_stocks: filterStocks
    });
  };

  const onSearchInputChange = (event: CustomInputEvent) => {
    setSearchInput(event.target.value);
  };

  const onCategoryInputChange = (category_id?: number) => {
    setProductCategory(category_id);
  };

  useEffect(() => {
    if (products?.length > 0) {
      return;
    }
    getProducts({});
  }, [getProducts, products.length]);

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar
          searchInput={searchInput}
          categoryId={productCategory}
          filterStocks={filterStocks}
          setFilterStocks={setFilterStocks}
          onSearchProduct={onSearchProduct}
          onSearchInputChange={onSearchInputChange}
          onCategoryChange={onCategoryInputChange}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Products products={products} />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          page={pageNumber}
          onChange={handleChange}
          count={productsLastPage}
        />
      </Box>
    </Page>
  );
};

export default ProductList;
