import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  colors
} from '@material-ui/core';
import { Product } from 'src/types/product';
import { CustomInputEvent, CustomKeyboardEvent, FixMeLater } from 'src/types';
import SaveIcon from '@material-ui/icons/Save';
import { cloneDeep } from 'lodash';
import { slices, useAppDispatch } from 'src/redux';
import {
  AlternatingColorTableRow,
  LinkComponent,
  ListingFreebieChip,
  OutOfStockChip
} from 'src/components';
import { usePermissions } from 'src/hooks';
import { formatCurrency } from 'src/utils';
import useResolution from 'src/hooks/useResolution';
import { AlternateRowContainer } from '../component/AlternateRowContainer';
import { TextRow } from '../component/TextRow';

const { actions: productActions } = slices.product;

interface Props {
  className?: string;
  products: Product[];
}

interface PriceListFieldProps {
  item: Product;
  index: number;
  fullWidth?: boolean;
  width?: string;
}

const useStyles = makeStyles((theme) => ({
  avatar: { marginRight: theme.spacing(2) },
  tableRow: { height: 35, padding: 10 },
  priceInput: { height: 30 },
  lightGrey: { backgroundColor: colors.blueGrey[50] },
  retailPriceCell: { width: 200 },
  mainDetails: {
    display: 'flex',
    rowGap: '.1em',
    flexDirection: 'column',
    padding: '1em'
  }
}));

const component = ({ className, products = [], ...rest }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const inputRef = useRef([]);
  const permissions = usePermissions();
  const { isMobile } = useResolution();

  const [productsState, setProductsState] = useState<Product[]>(products);

  const handleChange = useCallback(
    (event: CustomInputEvent, index: number) => {
      const clonedProducts = cloneDeep(productsState);
      clonedProducts[index].retail_price = +event.target.value;
      setProductsState(clonedProducts);
    },
    [productsState]
  );

  // NOTE: For some reason bringing this to the parent would loop the function.
  const handleSave = useCallback(
    (e: FixMeLater, item: Product) => {
      e.stopPropagation();
      dispatch(productActions.updateProductsThunk(item));
    },
    [dispatch]
  );

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent, item: Product) => {
      if (e.key === 'Enter') {
        handleSave(e, item);
      }
    },
    [handleSave]
  );

  const PriceListField: FC<PriceListFieldProps> = ({
    item,
    index,
    fullWidth = false,
    width = ''
  }) => {
    return (
      <>
        {permissions?.canEditPriceListPrice ? (
          <TextField
            ref={inputRef.current[index]}
            fullWidth={fullWidth}
            className={classes.priceInput}
            onFocus={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => onKeyDown(e, item)}
            type="number"
            name="retail_price"
            onChange={(e) => handleChange(e, index)}
            value={item?.retail_price ?? ''}
            style={{ width: width }}
            InputProps={{
              className: classes.priceInput,
              endAdornment: (
                <IconButton onClick={(e) => handleSave(e, item)}>
                  <SaveIcon />
                </IconButton>
              ),
              startAdornment: (
                <InputAdornment position="start">₱</InputAdornment>
              )
            }}
          />
        ) : (
          formatCurrency(item.retail_price)
        )}
      </>
    );
  };

  useEffect(() => {
    setProductsState(products);
  }, [products]);

  return isMobile ? (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar style={{ padding: '1em' }}>
        {productsState.map((item: Product, i: number) => (
          <AlternateRowContainer key={i} index={i}>
            <div className={classes.mainDetails}>
              <TextRow label="ID" value={String(item.id)} />
              <TextRow
                label="Name"
                isColumn
                component={
                  <>
                    <LinkComponent
                      openInNewTab
                      href={`/app/products/${item.id}`}
                      title={`${item.name ?? '--'}`}
                      font="Roboto, Helvetica, Arial, sans-serif "
                    />
                    <div
                      style={{
                        display: 'flex',
                        columnGap: '1em',
                        alignItems: 'center'
                      }}
                    >
                      {!item?.count ? (
                        <OutOfStockChip style={{ margin: '0px' }} />
                      ) : null}
                      {item?.has_freebie ? (
                        <ListingFreebieChip title="has freebie" />
                      ) : null}
                    </div>
                  </>
                }
              />
              <TextRow label="SKU" value={item.sku} />
              <TextRow
                label="Retail Price"
                component={<PriceListField item={item} index={i} width="8em" />}
              />
              <TextRow label="Category" value={item.category} />
              <TextRow
                noBorderBottom
                label="Available Items"
                value={String(item.count)}
              />
            </div>
          </AlternateRowContainer>
        ))}
      </PerfectScrollbar>
    </Card>
  ) : (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell className={classes.retailPriceCell}>
                  Retail Price
                </TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Available Item(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsState.map((item: Product, i: number) => (
                <AlternatingColorTableRow hover key={item.id}>
                  <TableCell className={classes.tableRow}>
                    {item.id ?? '--'}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    <LinkComponent
                      openInNewTab
                      href={`/app/products/${item.id}`}
                      title={`${item.name ?? '--'}`}
                    />
                    {!item?.count ? <OutOfStockChip /> : null}
                    {item?.has_freebie ? (
                      <ListingFreebieChip
                        chipStyle={{ marginLeft: 10 }}
                        title="has freebie"
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {item.sku ?? '--'}
                  </TableCell>
                  <TableCell
                    className={classes.tableRow}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PriceListField item={item} index={i} fullWidth />
                  </TableCell>

                  <TableCell className={classes.tableRow}>
                    {item.category ?? '--'}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {item.count}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const Products = React.memo(component);
