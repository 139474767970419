export const categories = [
  {
    id: 36,
    name: 'Gaming Chair',
    created_at: '2022-06-15T02:45:58.000000Z',
    updated_at: '2022-06-15T02:45:58.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 35,
    name: 'SERVICE',
    created_at: '2022-05-13T02:07:43.000000Z',
    updated_at: '2022-05-13T02:07:43.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 32,
    name: 'Marketing Materials',
    created_at: '2022-01-21T07:28:31.000000Z',
    updated_at: '2022-04-22T07:20:33.000000Z',
    is_hidden_pricelist: 1
  },
  {
    id: 30,
    name: 'Splitter',
    created_at: '2021-11-26T05:10:59.000000Z',
    updated_at: '2021-11-26T05:10:59.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 29,
    name: 'Fan Hub',
    created_at: '2021-11-18T03:47:05.000000Z',
    updated_at: '2021-11-18T03:47:05.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 28,
    name: 'Headset',
    created_at: '2021-11-06T18:07:34.000000Z',
    updated_at: '2021-11-06T18:07:34.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 27,
    name: 'Accessories',
    created_at: '2021-11-06T18:04:48.000000Z',
    updated_at: '2021-11-06T18:04:48.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 26,
    name: 'CPU Cooler',
    created_at: '2021-11-06T17:19:51.000000Z',
    updated_at: '2021-11-06T17:19:51.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 25,
    name: 'Monitor',
    created_at: '2021-11-04T05:07:54.000000Z',
    updated_at: '2021-11-04T05:07:54.000000Z',
    is_hidden_pricelist: 0
  },
  {
    id: 24,
    name: 'LED Strips',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 23,
    name: 'Cable Wire',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 21,
    name: 'GPU',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 20,
    name: 'Casing',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 19,
    name: 'Fan',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 18,
    name: 'PSU',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 17,
    name: 'Motherboard',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 16,
    name: 'CPU',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 15,
    name: 'HDD',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 14,
    name: 'SSD',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 13,
    name: 'RAM',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 12,
    name: 'HDMI Cable',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 11,
    name: 'UPS',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 10,
    name: 'Patch Cord / LAN Cable',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 9,
    name: 'Thermal Paste',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 8,
    name: 'SATA Cable',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 7,
    name: 'Enclosure',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 6,
    name: 'VGA Cable',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 5,
    name: 'Mouse Pad',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 4,
    name: 'Keyboard and Mouse',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 3,
    name: 'Keyboard',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 2,
    name: 'Mouse',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 1,
    name: 'WiFi Dongle',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  },
  {
    id: 39,
    name: 'Laptop',
    created_at: null,
    updated_at: null,
    is_hidden_pricelist: 0
  }
];

//====constant value for declaring target data for creating main category
export const targetDataCategories = [
  { name: 'Categories', value: 1 },
  { name: 'Manufacturer', value: 2 }
];

export const categoriesShippingFee = [
  {
    id: 27,
    name: 'Accessories',
    shipping_fee: 100
  },
  {
    id: 37,
    name: 'AVR',
    shipping_fee: 100
  },
  {
    id: 23,
    name: 'Cable Wire',
    shipping_fee: 100
  },
  {
    id: 20,
    name: 'Casing',
    shipping_fee: 2000
  },
  {
    id: 16,
    name: 'CPU',
    shipping_fee: 100
  },
  {
    id: 26,
    name: 'CPU Cooler',
    shipping_fee: 300
  },
  {
    id: 47,
    name: 'DP CABLE',
    shipping_fee: 100
  },
  {
    id: 7,
    name: 'Enclosure',
    shipping_fee: 100
  },
  {
    id: 19,
    name: 'Fan',
    shipping_fee: 100
  },
  {
    id: 29,
    name: 'Fan Hub',
    shipping_fee: 100
  },
  {
    id: 41,
    name: 'FLASH DRIVE',
    shipping_fee: 100
  },
  {
    id: 36,
    name: 'Gaming Chair',
    shipping_fee: 3000
  },
  {
    id: 42,
    name: 'GLASS PANEL',
    shipping_fee: 250
  },
  {
    id: 21,
    name: 'GPU',
    shipping_fee: 300
  },
  {
    id: 15,
    name: 'HDD',
    shipping_fee: 100
  },
  {
    id: 12,
    name: 'HDMI Cable',
    shipping_fee: 100
  },
  {
    id: 28,
    name: 'Headset',
    shipping_fee: 100
  },
  {
    id: 3,
    name: 'Keyboard',
    shipping_fee: 150
  },
  {
    id: 4,
    name: 'Keyboard and Mouse',
    shipping_fee: 200
  },
  {
    id: 39,
    name: 'Laptop',
    shipping_fee: 250
  },
  {
    id: 24,
    name: 'LED Strips',
    shipping_fee: 100
  },
  {
    id: 32,
    name: 'Marketing Materials',
    shipping_fee: 250
  },
  {
    id: 40,
    name: 'MICROPHONE',
    shipping_fee: 250
  },
  {
    id: 46,
    name: 'MOBILE WIFI',
    shipping_fee: 100
  },
  {
    id: 25,
    name: 'Monitor',
    shipping_fee: 1000
  },
  {
    id: 17,
    name: 'Motherboard',
    shipping_fee: 200
  },
  {
    id: 2,
    name: 'Mouse',
    shipping_fee: 100
  },
  {
    id: 5,
    name: 'Mouse Pad',
    shipping_fee: 100
  },
  {
    id: 45,
    name: 'NETWORK CONNECTOR',
    shipping_fee: 250
  },
  {
    id: 38,
    name: 'OPERATING SYSTEM',
    shipping_fee: 100
  },
  {
    id: 10,
    name: 'Patch Cord / LAN Cable',
    shipping_fee: 100
  },
  {
    id: 18,
    name: 'PSU',
    shipping_fee: 250
  },
  {
    id: 13,
    name: 'RAM',
    shipping_fee: 100
  },
  {
    id: 44,
    name: 'ROUTER',
    shipping_fee: 250
  },
  {
    id: 8,
    name: 'SATA Cable',
    shipping_fee: 100
  },
  {
    id: 35,
    name: 'SERVICE',
    shipping_fee: 250
  },
  {
    id: 30,
    name: 'Splitter',
    shipping_fee: 100
  },
  {
    id: 14,
    name: 'SSD',
    shipping_fee: 100
  },
  {
    id: 43,
    name: 'SWITCH',
    shipping_fee: 250
  },
  {
    id: 9,
    name: 'Thermal Paste',
    shipping_fee: 100
  },
  {
    id: 11,
    name: 'UPS',
    shipping_fee: 400
  },
  {
    id: 6,
    name: 'VGA Cable',
    shipping_fee: 100
  },
  {
    id: 1,
    name: 'WiFi Dongle',
    shipping_fee: 100
  }
];
