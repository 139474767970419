import { createSelector } from 'reselect';
import { SliceStatusValue } from 'src/constants';
import { RootState } from 'src/redux/store';

const selectUser = (state: RootState) => state.user;

const selectUserInfo = createSelector(selectUser, (state) => state.userInfo);
const selectAllUsers = createSelector(selectUser, (state) => state.users);

const selectRoles = createSelector(
  selectUser,
  (state) => state.userInfo?.all_roles ?? []
);

const selectFavoriteBranch = createSelector(
  selectUser,
  (state) => state.favoriteBranch
);

const selectFormPasscodeDialog = createSelector(
  selectUser,
  (state) => state.formPasscodeDialog
);

const selectValidatePasscodeLoading = createSelector(selectUser, (state) => {
  const isLoading = state?.validatePasscodeStatus === SliceStatusValue.Loading;
  return isLoading;
});

const selectValidatePasscodeSuccess = createSelector(selectUser, (state) => {
  const isSuccess =
    state?.validatePasscodeResponse && state?.validatePasscodeResponse?.success;
  return isSuccess;
});

const selectValidatePasscodeMessage = createSelector(selectUser, (state) => {
  const message = state?.validatePasscodeResponse?.message ?? '';
  return message;
});

const selectValidatePasscodeError = createSelector(selectUser, (state) => {
  const error = state?.validatePasscodeError?.message ?? '';
  return error;
});

export const selectors = {
  selectUserInfo,
  selectRoles,
  selectFavoriteBranch,
  selectAllUsers,
  selectFormPasscodeDialog,
  selectValidatePasscodeLoading,
  selectValidatePasscodeSuccess,
  selectValidatePasscodeMessage,
  selectValidatePasscodeError
};
