/* eslint-disable no-unused-vars */

import React, { CSSProperties, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import {
  CreateTransactionPaymentRequest,
  FixMeLater,
  GetTransactionPaymentsResponse,
  TransactionPaymentCategory
} from 'src/types';
import { PaymentType, colors } from 'src/constants';
import { formatCurrency } from 'src/utils';
import { paymentCategoriesOptions } from 'src/constants';
import { useSnackBar } from 'src/hooks';
import { PaymentCategoryEnum } from 'src/enums/payment';
import { slices } from 'src/redux';
import { PaymentTypes } from './PaymentTypes';
import useResolution from 'src/hooks/useResolution';

const { actions: transactionPaymentActions } = slices.transactionPayments;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paymentFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '1em',
    rowGap: '1em',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  },
  subAmountContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  formContainer: {
    marginTop: theme.spacing(3)
  },
  field: {
    marginTop: theme.spacing(3)
  },
  footerBtn: {
    marginTop: theme.spacing(1)
  }
}));

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  transactionNo?: string;
  onHandleClose: () => void;
  onApplyPress: (data: CreateTransactionPaymentRequest) => void;
  transactionPayments?: GetTransactionPaymentsResponse;
}

export const AddTransactionPaymentModal = ({
  transactionNo = '',
  visible,
  transactionPayments,
  onHandleClose,
  onApplyPress
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const { isMobile } = useResolution();

  const [selectedType, setSelectedType] = useState<string>('');
  const [paymentCategory, setPaymentCategory] = useState<
    TransactionPaymentCategory
  >(PaymentCategoryEnum.Payment);
  const [amount, setAmount] = useState<string>();
  const [acknowledgementReceiptNo, setAcknowledgementReceiptNo] = useState<
    string
  >('');

  const [amountError, setAmountError] = useState<boolean>(false);
  const resetState = () => {
    setAmount(undefined);
    setSelectedType('');
    setAcknowledgementReceiptNo('');
    setPaymentCategory(PaymentCategoryEnum.Payment);
  };

  const onApplyPressInternal = () => {
    const isRefundType = selectedType === PaymentType.Refund;
    if (!selectedType) {
      snackBar.show({ severity: 'error', message: 'Payment type required' });
      return;
    }
    //TODO: if payment has bug uncoment this
    // if (!isRefundType && (!isNormalInteger(amount) || +(amount || 0) <= 0)) {
    if (!isRefundType && +(amount || 0) <= 0) {
      snackBar.show({
        severity: 'error',
        message: 'Please enter a valid amount'
      });
      return;
    }
    if (isRefundType && +(amount ?? 0) >= 0) {
      snackBar.show({
        severity: 'error',
        message: 'Negative value required for refund'
      });
      return;
    }
    const data: CreateTransactionPaymentRequest = {
      transaction_no: transactionNo,
      category: paymentCategory,
      payment_type: selectedType,
      acknowledgement_receipt_no: acknowledgementReceiptNo || '',
      amount: +(amount || 0)
    };

    onApplyPress(data);
    resetState();
  };

  const onCloseInternal = () => {
    resetState();
    onHandleClose();
  };

  useEffect(() => {
    //set default value to amount field
    if (amount === undefined && transactionPayments?.total_balance) {
      setAmount(transactionPayments?.total_balance?.toString());
    }

    if (
      amount &&
      transactionPayments?.total_balance &&
      parseInt(amount) > transactionPayments?.total_balance
    ) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }, [amount, transactionPayments]);

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      style={modalStyle}
    >
      <div
        style={{
          top: isMobile ? '10%' : '20%',
          maxWidth: '100vw'
        }}
        className={classes.body}
      >
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            Add transaction payment inside {transactionNo}
          </Typography>
        </div>
        <div className={classes.subAmountContainer}>
          <Typography variant="subtitle2" style={{ color: colors.blue[600] }}>
            Total Transaction Amount:{' '}
            {formatCurrency(transactionPayments?.total_transaction_amount)}
          </Typography>
          <Typography variant="subtitle2" style={{ color: colors.purple[600] }}>
            Total Paid: {formatCurrency(transactionPayments?.total_paid_amount)}
          </Typography>
          <Typography variant="subtitle2" style={{ color: colors.red[600] }}>
            Total Balance: {formatCurrency(transactionPayments?.total_balance)}
          </Typography>
        </div>
        <div className={classes.formContainer}>
          <div
            className={classes.paymentFieldContainer}
            style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}
          >
            <PaymentTypes
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel>Payment Category</InputLabel>
              <Select
                value={paymentCategory || ''}
                onChange={(e: FixMeLater) =>
                  setPaymentCategory(e?.target?.value || '')
                }
                label="Payment Category"
              >
                {paymentCategoriesOptions?.map((item) => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            fullWidth
            label="Amount"
            className={classes.field}
            name="amount"
            required
            type="number"
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₱</InputAdornment>
              )
            }}
            error={amountError}
            helperText={
              amountError ? 'Amount exceed the total balance to pay' : ''
            }
          />
          <TextField
            fullWidth
            label="Acknowledgement Receipt No."
            className={classes.field}
            name="acknowledgement_receipt_no"
            variant="outlined"
            value={acknowledgementReceiptNo}
            onChange={(e) => setAcknowledgementReceiptNo(e.target.value)}
          />
        </div>

        <Box className={classes.footer}>
          <Button
            fullWidth
            onClick={onApplyPressInternal}
            color="primary"
            variant="contained"
            className={classes.footerBtn}
            disabled={amountError}
          >
            Apply
          </Button>
          <Button
            className={classes.footerBtn}
            fullWidth
            onClick={onCloseInternal}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};
