import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { PaginationV2, UserNotOnBranchData } from 'src/types';
import { Pagination } from '@material-ui/lab';
import useResolution from 'src/hooks/useResolution';

interface Props {
  userNotOnBranchData?: UserNotOnBranchData[];
  metaPage?: PaginationV2;
  isOpen: boolean;
  isLoading?: boolean;
  onHandlePageChange: (pageNum: number) => void;
  onAddUserToBranch: (userIds?: number[]) => void;
  onToggleAddForm?: () => void;
  onCloseToggle: () => void;
  searchUser: (keyword?: string, page?: number) => void;
}

const rowKeys = ['User ID', 'Full Name', 'Username', 'Current Branches'];

const component = ({
  userNotOnBranchData,
  metaPage,
  isOpen,
  isLoading,
  onHandlePageChange,
  onAddUserToBranch,
  onCloseToggle,
  searchUser
}: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useResolution();

  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchUser(e.target.value);
    }
  };

  const handleUnselectAll = () => {
    setSelectedUserIds([]);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    userId?: number | null | undefined
  ) => {
    const isChecked = event.target.checked;
    if (isChecked && userId != null) {
      setSelectedUserIds((prevIds: number[] | undefined) => [
        ...(prevIds ?? []),
        userId
      ]);
    } else {
      setSelectedUserIds((prevIds: number[]) =>
        prevIds?.filter((id) => id !== userId)
      );
    }
  };

  const onHandleAddUser = (userId?: number[]) => {
    onAddUserToBranch(userId ?? []);
    setSelectedUserIds([]);
    setCurrentPage(1);
  };

  const onHandleChangePage = (pageNum: number) => {
    setCurrentPage(pageNum);
    onHandlePageChange(pageNum);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCloseToggle}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <Typography style={{ fontWeight: 'bold' }}>
          Assign New User To This Branch
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card>
          <Box
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'end' : 'space-between',
              padding: '1em',
              rowGap: '1em',
              columnGap: '1em',
              flexWrap: isMobile ? 'wrap' : 'nowrap'
            }}
          >
            <Box style={{ display: 'flex', columnGap: '1em' }}>
              <TextField
                label="Search User"
                variant="outlined"
                onKeyDown={onKeyDown}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onHandleAddUser(selectedUserIds ?? [])}
              style={{ marginRight: 3 }}
            >
              {isLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                `Add User(s)`
              )}
            </Button>
            {selectedUserIds.length > 0 && (
              <Button
                onClick={handleUnselectAll}
                variant="outlined"
                color="primary"
                size="small"
              >
                uncheck all
              </Button>
            )}
          </Box>
        </Card>
        {/* <DialogContentText> */}
        <Typography
          //
          // variant="subtitle1"
          style={{ marginTop: '1em', fontWeight: 'bold' }}
        >
          Available Users
        </Typography>
        {/* </DialogContentText> */}
        <Divider style={{ marginTop: '15px' }} />

        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{''}</TableCell>
                {rowKeys.map((row: string, index: number) => (
                  <TableCell key={index}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userNotOnBranchData?.map((users) => (
                <TableRow key={users?.user_id}>
                  <TableCell scope="row" padding="checkbox">
                    <Checkbox
                      indeterminate={false}
                      checked={selectedUserIds.includes(users?.user_id || 0)}
                      onChange={(event) =>
                        handleCheckboxChange(event, users?.user_id)
                      }
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.user_id}`}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users?.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.full_name}`}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users?.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.username}`}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    {users.current_branches &&
                    users?.current_branches.length > 0 ? (
                      users?.current_branches.join(', ')
                    ) : (
                      <Typography variant="subtitle1" color="secondary">
                        {'No Assign Branch'}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Pagination
          count={metaPage?.last_page}
          page={currentPage}
          onChange={(e: any, p: number) => onHandleChangePage(p)}
        />
      </Box>
    </Dialog>
  );
};

export const AddUserForm = React.memo(component);
