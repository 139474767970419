import React, { FC, memo, useState } from 'react';
import { Button, Card, CardContent, makeStyles } from '@material-ui/core';
import { slices, useAppSelector } from 'src/redux';
import { TransactionSummaryDialog } from './TransactionSummaryDialog';
import { Sale } from 'src/types';
import { TextRow } from 'src/views/product/component/TextRow';
const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.up('xs')]: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'lightgray'
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'white'
      }
    }
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    textAlign: 'right'
  }
}));

const { selectors: salesSelectors } = slices.sales;

interface Props {}
const Component: FC<Props> = () => {
  const classes = useStyles();
  const sales = useAppSelector(salesSelectors.selectAllSales);

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [salesData, setSaleData] = useState<Sale>();

  const onClickViewTransaction = (sale?: Sale) => {
    setIsOpenDialog(true);
    setSaleData(sale);
  };

  return (
    <>
      {sales?.map((sale, index) => (
        <Card key={index} className={classes.card}>
          <CardContent>
            <TextRow
              label="Date Sold(Customer)"
              value={sale?.date_sold || '---'}
            />
            <TextRow label="Branch" value={sale?.branch || '---'} />
            <TextRow label="Customer" value={sale?.customer_name || '---'} />
            <TextRow label="Order No." value={sale?.order_no || '---'} />
            <TextRow
              label="Transaction No."
              value={sale?.transaction_no || '---'}
            />
            <TextRow
              label="AR No."
              value={sale?.acknowledgement_receipt_no || '---'}
            />

            <div className={classes.tableRow}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => onClickViewTransaction(sale)}
              >
                View Transaction Details
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
      <TransactionSummaryDialog
        data={salesData || {}}
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
      />
    </>
  );
};
export const SalesCardListings = memo(Component);
