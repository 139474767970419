import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as productReducer } from './slices/product';
import { reducer as productPartDetails } from './slices/product-part-details';
import { reducer as userReducer } from './slices/user';
import { reducer as supplierReducer } from './slices/supplier';
import { reducer as listingReducer } from './slices/listing';
import { reducer as categoryReducer } from './slices/category';
import { reducer as manufacturerReducer } from './slices/manufacturer';
import { reducer as transactionReducer } from './slices/transaction';
import { reducer as transactionPaymentReducer } from './slices/transaction-payment';
import { reducer as appReducer } from './slices/app';
import { reducer as salesReducer } from './slices/sales';
import { reducer as rmaReducer } from './slices/rma';
import { reducer as roleReducer } from './slices/roles';
import { reducer as permissionReducer } from './slices/permissions';
import { reducer as smsReducer } from './slices/sms';
import { reducer as auditReducer } from './slices/audit';
import { reducer as quotationReducer } from './slices/quotation';
import { reducer as inventoryReducer } from './slices/inventory';
import { reducer as galleryReducer } from './slices/gallery';
import { reducer as ecomOrdersReducer } from './slices/ecom-orders';
import { reducer as flashDealsReducer } from './slices/flash-deals';
import { reducer as bannerReducer } from './slices/ecomm/ecomm-banner';
import { reducer as pcPackageReducer } from './slices/pc-package';
import { reducer as branchesReducer } from './slices/branches';
import { reducer as optionReducer } from './slices/option';
import { reducer as raffleReducer } from './slices/raffle';
import { reducer as pcwUserReducer } from './slices/pcw-user';
import { reducer as kachiPointsReducer } from './slices/kachi-points';
import { reducer as TransferStockReducer } from './slices/stock-transfer';
import { reducer as userBuildsReducer } from './slices/user-builds';
import { reducer as blogsReducer } from './slices/blogs';
import { reducer as blogCategoryReducer } from './slices/blog-category';
import { reducer as inventoryAuditReducer } from './slices/inventory-audit';
import { reducer as pcBundlesReducer } from './slices/pc-bundles';
import { reducer as taptapCustomerReducer } from './slices/taptap-customer';
import { reducer as locationreducer } from './slices/location';
import { reducer as promotionalReducer } from './slices/promotional';

const appConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['openDrawerSectionIndices']
};

const productReducerConfig = {
  key: 'products',
  storage: storage,
  whitelist: ['priceList']
};

const listingsConfig = {
  key: 'listings',
  storage: storage,
  whitelist: ['recentDRNos', 'recentPurchaseDates', 'recentSuppliers']
};

const raffleConfig = {
  key: 'raffle',
  storage: storage,
  whitelist: ['raffleWinners', 'availableRaffleEntries', 'removedRaffleWinners']
};

const pcwUserConfig = {
  key: 'pcwUserAccount',
  storage: storage,
  whitelist: ['userAccountProfileData']
};

const taptapCustomerConfig = {
  key: 'taptapCustomer',
  storage: storage,
  whitelist: [
    'taptapVersion',
    'taptapCustomers',
    'taptapProfiles',
    'taptapColumns',
    'taptapGetCustomersPayload'
  ]
};

export const reducers = combineReducers({
  supplier: supplierReducer,
  quotation: quotationReducer,
  audit: auditReducer,
  product: persistReducer(productReducerConfig, productReducer),
  gallery: galleryReducer,
  productPartDetails: productPartDetails,
  user: userReducer,
  listing: persistReducer(listingsConfig, listingReducer),
  category: categoryReducer,
  manufacturer: manufacturerReducer,
  transaction: transactionReducer,
  transactionPayment: transactionPaymentReducer,
  app: persistReducer(appConfig, appReducer),
  flashDeals: flashDealsReducer,
  sales: salesReducer,
  rma: rmaReducer,
  role: roleReducer,
  permission: permissionReducer,
  sms: smsReducer,
  inventory: inventoryReducer,
  pcPackage: pcPackageReducer,
  branches: branchesReducer,
  options: optionReducer,
  raffle: persistReducer(raffleConfig, raffleReducer),
  pcwUser: persistReducer(pcwUserConfig, pcwUserReducer),
  // pcwUser: pcwUserReducer,

  // ecomm
  ecomOrders: ecomOrdersReducer,
  banner: bannerReducer,
  kachiPoints: kachiPointsReducer,
  userBuilds: userBuildsReducer,

  //transfer stock
  transferStock: TransferStockReducer,

  //blogs
  blogs: blogsReducer,
  blogCategory: blogCategoryReducer,

  //inventory audit
  inventoryAudit: inventoryAuditReducer,

  //pc bundles
  pcBundles: pcBundlesReducer,

  taptapCustomer: persistReducer(taptapCustomerConfig, taptapCustomerReducer),
  location: locationreducer,
  //promotional
  promotional: promotionalReducer
});
