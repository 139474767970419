import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { FixMeLaterApi, Listing } from 'src/types';
import { formatCurrency } from 'src/utils';
import { Product } from 'src/types/product';
import { usePermissions, useProductPartDetailsLogic } from 'src/hooks';
import { Page } from 'src/components';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { TransferStockListingDialog } from './ProductTransferListingDialog';
import { useIsMobile } from 'src/hooks/use-is-mobile';

interface Props {
  product: Product;
  className?: string;
  listings?: Listing[];
  onDeleteProduct: (product: FixMeLaterApi) => void;
  onSaveDetailPress: (product: Product) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  addItemBtn: {
    color: 'white',
    marginBottom: '1em',
    background: '#ff9800',
    '&:hover': {
      backgroundColor: '#ff9800',
      color: 'white'
    },
    '&:active': {
      backgroundColor: '#ff9800',
      color: 'white'
    }
  }
}));

const ProductDetailView = ({
  className,
  listings,
  product,
  ...rest
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { resetProductPartsStates } = useProductPartDetailsLogic();
  const {
    transferCount,
    transferCountListing,
    getTransferCount,
    getTransferCountListings
  } = useStockTransfer();
  const { viewTransferListingStock } = usePermissions();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onAddListingItemPress = () => {
    const sampleSerialNos = listings
      ?.map((listing) => listing?.serial_no)
      ?.filter((serialNo) => serialNo)
      ?.splice(0, 3);

    navigate('/app/products-listings/add', {
      state: { ...product, sample_serial_nos: sampleSerialNos }
    });
  };

  const onEditProductInformationPress = () => {
    navigate(`/app/products/information/${product?.id}`);
  };

  const handleTransferStockOpen = useCallback(() => {
    setIsOpen(true);
    getTransferCountListings(product?.id);
  }, [getTransferCountListings, product]);

  useEffect(() => {
    getTransferCount(product?.id);
  }, [getTransferCount, getTransferCountListings, product.id]);

  useEffect(() => {
    return () => {
      resetProductPartsStates();
    };
  }, [resetProductPartsStates]);

  return (
    <Page className={classes.root} title={`${product?.name || ''}`}>
      <Box mt={2}>
        <Card className={clsx(classes.root, className)} {...rest}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <Box>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                  >
                    {product?.name ?? ''}
                  </Typography>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    Price:
                    {product?.retail_price
                      ? ` ${formatCurrency(product?.retail_price)}`
                      : '₱0'}
                  </Typography>
                  <Typography
                    style={{ marginTop: 10 }}
                    color="textPrimary"
                    gutterBottom
                    variant="h5"
                  >
                    {`Available Stock(s): ${product?.available_count}`}
                  </Typography>
                  <Typography color="textSecondary" variant="h6">
                    Category: {product?.category ?? ''}
                  </Typography>
                  <Typography color="textSecondary" variant="h6">
                    SKU: {product?.sku ?? ''}
                  </Typography>

                  {transferCount && viewTransferListingStock ? (
                    <Button
                      variant="outlined"
                      style={{ marginTop: '1rem' }}
                      color="secondary"
                      onClick={handleTransferStockOpen}
                    >
                      {`On Transfer: ${transferCount} Item(s)`}
                    </Button>
                  ) : null}

                  {/* TODO: guess replace button above with transfer (gettranfercount or something) */}
                </Box>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  mt={1}
                  flexDirection="column"
                >
                  <Tooltip
                    title={'Click here to add a listing inside this product'}
                  >
                    <Button
                      hidden
                      variant="contained"
                      className={classes.addItemBtn}
                      onClick={onAddListingItemPress}
                    >
                      Add an item
                    </Button>
                  </Tooltip>
                  <Button
                    style={{ width: 250 }}
                    onClick={onEditProductInformationPress}
                    variant="contained"
                  >
                    Edit Product Information
                  </Button>
                  <Button
                    color="primary"
                    style={{ marginTop: 10, width: 200 }}
                    disabled={isEmpty(product)}
                    onClick={() =>
                      navigate(`/app/flash-deals/add`, {
                        state: product
                      })
                    }
                    variant="outlined"
                  >
                    Add on Flash Deals
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <TransferStockListingDialog
        isOpen={isOpen}
        transferListings={transferCountListing}
        onHandleClose={() => setIsOpen(false)}
      />
    </Page>
  );
};

export default ProductDetailView;
