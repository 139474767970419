import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CreateTransactionPaymentRequest,
  CreateTransactionPaymentResponse,
  DeleteTransactionPaymentRequest,
  DeleteTransactionPaymentResponse,
  GetPaymentTypesResponse,
  GetTransactionPaymentsResponse,
  UpdatePaymentTypesRequest,
  UpdatePaymentTypesResponse,
  UpdateTransactionPaymentRequest
} from 'src/types/transaction';
import { CommonAxiosResponse, CommonResponseMessage } from 'src/types';

export const createTransactionPaymentThunk = createAsyncThunk<
  CommonAxiosResponse<CreateTransactionPaymentResponse>,
  CreateTransactionPaymentRequest,
  { state: RootState }
>('transaction/createTransactionPaymentThunk', async (data) => {
  const response = await Api.post(`/transaction/payment/create`, data);
  if (!response) {
    throw new Error('Failed at createTransactionPaymentThunk.');
  }
  return response;
});

export const getTransactionPaymentsThunk = createAsyncThunk<
  CommonAxiosResponse<GetTransactionPaymentsResponse>,
  string | undefined,
  { state: RootState }
>('transaction/getTransactionPaymentsThunk', async (transaction_no) => {
  if (!transaction_no) {
    throw new Error('Failed getTransactionPaymentsThunk empty transaction no');
  }
  const response = await Api.get(`/transaction/payment/get/${transaction_no}`);
  // if (!response.success) {
  //   throw new Error('Failed getTransactionPaymentsThunk');
  // }
  return response;
});

export const getPaymentTypesThunk = createAsyncThunk<
  CommonAxiosResponse<GetPaymentTypesResponse>
>('option/getPaymentTypesThunk', async () => {
  const response = await Api.get('option/get/transaction-payment-types');
  if (!response.success) {
    throw new Error('Failed at getPaymentTypesThunk.');
  }
  return response;
});

export const updatePaymentTypesThunk = createAsyncThunk<
  CommonAxiosResponse<UpdatePaymentTypesResponse>,
  UpdatePaymentTypesRequest
>('option/updatePaymentTypesThunk', async (data) => {
  const response = await Api.patch(
    'option/update/value/transaction-payment-types',
    data
  );
  if (!response.success) {
    throw new Error('Failed at updatePaymentTypesThunk.');
  }
  return response;
});

export const deleteTransactionPaymentThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteTransactionPaymentResponse>,
  DeleteTransactionPaymentRequest,
  { state: RootState }
>('transaction/deleteTransactionPaymentThunk', async (data) => {
  const response = await Api.delete(
    `/transaction/payment/delete/${data?.id}`,
    data
  );
  if (!response.success) {
    throw new Error('Failed deleteTransactionPaymentThunk');
  }
  return response;
});

export const updateTransactionPaymentThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  UpdateTransactionPaymentRequest,
  { state: RootState }
>('transaction/updateTransactionPaymentThunk', async (data) => {
  const response = await Api.patch(
    `/transaction/payment/update/${data?.id}`,
    data
  );
  if (!response) {
    throw new Error('Failed at updateTransactionPaymentThunk.');
  }
  return response;
});
