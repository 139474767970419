import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useRef,
  useState
} from 'react';
import { Editor } from '@tiptap/react';
import { Box, Button, Menu, MenuItem, Tooltip } from '@material-ui/core';

import ImageIcon from '@material-ui/icons/Image';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { UploadImageOptionEnum } from 'src/enums/tiptap_editor';

interface Props {
  editor: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [imgAction, setImgAction] = useState<string>();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!editor) {
    return null;
  }

  const handleUpload = useCallback((action?: string) => {
    setImgAction(action);
    if (inputRef.current) {
      inputRef.current.click();
    }
    handleMenuClose();
  }, []);

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement>,
    action?: string
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onloadend = () => {
        const newImage = reader.result?.toString();
        if (newImage) {
          const url = prompt('Enter URL for the image link');
          if (url) {
            // Use insertContent to add an anchor with an image
            editor
              .chain()
              .focus()
              .setLink({ href: url }) // Start link
              .insertContent(
                `<a href="${url}"><img src="${newImage}" alt="${action}" /></a>` // Insert the anchor with image
              )
              .run();
          } else {
            editor
              .chain()
              .focus()
              .setImage({ src: newImage, alt: action }) // Insert image without link
              .run();
          }
        }

        // Reset input value to allow re-uploading the same image
        if (inputRef.current) {
          inputRef.current.value = ''; // Reset the file input
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleAddYoutubeVideo = () => {
    const url = prompt('Enter YouTube URL');

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <input
          type="file"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleImageChange(e, imgAction)
          }
          style={{ display: 'none' }}
          ref={inputRef}
          accept=".png,.jpeg,.jpg"
        />
        <>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleMenuOpen}
            style={{ padding: 3 }}
          >
            <ImageIcon />
            <ArrowDropDownIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {Object.values(UploadImageOptionEnum).map((item, index) => (
              <MenuItem key={index}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => handleUpload(item)}
                  startIcon={<ImageIcon />}
                >
                  {item}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </>
      </div>
      <div style={{ marginLeft: 3 }}>
        <Tooltip title="Add YouTube video">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddYoutubeVideo}
            style={{ padding: 3 }}
          >
            <YouTubeIcon />
          </Button>
        </Tooltip>
      </div>
    </Box>
  );
};

export const ImageOption = memo(component);
