import { Chip } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  style?: React.CSSProperties;
}

export const OutOfStockChip: FC<Props> = ({ style = { marginLeft: 10 } }) => {
  return (
    <Chip
      style={style}
      label="Sold Out"
      size="small"
      variant="outlined"
      color="secondary"
    />
  );
};
