import React, { FC, memo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  colors,
  Card,
  Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Sale } from 'src/types';
import { formatCurrency, getPaymentTypeColor } from 'src/utils';

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginBottom: theme.spacing(1)
  },
  serialNo: {
    color: colors.grey[600]
  },
  itemFooter: {
    display: 'flex'
  },
  quantity: {
    color: colors.purple[300],
    paddingLeft: theme.spacing(1)
  },
  dealersPrice: {
    color: colors.orange[600],
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1)
  },
  retailPrice: {
    color: colors.blue[600],
    paddingLeft: theme.spacing(1)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  data: Sale;
}

const MuiDialogWithAccordion: FC<Props> = ({ open, onClose, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="dialog-title">{`Transaction: ${data?.transaction_no}`}</DialogTitle>
      <DialogContent style={{ padding: '1rem' }}>
        <Card style={{ padding: 5, marginBottom: '1rem' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h5" style={{ color: colors.blue[600] }}>
              Total Retail Price: {formatCurrency(data?.total_retail_price)}
            </Typography>

            <Typography variant="h6" style={{ color: colors.orange[600] }}>
              TDP: {formatCurrency(data?.total_dealers_price)}
            </Typography>

            <Typography variant="h6" style={{ color: colors.purple[600] }}>
              Amount Paid: {formatCurrency(data?.amount_paid)}
            </Typography>
            <Typography variant="h6" style={{ color: colors.red[600] }}>
              Balance: {formatCurrency(data?.balance)}
            </Typography>

            <Typography variant="h6" style={{ color: colors.green[500] }}>
              Gross: {formatCurrency(data?.total_gross)}
            </Typography>
          </Box>
        </Card>

        <Accordion style={{ marginBottom: '1rem' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="products-content"
          >
            <Typography>Products</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 5 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {data?.items?.map((product, index) => {
                return (
                  <Card
                    key={`${product?.serial_no}-${index}`}
                    style={{ padding: 10, marginBottom: 10, width: '100%' }}
                  >
                    <Typography variant="h6">
                      {product?.product_name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.serialNo}
                    >
                      {product?.serial_no}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.quantity}
                    >
                      QTY: {`${product?.quantity}`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.dealersPrice}
                    >
                      DP: {formatCurrency(product?.dealers_price || 0)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.retailPrice}
                    >
                      RP: {formatCurrency(product?.retail_price || 0)}
                    </Typography>
                  </Card>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="products-content"
          >
            <Typography>Payments</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 5 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {data?.payments && data?.payments?.length > 0 ? (
                data?.payments?.map((payment, index) => {
                  return (
                    <Card
                      key={`${index}`}
                      style={{ padding: 10, marginBottom: 10, width: '100%' }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography variant="h6">
                          {`${formatCurrency(payment?.amount)} `}
                        </Typography>

                        <Typography variant="h5">{` - `}</Typography>
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: getPaymentTypeColor(payment?.payment_type)
                          }}
                        >
                          {` (${payment?.payment_type})`}
                        </Typography>
                      </Box>

                      {payment?.acknowledgement_receipt_no ? (
                        <Typography variant="h6">
                          {`AR No: ${payment?.acknowledgement_receipt_no}`}
                        </Typography>
                      ) : null}

                      <Typography variant="subtitle1">
                        {`payment created by: ${payment?.created_by}`}
                      </Typography>

                      <Typography variant="subtitle1">
                        {`data posted: ${payment?.date_posted}`}
                      </Typography>
                    </Card>
                  );
                })
              ) : (
                <Typography variant="h5" style={{ textAlign: 'center' }}>
                  No payment record found
                </Typography>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const TransactionSummaryDialog = memo(MuiDialogWithAccordion);
