import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ClearIcon from '@material-ui/icons/Clear';

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import usePromotional from 'src/hooks/promotional/use-promotional';
import { LoaderBar, SearchResultNotFound } from 'src/components';
import { useNavigate } from 'react-router';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  GetPromotionalData,
  PromotionalPosition
} from 'src/redux/slices/promotional';
import useResolution from 'src/hooks/useResolution';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
  card: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 10,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  mobileCard: {
    display: 'flex',
    padding: '.5em',
    alignItems: 'center',
    touchAction: 'none'
  },
  title: {
    columnGap: '.2em',
    display: 'flex',
    alignItems: 'center'
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  drag: { fontSize: '2.5em' },
  mobileText: { fontSize: '1em' }
});

interface Props {}

const Component: FC<Props> = () => {
  const { width } = useResolution();
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    isLoading,
    promoListing,
    errMsg,
    getPromoListings,
    updateArrangements
  } = usePromotional();

  const [searchPromo, setSearchPromo] = useState<string>('');
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [disableDragging, setDisableDragging] = useState<boolean>(false);
  const [promoList, setPromoList] = useState<GetPromotionalData[]>(
    promoListing?.data || []
  );
  const [arrangement, setArrangement] = useState<PromotionalPosition[]>([]);

  const dragColor = useMemo(() => (disableDragging ? 'disabled' : 'primary'), [
    disableDragging
  ]);

  const onResetKeywordAndResult = () => {
    setSearchPromo('');
    setDisableDragging(false);
    getPromoListings({ keyword: '' });
  };

  const onDragStart = () => setIsDragging(true);

  const onDragEnd = (res: any) => {
    if (!res.destination) {
      return;
    }

    let updatedList = [...promoList];
    const [removed] = updatedList.splice(res.source.index, 1);
    updatedList.splice(res.destination.index, 0, removed);
    const newArrangementArr = updatedList.map((i, ind) => ({
      promotional_id: i.id,
      position: ind
    }));

    if (promoList !== updatedList) {
      setPromoList(updatedList);
      setArrangement(newArrangementArr); // move this to effect and replace with state instead for debouncing
    }
    setIsDragging(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchPromo(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Disable dragging when keyword is applied and enable when no keyword is searched
      searchPromo ? setDisableDragging(true) : setDisableDragging(false);
      getPromoListings({ keyword: searchPromo });
    }
  };

  const onHandleUpdate = (id?: number) => {
    navigate(`/app/promotional/details/${id}`);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    getPromoListings();
  }, [getPromoListings]);

  useEffect(() => {
    setPromoList(promoListing?.data || []);
  }, [promoListing]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDragging && arrangement[0]) {
        updateArrangements({ arrangement });
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [arrangement, isDragging, updateArrangements]);

  return (
    <Box>
      <Paper
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          padding: '1rem',
          marginTop: '1rem',
          columnGap: '1em',
          rowGap: '1em'
        }}
      >
        <TextField
          id="search-promo"
          label="Search Promo"
          value={searchPromo}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />

        {disableDragging && (
          <Button
            color="secondary"
            startIcon={<ClearIcon />}
            onClick={onResetKeywordAndResult}
          >
            Clear Search
          </Button>
        )}
      </Paper>
      {disableDragging && (
        <Alert severity="info" style={{ marginTop: 10, cursor: 'pointer' }}>
          Cannot edit arrangements when searching a specific keyword.
        </Alert>
      )}
      {promoList[0] && !isLoading ? (
        <>
          <Card style={{ marginTop: '1em' }}>
            <LoaderBar isLoading={isLoading} />
            <CardContent>
              <Grid container spacing={2} style={{ display: 'flex' }}>
                <DragDropContext
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                >
                  <Droppable
                    droppableId="droppable-banner-list"
                    direction="vertical"
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '.2em',
                          width: '100%'
                        }}
                      >
                        {promoList.map((i, ind) => (
                          <Draggable
                            key={ind}
                            draggableId={String(ind)}
                            index={ind}
                            isDragDisabled={disableDragging}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {width <= 600 ? (
                                  <Card className={classes.mobileCard}>
                                    <DragIndicatorIcon
                                      color={dragColor}
                                      className={classes?.drag}
                                    />

                                    <Box
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flex: 1
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          variant="h4"
                                          className={classes.mobileText}
                                        >
                                          {i?.title}
                                        </Typography>
                                        <Typography
                                          variant="h4"
                                          className={classes.mobileText}
                                          style={{
                                            color: i?.is_published
                                              ? '#5cb85c'
                                              : '#ff2c2c'
                                          }}
                                        >
                                          {i?.is_published
                                            ? 'Published'
                                            : 'Unpublished'}
                                        </Typography>
                                      </Box>

                                      <IconButton
                                        onClick={() => onHandleUpdate(i?.id)}
                                      >
                                        <EditIcon color="primary" />
                                      </IconButton>
                                    </Box>
                                  </Card>
                                ) : (
                                  <Card className={classes?.card} key={ind}>
                                    <Box className={classes?.title}>
                                      <Tooltip title="Drag to move this item">
                                        <DragIndicatorIcon
                                          color={dragColor}
                                          className={classes?.drag}
                                        />
                                      </Tooltip>
                                      <Typography>{i?.title}</Typography>
                                    </Box>

                                    <Box className={classes.details}>
                                      <Typography
                                        variant="h4"
                                        style={{
                                          color: i?.is_published
                                            ? '#5cb85c'
                                            : '#ff2c2c'
                                        }}
                                      >
                                        {i?.is_published
                                          ? 'Published'
                                          : 'Unpublished'}
                                      </Typography>
                                      <Tooltip title="Edit Details">
                                        <IconButton
                                          style={{ marginLeft: '1rem' }}
                                          onClick={() => onHandleUpdate(i?.id)}
                                        >
                                          <EditIcon color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Card>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid>
            </CardContent>
          </Card>
        </>
      ) : isLoading || !promoListing ? (
        <LinearProgress />
      ) : (
        <SearchResultNotFound title={errMsg} onHandleRefresh={refreshPage} />
      )}
    </Box>
  );
};

export const PromoListings = memo(Component);
