import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  AppBarProps,
  Button
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import { LogoutDialog } from 'src/components';
import { Logo } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import useResolution from 'src/hooks/useResolution';

const { actions: userActions } = slices.user;

interface Props {
  className?: string | undefined;
  toggleDrawerOpen: () => void;
  rest?: AppBarProps;
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

export const TopBar = ({ className, toggleDrawerOpen, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isMobile } = useResolution();

  const [logoutDialogVisibility, setLogoutDialogVisibility] = useState<boolean>(
    false
  );

  const onClickLogout = () => {
    setLogoutDialogVisibility(true);
  };

  const onDismissDialog = () => {
    setLogoutDialogVisibility(false);
  };

  const onLogOut = async () => {
    await dispatch(userActions.logout());
    navigate('/login', { replace: true });
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar style={{ border: '1px solid black' }}>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton onClick={onClickLogout} color="inherit">
          <InputIcon />
        </IconButton>
        {isMobile && (
          <Button color="inherit" onClick={toggleDrawerOpen}>
            <MenuRoundedIcon />
          </Button>
        )}
      </Toolbar>
      <LogoutDialog
        onLogout={onLogOut}
        onDismiss={onDismissDialog}
        isVisible={logoutDialogVisibility}
      />
    </AppBar>
  );
};
