import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { localize } from 'src/constants';
import { theme } from 'src/theme';
import { FixMeLaterApi, Manufacturer } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';
import { convertImageFileToBase64 } from 'src/utils';
import { SelectImageButton } from 'src/components';
import { WarrantyDetails } from '../components/WarrantyDetails';

const { actions: manufacturerActions } = slices.manufacturer;
interface Props {
  manufacturer: FixMeLaterApi;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  imgContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  imgPreview: {
    width: undefined,
    height: 70,
    // marginRight: theme.spacing(1)
    marginBottom: theme.spacing(1)
  }
}));

const ManufacturerDetailView = ({
  className,
  manufacturer,
  ...rest
}: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [manufacturerDetail, setManufacturerDetail] = useState<Manufacturer>(
    manufacturer
  );
  const [manufacturerName, setManufacturerName] = useState('');
  const [iconUrl, setIconUrl] = useState<string>('');
  const [bannerUrl, setBannerUrl] = useState<string>('');
  const [warrantyDetails, setWarrantyDetails] = useState<string>('');

  const valueChecker = (val: string, action: string): boolean => {
    const { banner_url, name, icon_url, warranty_details } = manufacturerDetail;
    let isNewValue = false;
    switch (action) {
      case 'NAME':
        isNewValue = val && val !== name ? true : false;
        break;
      case 'ICON':
        isNewValue = val && val !== icon_url ? true : false;
        break;
      case 'BANNER':
        isNewValue = val && val !== banner_url ? true : false;
        break;
      case 'WARRANTY':
        isNewValue = val && val !== warranty_details ? true : false;
        break;
    }
    return isNewValue;
  };

  const onSaveDetailPress = async () => {
    if (!id) {
      return;
    }
    const newManufacturerInfo = {
      ...(valueChecker(manufacturerName, 'NAME') && { name: manufacturerName }),
      id: +id,
      ...(valueChecker(iconUrl, 'ICON') && { icon_url: iconUrl }),
      ...(valueChecker(bannerUrl, 'BANNER') && {
        banner_url: bannerUrl
      }),
      ...(valueChecker(warrantyDetails, 'WARRANTY') && {
        warranty_details: warrantyDetails
      })
    };

    const response = unwrapResult(
      await dispatch(
        manufacturerActions.updateManufacturerThunk(newManufacturerInfo)
      )
    );
    if (response?.success && response?.message) {
      snackBar.show({
        severity: 'success',
        message: response.message
      });
      getManufacturerDetail();
      setManufacturerName('');
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message ? response.message : localize.ERR_API_UNKNOWN
      });
    }
  };

  const onImageSelected = async (img: File[], action: string) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      switch (true) {
        case action === 'icon':
          setIconUrl(imgInBase64);
          break;
        case action === 'banner':
          setBannerUrl(imgInBase64);
          break;
      }
    }
  };

  const getManufacturerDetail = async () => {
    if (!id) {
      return;
    }
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturerViaIdThunk(+id))
    );
    const manufacturer = getManufacturersResponse?.originalData?.manufacturer;
    if (manufacturer) {
      setManufacturerDetail(manufacturer);
      setIconUrl(manufacturer.icon_url);
      setManufacturerName(manufacturer.name);
      setBannerUrl(manufacturer.banner_url || '');
      setWarrantyDetails(manufacturer.warranty_details || '');
    }
  };

  const onDeleteManufacturer = async () => {
    if (!id) {
      return;
    }
    const deleteManufacturerResponse = unwrapResult(
      await dispatch(manufacturerActions.deleteManufacturerThunk(+id))
    );
    if (
      deleteManufacturerResponse?.success &&
      deleteManufacturerResponse?.message
    ) {
      // eslint-disable-next-line no-alert
      alert(deleteManufacturerResponse.message);
      navigate(-1);
    }
  };

  useEffect(() => {
    getManufacturerDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          {/* TODO: We can put permission here to see edit manufacturer details but no specific perms for that now.
            closest is canEditCategory from usePermissions */}
          <Box>
            <Card elevation={0}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    Manufacturer name
                  </Typography>
                  <TextField
                    fullWidth
                    required
                    name="manufacturerName"
                    variant="outlined"
                    onChange={(e) => setManufacturerName(e.target.value)}
                    value={manufacturerName}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <WarrantyDetails
                    warrantyDetails={warrantyDetails}
                    onChange={(val: string) => setWarrantyDetails(val)}
                  />
                </Grid>
                <Grid item md={6} xs={12} className={classes.row}>
                  {iconUrl && (
                    <div className={classes.imgContainer}>
                      <img
                        alt="icon"
                        className={classes.imgPreview}
                        style={{
                          width: undefined,
                          height: 70
                          // marginRight: '1rem'
                        }}
                        src={iconUrl}
                      />
                    </div>
                  )}
                  <SelectImageButton
                    containerStyle={{ width: '100%' }}
                    title="Select/Update Manufacturer Icon"
                    helperText="Size Up to 3MB and only jpeg, jpg and png is allowed"
                    multiple={false}
                    onImageSelected={(e) => onImageSelected(e, 'icon')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  {bannerUrl && (
                    <div className={classes.imgContainer}>
                      <img
                        alt="banner"
                        className={classes.imgPreview}
                        style={{
                          width: 300, // this maybe changed depending on the resolution actually used
                          height: undefined,
                          marginBottom: '1rem'
                        }}
                        src={bannerUrl}
                      />
                    </div>
                  )}
                  <SelectImageButton
                    containerStyle={{ width: '100%' }}
                    title="Select/Update Manufacturer Banner"
                    helperText="Size Up to 3MB and only jpeg, jpg and png is allowed"
                    multiple={false}
                    onImageSelected={(e) => onImageSelected(e, 'banner')}
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  onClick={onDeleteManufacturer}
                  color="secondary"
                  variant="contained"
                >
                  Delete Manufacturer
                </Button>
                <Button
                  onClick={onSaveDetailPress}
                  color="primary"
                  variant="contained"
                >
                  Save details
                </Button>
              </Box>
            </Card>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ManufacturerDetailView;
