import { TextareaAutosize, Typography } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  warrantyDetails: string;
  onChange: (val: string) => void;
  style?: {};
}

export const WarrantyDetails: FC<Props> = ({
  warrantyDetails,
  onChange,
  style = {}
}) => {
  return (
    <div style={style}>
      <Typography style={{ fontWeight: 'bold' }}>Warranty Details</Typography>

      <TextareaAutosize
        value={warrantyDetails}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Manufacturer Warranty Details"
        style={{
          fontFamily: 'roboto',
          fontSize: '.9em',
          width: '100%',
          height: '5em',
          border: '1px solid #ccc',
          borderRadius: 4,
          padding: '10px 12px'
        }}
      />
    </div>
  );
};
