import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Container, makeStyles } from '@material-ui/core';
import { AlertDialog, Page } from 'src/components';
import {
  AnchorEl,
  FixMeLater,
  Listing,
  ListingV2,
  ProductListingHistoryData
} from 'src/types';
import { cleanSN, copyToClipboard, getCategoryNameViaId } from 'src/utils';
import Toolbar from './Toolbar';
import ProductDetail from './ProductDetail';
import { ListingMenuEnum, ListingStatusEnum, ProductTypeEnum } from 'src/enums';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { Product } from 'src/types/product';
import {
  useAlertGlobal,
  useIsMount,
  usePermissions,
  useProductPartDetailsLogic,
  useSnackBar
} from 'src/hooks';
import { Alert, Pagination } from '@material-ui/lab';
import { SerializedListingsProductTable } from '../component';
import { ProductUpdateHistory } from './ProductUpdateHistory';
import { ListingPrintModal } from '../component/ListingPrintModal';

type ProductListingDialogType = {
  title?: string;
  visible?: boolean;
  subtitle?: string;
  data?: Listing;
  type?: ListingStatusEnum | string;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const { actions: productActions } = slices.product;
const { actions: productPartActions } = slices.productPartDetails;
const { actions: listingActions } = slices.listing;

const ProductView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const alertGlobal = useAlertGlobal();
  const permissions = usePermissions();
  const isFirstMount = useIsMount();

  const { transformToValidDataForUpdate } = useProductPartDetailsLogic();

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const location: any = useLocation();
  const branchIdFromPricelist = location?.state || [];

  const [productDetails, setProductDetails] = useState<Product | undefined>();
  const [anchorEls, setAnchorEls] = useState<AnchorEl>({});
  const [listings, setListings] = useState<ListingV2[]>([]);
  const [allListings, setAllListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invalidProductOpen, setInvalidProductOpen] = useState(false);
  const [sortDir, setSortDir] = useState<string>('asc');
  const [isPrintOpen, setIsPrintOpen] = useState<boolean>(false);
  const [alertDialogData, setAlertDialogData] = useState<
    ProductListingDialogType | undefined
  >(undefined);
  const [selectedBranchId, setSelectedBranchId] = useState<
    number[] | undefined
  >([]);
  const [viewListingHistory, setViewListingHistory] = useState<boolean>(false);
  const [isLoadingListingHistory, setIsLoadingHistory] = useState<boolean>(
    false
  );
  const [selectedSerialNo, setSelectedSerialNo] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [searchText, setSearchText] = useState('');
  const [listingHistoryData, setListingHistoryData] = useState<
    ProductListingHistoryData[]
  >([]);

  const [lastPage, getLastPage] = useState<number>();
  const [page, setPage] = useState<number>(1);

  const isProductConsumable = useMemo(
    () => productDetails?.product_type === ProductTypeEnum.Consumable,
    [productDetails]
  );

  const handleSetSortDir = (e: any) => {
    setSortDir(e.target.value ?? '');
    setPage(1);
    getProductListing(
      '',
      isProductConsumable,
      1,
      selectedBranchId || branchIdFromPricelist,
      statusFilter,
      e.target.value
    );
  };

  const handleChangeKeyword = (e: any) => {
    setSearchText(e.target.value ?? '');
  };

  const handleTogglePrint = () => setIsPrintOpen(!isPrintOpen);

  const handlePrintPress = async () => {
    setIsLoading(true);
    if (id) {
      const res = unwrapResult(
        await dispatch(
          listingActions.getListingsThunk({
            product_id: +id,
            keyword: searchText,
            limit: 999,
            sort_direction: sortDir,
            status: statusFilter === 'all' ? undefined : statusFilter,
            branch_id: selectedBranchId || branchIdFromPricelist
          })
        ).finally(() => setIsLoading(false))
      );
      if (res.success) {
        setAllListings(res?.originalData?.data ?? []);
        handleTogglePrint();
      }
    } else {
      console.log('Failed at getListingsThunk');
    }
  };

  const getProductListing = useCallback(
    async (
      keyword?: string,
      isConsumable = isProductConsumable,
      pageNum = 1,
      branchIds?: number[],
      status?: string | null,
      sorting = sortDir
    ) => {
      const statusVal = status === 'all' ? null : status;

      if (!id) {
        return;
      }
      setIsLoading(true);
      let getListingResponse: any = null;
      if (isConsumable) {
        getListingResponse = unwrapResult(
          await dispatch(
            listingActions.getConsumableListingsViaProductId({
              product_id: +id,
              keyword,
              page: pageNum,
              sort_direction: sorting,
              limit: 10,
              status: statusVal,
              branch_id: branchIds || branchIdFromPricelist || []
            })
          ).finally(() => setIsLoading(false))
        );
      } else {
        const sn = keyword ? cleanSN(keyword) : '';
        getListingResponse = unwrapResult(
          await dispatch(
            listingActions.getListingsThunk({
              product_id: +id,
              keyword: sn,
              page: pageNum,
              limit: 10,
              sort_direction: sorting,
              status: statusVal,
              branch_id: branchIds || branchIdFromPricelist || []
            })
          ).finally(() => setIsLoading(false))
        );
      }
      if (
        getListingResponse?.success &&
        getListingResponse?.originalData?.data
      ) {
        setListings(getListingResponse?.originalData?.data);
        getLastPage(getListingResponse?.originalData?.meta?.last_page);
      }
    },
    [isProductConsumable, id, dispatch, sortDir, branchIdFromPricelist]
  );

  const getProductDetailsV2 = useCallback(
    async (thenGetListings: boolean = false, branch_ids = []) => {
      if (!id) {
        return;
      }

      const response = unwrapResult(
        await dispatch(
          productActions.getProductDetailsThunk({ id, branch_id: branch_ids })
        )
      ).originalData;

      const { success, data } = response;

      if (!success) {
        snackBar.show({
          severity: 'error',
          message: 'Failed to get product details. Try again later'
        });
        return;
      }

      if (isEmpty(data)) {
        setInvalidProductOpen(true);
        return;
      }

      setProductDetails(data);

      if (thenGetListings) {
        getProductListing('', isProductConsumable);
      }
    },
    [dispatch, getProductListing, id, isProductConsumable, snackBar]
  );

  const onHandleViewProductListingUpdateHistory = useCallback(
    async (serialNo?: string) => {
      try {
        setIsLoadingHistory(true);
        const response = unwrapResult(
          await dispatch(
            productActions.getProductListingUpdateHistoryThunk(serialNo)
          )
        );
        if (response?.success) {
          setIsLoadingHistory(false);
          const { data } = response?.originalData;
          setListingHistoryData(data || []);
        }
      } catch (error) {
        setIsLoadingHistory(false);
        console.error(error);
      } finally {
        setIsLoadingHistory(false);
      }
    },
    [dispatch]
  );

  const onInvalidProductAlertClose = () => {
    setInvalidProductOpen(false);
    navigate(-1);
  };

  const handleActionClick = (menuId: number, event: FixMeLater) => {
    event.stopPropagation();
    const clonedAnchorEls: FixMeLater = cloneDeep(anchorEls);
    clonedAnchorEls[menuId] = event.target;
    setAnchorEls(clonedAnchorEls);
  };

  const updateListingStatus = async (listingId: number, status: FixMeLater) => {
    const updateListingResponse = unwrapResult(
      await dispatch(
        listingActions.updateListingsThunk({ id: +listingId, status })
      ).finally(() => setIsLoading(false))
    );

    if (updateListingResponse.success) {
      getProductListing();
      snackBar.show({
        severity: 'success',
        message: updateListingResponse.message
      });
      return;
    }
    snackBar.show({
      severity: 'error',
      message: updateListingResponse.message
    });
  };

  const deleteListing = async (listingId: number) => {
    const deleteListingResponse = unwrapResult(
      await dispatch(listingActions.deleteListingThunk(listingId))
    );
    if (deleteListingResponse.success) {
      getProductListing();
      snackBar.show({
        severity: 'success',
        message: deleteListingResponse.message
      });
      return;
    }
    snackBar.show({
      severity: 'error',
      message: deleteListingResponse.message
    });
  };

  // comment out cause  unused
  // const deleteListingConsumable = async (listingId: number) => {
  //   const deleteListingResponse = unwrapResult(
  //     await dispatch(listingActions.deleteListingThunk(listingId))
  //   );
  //   if (deleteListingResponse.success) {
  //     getProductListing();
  //     snackBar.show({
  //       severity: 'success',
  //       message: deleteListingResponse.message
  //     });
  //     return;
  //   }
  //   snackBar.show({
  //     severity: 'error',
  //     message: deleteListingResponse.message
  //   });
  // };

  const onListingPress = (item_id: number) => {
    navigate(`/app/products-listings/${item_id}`);
  };

  //TODO: Rma is deprecated, remove later
  // const setListingToRma = (item: Listing) => {
  //   const newRmaData: Rma = {
  //     product_listing_serial_no: item?.serial_no,
  //     customer_id: item?.purchase_info?.customer_id,
  //     product_id: item?.product?.id,
  //     listing_id: item?.id,
  //     product_name: item?.product_name,
  //     customer_name: item?.purchase_info?.full_name,
  //     transaction_no: item?.purchase_info?.transaction_no
  //   };
  //   navigate('/app/rma/add', { state: newRmaData });
  // };

  const onAlertCancel = () => {
    setAlertDialogData(undefined);
  };

  const onAlertOk = () => {
    const item = alertDialogData?.data;
    if (item?.id) {
      if (alertDialogData?.type === ListingMenuEnum.SetAvailable) {
        updateListingStatus(item?.id, ListingStatusEnum.Available);
      }
      if (alertDialogData?.type === ListingMenuEnum.SetOnHold) {
        updateListingStatus(item?.id, ListingStatusEnum.OnHold);
      }
      if (alertDialogData?.type === ListingMenuEnum.SetForWarranty) {
        updateListingStatus(item?.id, ListingStatusEnum.ForWarranty);
      }
      if (alertDialogData?.type === ListingMenuEnum.SetPurchased) {
        updateListingStatus(item?.id, ListingStatusEnum.Purchased);
      }
      if (alertDialogData?.type === ListingMenuEnum.Delete) {
        deleteListing(item?.id);
      }
    }
    setAlertDialogData(undefined);
  };

  const handleActionClose = (item: Listing, type?: ListingMenuEnum) => {
    setAnchorEls({}); // reset the value so we dont have a ton of null values

    //TODO: Rma is deprecated, remove later
    // on set to RMA
    // if (type === ListingMenuEnum.SetForRma) {
    //   setListingToRma(item);
    //   return;
    // }

    // on Edit
    if (type === ListingMenuEnum.Edit) {
      onListingPress(item.id);
      return;
    }

    // Delete alert
    if (type === ListingMenuEnum.Delete) {
      setAlertDialogData({
        title: 'Delete Item?',
        subtitle: `Are you sure you want to delete this item: ${item?.serial_no} `,
        data: item,
        type: type,
        visible: true
      });
      return;
    }

    // Someone already bought it
    if (
      item?.purchase_info?.full_name &&
      item?.status === ListingStatusEnum.Purchased &&
      type
    ) {
      setAlertDialogData({
        title: 'Update Status?',
        subtitle: `This Item has already been purchased by ${item?.purchase_info
          ?.full_name || '--'}. Are you sure you want to change its status? `,
        data: item,
        type: type,
        visible: true
      });
      return;
    }

    // If item is not yet purchased
    if (item?.id) {
      if (type === ListingMenuEnum.SetAvailable) {
        updateListingStatus(item?.id, ListingStatusEnum.Available);
      }
      if (type === ListingMenuEnum.SetOnHold) {
        updateListingStatus(item?.id, ListingStatusEnum.OnHold);
      }
      if (type === ListingMenuEnum.SetPurchased) {
        updateListingStatus(item?.id, ListingStatusEnum.Purchased);
      }
      if (type === ListingMenuEnum.SetDefective) {
        updateListingStatus(item?.id, ListingStatusEnum.Defective);
      }
    }

    //open view listing history
    if (type === ListingMenuEnum.ViewHistory) {
      setViewListingHistory(true);
      setSelectedSerialNo(item?.serial_no || '');
      onHandleViewProductListingUpdateHistory(item?.serial_no);
      return;
    }
  };

  const onSearchListing = () => {
    getProductListing(
      searchText,
      isProductConsumable,
      1,
      selectedBranchId,
      statusFilter
    );
  };

  const onUpdateProductInfoAndDetailsAPI = async (newProductInfo: Product) => {
    const updateProductRes = unwrapResult(
      await dispatch(productActions.updateProductsThunk(newProductInfo))
    );
    if (updateProductRes?.success && updateProductRes?.message) {
      snackBar.show({ severity: 'success', message: updateProductRes.message });
      const categoryKey = getCategoryNameViaId(newProductInfo?.category_id);
      if (categoryKey) {
        const newProductDetail = transformToValidDataForUpdate(categoryKey);
        const updateProductDetailsRes = unwrapResult(
          await dispatch(
            productPartActions.updateProductDetailsThunk({
              category_name: categoryKey,
              product_id: newProductInfo?.id,
              ...newProductDetail
            })
          )
        );
        if (!updateProductDetailsRes?.success) {
          snackBar.show({
            severity: 'error',
            useSound: true,
            message:
              updateProductDetailsRes.message || 'Error updating product detail'
          });
        }
      }
      // getProductDetails(true);
    }
  };

  const onSaveDetailPress = async (
    newProductInfo: Product,
    priceChecker: boolean = true
  ) => {
    if (priceChecker) {
      if (
        newProductInfo?.dealers_price !== productDetails?.dealers_price ||
        newProductInfo?.retail_price !== productDetails?.retail_price
      ) {
        if (!permissions.canEditPriceListPrice) {
          alert(':P No to inspect element');
          return;
        }
        alertGlobal.show({
          title: 'Price Change',
          subtitle:
            'Retail price and/or Dealer price is updated. Are you sure?',
          buttons: [
            {
              text: 'Yes',
              onClick: () => {
                onSaveDetailPress(newProductInfo, false);
                alertGlobal.hide();
              },
              color: 'secondary'
            },
            {
              text: 'Cancel',
              onClick: () => alertGlobal.hide()
            }
          ]
        });
        return;
      }
    }
    if (!isProductConsumable) {
      newProductInfo.consumable_unit = undefined;
    }
    onUpdateProductInfoAndDetailsAPI(newProductInfo);
  };

  const onDeleteProduct = async (productInfo: Product) => {
    // TODO: Add a checker if is on transaction. (Check trello)
    if (productInfo?.id) {
      const response = unwrapResult(
        await dispatch(productActions.deleteProductThunk(productInfo.id))
      );
      if (response?.success && response?.message) {
        snackBar.show({ severity: 'success', message: response.message });
        dispatch(productActions.removeProduct(productInfo.id));
        await dispatch(
          productPartActions.deleteProductDetailsThunk({
            category_name: getCategoryNameViaId(productInfo?.category_id),
            product_id: productInfo.id
          })
        );
        navigate(-1);
      }
    }
  };

  const handleBranchChange = (branchIds?: number[]) => {
    getProductListing(
      searchText,
      isProductConsumable,
      1,
      branchIds,
      statusFilter
    );
    getProductDetailsV2(false, branchIds);
    setSelectedBranchId(branchIds);
    setPage(1);
  };

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
    getProductListing(
      searchText,
      isProductConsumable,
      value,
      selectedBranchId || branchIdFromPricelist,
      statusFilter
    );
  };

  const handleFilterStatusChange = (status: string) => {
    setStatusFilter(status);
    setPage(1);
    getProductListing(
      searchText,
      isProductConsumable,
      1,
      selectedBranchId || branchIdFromPricelist,
      status
    );
  };

  useEffect(() => {
    if (isFirstMount) {
      getProductDetailsV2(true);
    }
    // intended disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstMount, selectedBranchId, branchIdFromPricelist]);

  const onCopySerialNo = (e: any, a: string) => {
    e.stopPropagation();
    const copySerialNumber = `${a}`;
    copyToClipboard(copySerialNumber);
  };

  return (
    <Page className={classes.root} title="Product">
      <>
        <Container maxWidth={false}>
          {productDetails && (
            <ProductDetail
              listings={listings}
              onDeleteProduct={onDeleteProduct}
              onSaveDetailPress={onSaveDetailPress}
              product={productDetails}
            />
          )}

          {productDetails && (
            <Toolbar
              onPrintPress={handlePrintPress}
              sortDir={sortDir}
              statusFilter={statusFilter}
              isLoading={isLoading}
              hasListing={!!listings[0]}
              handleSetSortDir={handleSetSortDir}
              product={productDetails}
              branchFromPriceList={branchIdFromPricelist}
              onHandleBranchChange={(branchIds?: number[]) =>
                handleBranchChange(branchIds || [])
              }
              onSearchListing={onSearchListing}
              handleChangeKeyword={handleChangeKeyword}
              onHandleStatusChangeFilter={(status: string) =>
                handleFilterStatusChange(status)
              }
            />
          )}

          {isProductConsumable ? (
            <Alert hidden className={classes.consumableAlert} severity="info">
              This product is consumable and Serial No. does not matter
            </Alert>
          ) : null}
          {/* Do not show table if product is consumable like rj45 */}
          {isProductConsumable ? null : (
            <SerializedListingsProductTable
              isLoading={isLoading}
              listings={listings}
              anchorEls={anchorEls}
              onCopySerialNo={onCopySerialNo}
              handleActionClose={handleActionClose}
              handleActionClick={handleActionClick}
            />
          )}
        </Container>
        <AlertDialog
          title="Invalid Product"
          isVisible={invalidProductOpen}
          subTitle={'We dont have this on our system'}
          handleClose={onInvalidProductAlertClose}
        />
        <AlertDialog
          title={alertDialogData?.title}
          customButtons={
            <>
              <Button onClick={onAlertOk}>Ok</Button>
              <Button onClick={onAlertCancel} color="primary">
                Cancel
              </Button>
            </>
          }
          subTitle={alertDialogData?.subtitle}
          isVisible={alertDialogData?.visible || false}
        />
        {!isProductConsumable && (
          <ListingPrintModal
            listings={allListings}
            isOpen={isPrintOpen}
            toggleDialogOpen={handleTogglePrint}
          />
        )}

        {!isProductConsumable && (
          <Box display="flex" justifyContent="flex-end" p={2} marginBottom={5}>
            <Pagination
              count={lastPage}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </>
      <ProductUpdateHistory
        isOpen={viewListingHistory}
        serialNo={selectedSerialNo}
        expandFirstCard={viewListingHistory}
        handleClose={() => setViewListingHistory(false)}
        productListingHistory={listingHistoryData}
        isLoadingData={isLoadingListingHistory}
      />
    </Page>
  );
};

export default ProductView;
