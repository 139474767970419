import React, { FC } from 'react';

interface Props {
  index: number;
  children: React.ReactNode;
}

export const AlternateRowContainer: FC<Props> = ({ index, children }) => {
  const isEven = (index & 1) === 0; // fancy checking if number is even
  return (
    <div style={{ background: !isEven ? 'white' : 'rgba(0, 0, 0, 0.12)' }}>
      {children}
    </div>
  );
};
