import * as product from './product';
import * as productPartDetails from './product-part-details';
import * as user from './user';
import * as supplier from './supplier';
import * as listing from './listing';
import * as category from './category';
import * as manufacturer from './manufacturer';
import * as transaction from './transaction';
import * as transactionPayments from './transaction-payment';
import * as app from './app';
import * as sales from './sales';
import * as rma from './rma';
import * as roles from './roles';
import * as permissions from './permissions';
import * as oauth from './oauth';
import * as audit from './audit';
import * as quotations from './quotation';
import * as inventory from './inventory';
import * as gallery from './gallery';
import * as ecommBanner from './ecomm/ecomm-banner';
import * as ecomOrder from './ecom-orders';
import * as pcPackage from './pc-package';
import * as flashDeals from './flash-deals';
import * as sms from './sms';
import * as customerService from './customer-service';
import * as pcwUserAccounts from './pcw-user';
import * as branch from './branches';
import * as options from './option';
import * as raffle from './raffle';
import * as pcwUserAddress from './pcw-user-address';
import * as eventCode from './code-generator';
import * as kachiPoints from './kachi-points';
import * as stockTransfer from './stock-transfer';
import * as recentOutOfStocks from './out-of-stocks-product';
import * as decayingStocks from './decaying-stock';
import * as fastMovingProducts from './fast-moving-products';
import * as transferStockLogs from './transfer-stock-logs';
import * as userBuilds from './user-builds';
import * as blog from './blogs';
import * as blogCategory from './blog-category';
import * as inventoryAudit from './inventory-audit';
import * as inventoryAuditLogs from './inventory-audit-logs';
import * as pcBundles from './pc-bundles';
import * as taptapCustomer from './taptap-customer';
import * as location from './location';
import * as promotional from './promotional';

export const slices = {
  product,
  user,
  productPartDetails,
  supplier,
  listing,
  inventory,
  category,
  gallery,
  quotations,
  manufacturer,
  transaction,
  transactionPayments,
  ecommBanner,
  app,
  flashDeals,
  sales,
  rma,
  roles,
  permissions,
  oauth,
  audit,
  ecomOrder,
  pcPackage,
  customerService,
  pcwUserAccounts,
  pcwUserAddress,
  branch,
  options,
  sms,
  raffle,
  eventCode,
  kachiPoints,
  stockTransfer,
  recentOutOfStocks,
  decayingStocks,
  fastMovingProducts,
  transferStockLogs,
  userBuilds,
  blog,
  blogCategory,
  inventoryAudit,
  inventoryAuditLogs,
  pcBundles,
  taptapCustomer,
  location,
  promotional
};
