import React, {
  useState,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import {
  CircularProgress,
  Fade,
  Grid,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { BranchListDropDown } from 'src/components/dropdown';
import { BranchItemListField } from './BranchItemListField';
import { TransferButtonControls } from './TransferControlButtons';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { DecisionDialog } from 'src/components';
import { Alert } from '@material-ui/lab';
import { messagePrompt } from '../constant/messagePrompt';
import { ProductWithSerialNo } from 'src/types';
import { TransferListError } from './TransferListError';
import { debounce } from 'lodash';
import { TransferListAction } from 'src/redux/slices/stock-transfer';
import { TransferNonSnDialog } from './TransferNonSnDialog';
import useUserInfo from 'src/hooks/user/use-user-info';
import { cleanSN } from 'src/utils';

type ErrorAlert = {
  isError: boolean;
  severity: 'info' | 'warning' | 'error';
  alertMsg: string | null;
};

type AlertDecision = {
  title?: string;
  subtitle?: string;
  isOpen?: boolean;
};

const useStyle = makeStyles(() => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customFonts: {
    fontWeight: 700,
    marginBottom: '1rem'
  }
}));

const Component = () => {
  const classes = useStyle();
  const inputSearchSerialRef = useRef<HTMLInputElement>(null);

  const {
    isGetProductSnLoading,
    originBranchListProduct,
    receivingBranchListProduct,
    sortedOriginBranchListItems,
    sortedReceivingBranchListItems,
    isConfirmBranchChange,
    originListingId,
    receivingListingId,
    productSerialNumber,
    errorSerialNos,
    setProductSerialNumber,
    getProdAvailableViaSerialNo,
    transferStockOriginBranchToReceivingBranch,
    transferStockBackToOriginBranchFromReceivingbranch,
    setIsConfirmBranchChange,
    clearAllOriginItems,
    clearAllReceivingItems,
    removeItemOnList,
    setErrorSerialNos,
    getSelectedOriginBranch
  } = useStockTransfer();
  const { userBranchOptions } = useUserInfo();

  const [originBranchId, setOriginBranchId] = useState<number>();
  const [receivingBranchId, setReceivingBranchId] = useState<number>();
  const [isOpenNonSn, setIsOpenNonSn] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({
    isError: false,
    severity: 'error',
    alertMsg: null
  });
  const [alertDecision, setAlertDecision] = useState<AlertDecision>();

  const originBranchLabel = useMemo(() => {
    const count = sortedOriginBranchListItems.filter((i) => i.isChecked).length;
    const countString = count ? `for Transfer: ${count}` : '';

    return `Origin Item(s) ${countString}`;
  }, [sortedOriginBranchListItems]);

  const receivingBranchLabel = useMemo(() => {
    const count = sortedReceivingBranchListItems.filter((i) => i.isChecked)
      .length;
    const countString = count ? `for Transfer: ${count}` : '';

    return `Receiving Item(s) ${countString}`;
  }, [sortedReceivingBranchListItems]);

  const isTransferTo = useMemo(() => {
    const isTransfer =
      originBranchListProduct &&
      originBranchListProduct.length >= 1 &&
      originBranchListProduct.some((item) => item?.isChecked) &&
      !errorAlert.isError;
    return isTransfer;
  }, [errorAlert.isError, originBranchListProduct]);

  const isTransferBackTo = useMemo(() => {
    const isTransfer =
      receivingBranchListProduct &&
      receivingBranchListProduct.length >= 1 &&
      receivingBranchListProduct.some((item) => item?.isChecked) &&
      !errorAlert.isError;
    return isTransfer;
  }, [errorAlert.isError, receivingBranchListProduct]);

  const onHandleProductKeywordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setProductSerialNumber(event?.target.value);
    },
    [setProductSerialNumber]
  );

  const onHandleOriginBranchChange = useCallback(
    (branchIds?: number[]) => {
      if (branchIds && branchIds?.length === 1) {
        const selectedBranch = userBranchOptions.find(
          (branch) => branch.value === branchIds[0]
        );
        const selectedBranchValue = selectedBranch ? selectedBranch.name : '';

        setOriginBranchId(branchIds[0]);
        //every time branch change clear the list
        clearAllOriginItems();
        setErrorSerialNos([]);
        clearAllReceivingItems(); //we also delete items in receiving branch
        setIsConfirmBranchChange(false);
        setProductSerialNumber('');
        getSelectedOriginBranch(branchIds[0], selectedBranchValue);
      }
    },
    [
      clearAllOriginItems,
      clearAllReceivingItems,
      getSelectedOriginBranch,
      setErrorSerialNos,
      setIsConfirmBranchChange,
      setProductSerialNumber,
      userBranchOptions
    ]
  );

  const onHandleReceivingBranchChange = useCallback(
    (branchIds?: number[]) => {
      if (branchIds && branchIds?.length === 1) {
        setReceivingBranchId(branchIds[0]);
        clearAllReceivingItems();
        setIsConfirmBranchChange(false);
      }
    },
    [clearAllReceivingItems, setIsConfirmBranchChange]
  );

  const onHandleBranchFocus = (action: TransferListAction) => {
    //if there is current product and the user want to change the branch
    //alert when branchdropdown is focus
    const originBranchExists =
      originBranchId && originBranchListProduct.length >= 1;
    const receivingBranchExists =
      receivingBranchId && receivingBranchListProduct.length >= 1;
    const isBranchChangeNotConfirmed = !isConfirmBranchChange;
    const isReceivingBranchHasItem = receivingBranchListProduct.length >= 1;

    switch (action) {
      case 'origin':
        if (
          (originBranchExists && isBranchChangeNotConfirmed) ||
          (isReceivingBranchHasItem && isBranchChangeNotConfirmed)
        ) {
          setAlertDecision({
            title: messagePrompt.originTitle,
            subtitle: messagePrompt.originBranchMsg,
            isOpen: true
          });
        }
        break;
      case 'receiving':
        if (receivingBranchExists && isBranchChangeNotConfirmed) {
          setAlertDecision({
            title: messagePrompt.receivingTitle,
            subtitle: messagePrompt.receivingBranchMsg,
            isOpen: true
          });
        }
        break;
      default:
        return;
    }
  };

  const onHandleTransferStockToReceivingBranch = () => {
    transferStockOriginBranchToReceivingBranch({
      receiving_branch: receivingBranchId,
      origin_branch: originBranchId,
      listing_ids: originListingId
    });
    setIsConfirmBranchChange(false);
  };

  const onHandleTransferStockBackToOriginBranch = () => {
    transferStockBackToOriginBranchFromReceivingbranch({
      //origin branch is now the receving branch
      //receiving branch is now the origin branch
      receiving_branch: originBranchId,
      origin_branch: receivingBranchId,
      listing_ids: receivingListingId
    });
    setIsConfirmBranchChange(false);
  };

  const onKeyPressEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const eventTarget: any = event.target;
      const eventTargetValue: string = eventTarget.value;
      getProdAvailableViaSerialNo({
        branch_id: originBranchId,
        serial_no: cleanSN(eventTargetValue)
      });
      setProductSerialNumber('');
    }
  };

  const onRemoveOriginItem = (item?: string) => {
    removeItemOnList(item);
  };

  const handleErrors = useCallback(
    (
      originId?: number,
      receivingId?: number,
      originItems?: ProductWithSerialNo[]
    ) => {
      if (originId && receivingId) {
        const isEqualBranch = originId === receivingId;
        setErrorAlert({
          isError: isEqualBranch,
          severity: 'error',
          alertMsg: messagePrompt.errorSameBranchMsg
        });
        return;
      }
      if (originId && !receivingId && originItems && originItems?.length >= 1) {
        setErrorAlert({
          isError: true,
          severity: 'warning',
          alertMsg: messagePrompt.warningNoReceiveBranch
        });
        return;
      }
      setErrorAlert({ isError: false, severity: 'error', alertMsg: null });
    },
    []
  );

  const onResyncErrorItem = debounce((index: number) => {
    const errorItem = errorSerialNos?.[index];
    getProdAvailableViaSerialNo({
      branch_id: errorItem?.branch_id,
      serial_no: errorItem?.serial_no
    });
  }, 500);

  const onRemoveErrorItem = (index: number) => {
    setErrorSerialNos((prev) => prev.filter((_, i) => i !== index));
  };

  const onClearAll = () => {
    setErrorSerialNos([]);
  };

  useEffect(() => {
    if (!isGetProductSnLoading && inputSearchSerialRef.current) {
      inputSearchSerialRef.current.focus();
    }
  }, [isGetProductSnLoading]);

  useEffect(() => {
    handleErrors(originBranchId, receivingBranchId, originBranchListProduct);
  }, [
    handleErrors,
    originBranchId,
    originBranchListProduct,
    receivingBranchId
  ]);

  return (
    <Paper elevation={1} style={{ padding: '1rem' }}>
      <Grid container spacing={3} alignContent="center">
        <Fade in={errorAlert.isError}>
          <Grid item md={12} style={{ padding: 0 }}>
            {errorAlert.isError ? (
              <Alert severity={errorAlert.severity}>
                {errorAlert.alertMsg}
              </Alert>
            ) : null}
          </Grid>
        </Fade>
        <Grid item xs={12} md={5}>
          <Typography variant="h4" className={classes.customFonts}>
            {originBranchLabel}
          </Typography>
          <Paper
            elevation={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              marginBottom: '1rem'
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <div style={{ marginRight: '1rem', width: '100%' }}>
                  <BranchListDropDown
                    allowNoSelectedBranch={false}
                    onHandleBranchChange={onHandleOriginBranchChange}
                    onHandleFocus={() => onHandleBranchFocus('origin')}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Paper style={{ minWidth: '57%', padding: '0.6rem' }}>
                  <TextField
                    onChange={onHandleProductKeywordChange}
                    value={productSerialNumber}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {isGetProductSnLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          )}
                        </InputAdornment>
                      )
                    }}
                    disabled={!originBranchId}
                    placeholder="Find Product Via Serial No."
                    variant="outlined"
                    onKeyPress={onKeyPressEnter}
                    inputRef={inputSearchSerialRef}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <TransferListError
            onClearAll={onClearAll}
            onRetry={onResyncErrorItem}
            onRemove={onRemoveErrorItem}
            transferErrorList={errorSerialNos}
          />

          <BranchItemListField
            branchListItem={sortedOriginBranchListItems || []}
            action="origin"
            onRemoveItem={(item?: string) => onRemoveOriginItem(item || '')}
            isReceivingBranch={true}
          />
        </Grid>
        <Grid item xs={12} md={2} className={classes.flexCenter}>
          <TransferButtonControls
            originId={originBranchId}
            receivingId={receivingBranchId}
            isTransferTo={isTransferTo}
            isTransferBackTo={isTransferBackTo}
            onHandleTransferToReceivingBranch={
              onHandleTransferStockToReceivingBranch
            }
            onHandleTransferToOriginBranch={
              onHandleTransferStockBackToOriginBranch
            }
            onHandleOpenNonSnDialog={() => setIsOpenNonSn(!isOpenNonSn)}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Typography
            variant="h4"
            style={{ fontWeight: 700, marginBottom: '1rem' }}
          >
            {receivingBranchLabel}
          </Typography>
          <div style={{ marginBottom: '1rem' }}>
            <BranchListDropDown
              allowNoSelectedBranch={false}
              onHandleBranchChange={onHandleReceivingBranchChange}
              onHandleFocus={() => onHandleBranchFocus('receiving')}
            />
          </div>

          <BranchItemListField
            action={'receiving'}
            branchListItem={sortedReceivingBranchListItems || []}
            //not allowed to remove item in receiving branch as of now
            onRemoveItem={() => {}}
          />
        </Grid>
      </Grid>
      <DecisionDialog
        isOpen={alertDecision?.isOpen || false}
        title={alertDecision?.title}
        subTitle={alertDecision?.subtitle}
        okayButtonName={'Understood'}
        onHandleConfirmAction={() => {
          setIsConfirmBranchChange(true);
          setAlertDecision({ isOpen: false });
        }}
        onHandleClose={() =>
          setAlertDecision((prev) => ({ ...prev, isOpen: false }))
        }
      />
      <TransferNonSnDialog
        isOpen={isOpenNonSn}
        onHandleClose={() => setIsOpenNonSn(false)}
      />
    </Paper>
  );
};

export const TransferList = React.memo(Component);
