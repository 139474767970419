import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { BranchListDropDown } from 'src/components/dropdown';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';
import { Search as SearchIcon } from 'react-feather';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { SelectProductAuditModal } from './SelectProductAuditModal';
import { useSnackBar } from 'src/hooks';
import { useInventoryAudit } from 'src/hooks/inventory-audit';
import { CustomKeyboardEvent } from 'src/types';
import ReplayIcon from '@material-ui/icons/Replay';
import { DecisionDialog } from 'src/components';

interface Props {}

const component: FC<Props> = () => {
  const snackBar = useSnackBar();
  const {
    hasAuditListing,
    commonAuditParams,
    handleCommonAuditParams,
    getProductListingViaSerialNo,
    resetInventoryAudit
  } = useInventoryAudit();
  const [scanSn, setScanSn] = useState<string>('');
  // const [paramsFilter, setParamsFilter] = useState<CommonAuditParams>({});
  const [productAuditOpen, setProductAuditOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onHandleScanSn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScanSn(event?.target?.value);
  };

  const onHandleClickSelectProductAuditModal = useCallback(() => {
    if (!commonAuditParams?.branch_id || !commonAuditParams?.category_id) {
      snackBar.show({
        severity: 'error',
        message: 'Both branch and category is required'
      });
      return;
    }
    setProductAuditOpen(!productAuditOpen);
  }, [commonAuditParams, productAuditOpen, snackBar]);

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      getProductListingViaSerialNo(scanSn);
      setScanSn('');
    }
  };

  const onHandleResetInventoryAudit = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (scanSn) {
      handleCommonAuditParams({ ...commonAuditParams, keyword: scanSn });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonAuditParams]);

  return (
    <Card style={{ padding: '1rem' }}>
      <Grid
        container
        spacing={1}
        style={{ justifyContent: 'flex-start', alignItems: 'center' }}
      >
        {hasAuditListing ? (
          <Grid item md={6} xs={12}>
            <TextField
              onChange={onHandleScanSn}
              value={scanSn}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Scan Or Type Product Serial No"
              variant="outlined"
              onKeyPress={onKeyPress}
            />
          </Grid>
        ) : (
          <>
            <Grid item md={3} xs={6}>
              <div style={{ marginLeft: -9 }}>
                <BranchListDropDown
                  isElevated={false}
                  allowNoSelectedBranch={false}
                  onHandleBranchChange={(branch?: number[]) => {
                    handleCommonAuditParams({
                      ...commonAuditParams,
                      branch_id: branch ? branch[0] : undefined // Extract the single item
                    });
                  }}
                />
              </div>
            </Grid>
            <Grid item md={3} xs={6}>
              <CategoryListDropdown
                onHandleCategories={(categoryId: number) => {
                  handleCommonAuditParams({
                    ...commonAuditParams,
                    category_id: categoryId
                  });
                }}
              />
            </Grid>
          </>
        )}

        <Grid item md={3} xs={12}>
          <Button
            style={{ marginRight: '1rem', height: '55px' }}
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
            onClick={onHandleClickSelectProductAuditModal}
            startIcon={<FindInPageIcon />}
          >
            Select Product/s
          </Button>
        </Grid>

        {hasAuditListing && (
          <Grid item md={3} xs={12}>
            <Button
              style={{ marginRight: '1rem', height: '55px' }}
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={onHandleResetInventoryAudit}
              startIcon={<ReplayIcon />}
            >
              Reset Inventory Audit
            </Button>
          </Grid>
        )}
      </Grid>
      <SelectProductAuditModal
        isOpen={productAuditOpen}
        onHandleClose={() => setProductAuditOpen(false)}
      />

      <DecisionDialog
        isOpen={isOpen}
        title="Reset Inventory Audit Page"
        subTitle={`Proceeding with this action will reset the current inventory audit state and any unsaved progress will be lost. Are you sure you want to continue?`}
        onHandleConfirmAction={resetInventoryAudit}
        onHandleClose={() => setIsOpen(false)}
      />
    </Card>
  );
};

export const Toolbar = memo(component);
