import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import Alert from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Grid,
  TextField,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox,
  Divider,
  Typography,
  InputAdornment,
  CircularProgress,
  SvgIcon
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton, Page } from 'src/components';
import { localize } from 'src/constants';
import {
  AddTempTransactionProp,
  CustomInputEvent,
  CustomKeyboardEvent,
  ErrorSerialNoList,
  GetListingViaSerialRequest,
  ProductWithSerialNo,
  Transaction,
  TransactionInputField
} from 'src/types';
import { cleanSN, formatCurrency, isEmptyOrSpaces } from 'src/utils';
import { useDebouncedEffect, useSnackBar } from 'src/hooks';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useRef } from 'react';
import { BranchListDropDown } from 'src/components/dropdown';
import { NonSerializeProductDropdown } from 'src/components/dropdown/NonSerializeDropdown';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';
import { TransactListError } from '../components/TransactListError';
import { ListingStatusEnum } from 'src/enums';
import { AddTransactionCard } from './AddTransactionCard';

const useStyles = makeStyles((theme) => ({
  root: {},
  divider: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  MobileBox: {
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      flexDirection: 'column'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  DesktopBox: {
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}));

const { actions: listingActions } = slices.listing;
const { actions: pcBundleActions } = slices.pcBundles;
const {
  actions: transactionActions,
  selectors: transactionSelectors
} = slices.transaction;
const { actions: productActions } = slices.product;

const AddTransactionView = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isCreateTransactionThunkLoading = useAppSelector(
    transactionSelectors.isCreateTransactionLoading
  );
  const navigate = useNavigate();
  const isAddTransactionLoadingWithRef = useRef(false);
  const serialNoTextFieldRef = useRef<any>(null);
  const { state }: any = location;

  const [isLoading, setIsLoading] = useState(false);
  const [navigateTransactionDetails, setNavigateTransactionDetails] = useState(
    true
  );
  const [loadingListingInfo, setLoadingListing] = useState<boolean>(false);
  const [recentTransactions, setRecentTransactions] = useState<Transaction[]>(
    []
  );
  const [invalidItems, setInvalidItems] = useState<Transaction[]>([]);
  const [serialNo, setSerialNo] = useState('');
  const [errorSerialNos, setErrorSerialNos] = useState<ErrorSerialNoList>([]);
  const [productNameInput, setProductNameInput] = useState('');
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState<ProductWithSerialNo[]>([]);
  const [
    selectedProduct,
    setSelectedProduct
  ] = useState<ProductWithSerialNo | null>(null);
  const [transactionRemarks, setTransactionRemarks] = useState<string>();
  const [selectedBranchIds, setSelectedBranchIds] = useState<number[]>();
  const [poweredBuildSerialNo, setPoweredBuildSerialNo] = useState('');

  const clearRecentTransactions = () => {
    setRecentTransactions([]);
  };

  const onClearAll = () => {
    setErrorSerialNos([]);
  };

  const fetchListingViaParam = async (val: GetListingViaSerialRequest) => {
    setLoadingListing(true);
    const response = unwrapResult(
      await dispatch(listingActions.getListingViaSNThunk(val))
    );
    setLoadingListing(false);
    return response;
  };

  const onRemoveErrorItem = (index: number) => {
    setErrorSerialNos((prev) => prev.filter((_, i) => i !== index));
  };

  const onResyncErrorItem = debounce((index: number) => {
    const errorItem = errorSerialNos?.[index];
    if (errorItem?.branch_id) {
      handleTransaction(errorItem.serial_no, errorItem.branch_id);
      return;
    } else {
      return;
    }
  }, 500);

  const onHandlePoweredBuildsInput = async (
    powered_build_serial_no: string
  ) => {
    const selected_branch_id = selectedBranchIds?.[0];
    const pb_serial_no = cleanSN(powered_build_serial_no);

    try {
      // if there is no selected branch id
      if (!selected_branch_id) {
        snackBar.show({
          severity: 'error',
          message: 'Please select a branch',
          useSound: true
        });
        return;
      }

      // if input of powered build serial no is empty
      if (!powered_build_serial_no) {
        snackBar.show({
          severity: 'error',
          message: 'Please input a valid powered build serial number',
          useSound: true
        });
        return;
      }

      const fetchPoweredBuildComponentsResponse = unwrapResult(
        await dispatch(
          pcBundleActions.getComponentGroupItemsViaSN(pb_serial_no)
        )
      );
      if (!fetchPoweredBuildComponentsResponse?.success) {
        if (!powered_build_serial_no) {
          snackBar.show({
            severity: 'error',
            message:
              'Something went wrong while fetching powered build components',
            useSound: true
          });
          return;
        }
      }

      const pcBundleItems =
        fetchPoweredBuildComponentsResponse?.originalData?.data || [];

      if (pcBundleItems?.length <= 0) {
        snackBar.show({
          severity: 'error',
          message: 'power build component is empty',
          useSound: true
        });
        return;
      }

      const isThereItemNotInSameBranch = pcBundleItems?.find(
        (x) => x.branch_id !== selected_branch_id
      );

      if (isThereItemNotInSameBranch) {
        snackBar.show({
          severity: 'error',
          message: `Some item is not in the same branch. ${isThereItemNotInSameBranch?.product_name} ${isThereItemNotInSameBranch?.serial_no}`,
          useSound: true
        });
        return;
      }

      addTransactionToTempListBulk(pcBundleItems, pb_serial_no);
      setPoweredBuildSerialNo('');
    } catch (error) {
      console.error(error);
      snackBar.show({
        severity: 'error',
        message: 'Something went wrong on general for. Consult the dev.',
        useSound: true
      });
      return;
    }
  };

  // i think this is adding to temp list
  // TODO: create branch checker
  const handleTransaction = async (
    serial_no = cleanSN(serialNo),
    branch_id = selectedBranchIds?.[0]
  ) => {
    const requestParams = {
      serial_no,
      branch_id
    };

    try {
      if (recentTransactions.find((prod) => prod.serial_no === serial_no)) {
        snackBar.show({
          severity: 'error',
          message: 'Item is already added on the list.',
          useSound: true
        });
        return;
      }

      if (!serial_no) {
        snackBar.show({
          severity: 'error',
          message: 'Please input a serial number',
          useSound: true
        });
        return;
      }

      if (!branch_id) {
        snackBar.show({
          severity: 'error',
          message: 'Please select a branch',
          useSound: true
        });
        return;
      }

      const response = await fetchListingViaParam({
        serial_no: serial_no,
        branch_ids: [branch_id]
      });

      const message = response?.message;
      const notExistMessage = 'This listing does not exist';
      const listingDoesNotExist =
        notExistMessage.toLowerCase() === message.toLowerCase();
      const listingData = response?.originalData?.listing ?? null;
      const isListingAvailable =
        listingData?.status === ListingStatusEnum.Available;

      if (listingData && isListingAvailable) {
        snackBar.show({
          severity: 'success',
          message: 'Product found and added to the list',
          useSound: true
        });
        addTransactionToTempList({
          serialNoArg: listingData.serial_no,
          productNameArg: listingData?.product_name,
          amount: listingData?.retail_price || 0,
          category_name: listingData?.category_name,
          branch_name: listingData?.branch_name
        });

        // If added sn is already on error list, remove it.
        setErrorSerialNos((prev) =>
          prev?.filter((i) => i?.serial_no !== serial_no)
        );
        return;
      } else if (listingDoesNotExist) {
        snackBar.show({
          severity: 'error',
          message,
          useSound: true
        });
        return;
      } else if (!isListingAvailable) {
        snackBar.show({
          severity: 'error',
          message: 'Serial is unavailable',
          useSound: true
        });
        return;
      } else {
        snackBar.show({
          severity: 'error',
          message: message,
          useSound: true
        });

        // Check if new item for error list already exist.
        const itemAlreadyExistInErrSerialNos = errorSerialNos.find(
          (x) =>
            x?.serial_no === requestParams?.serial_no &&
            x?.branch_id === requestParams?.branch_id
        );
        if (itemAlreadyExistInErrSerialNos) {
          // only add to list if error is different
          !listingDoesNotExist &&
            setErrorSerialNos((prev) => {
              const newErrorSerialNos = prev.map((x) => {
                if (x?.serial_no === serial_no) {
                  return { ...x, message: message };
                }
                return x;
              });
              return newErrorSerialNos;
            });
          return;
        }
        !listingDoesNotExist &&
          setErrorSerialNos((prev) => [
            ...prev,
            { ...requestParams, message: message }
          ]);
      }
    } catch (error) {
      setErrorSerialNos((prev) => [
        ...prev,
        { ...requestParams, message: 'Something went wrong. Try again later' }
      ]);
      console.error(error);
      return;
    }
  };

  const getPcBundleComponentGroupSerialNoFromTempTransaction = () => {
    const clonedRecentTransactions = cloneDeep(recentTransactions);
    const poweredBuildSerialNo = clonedRecentTransactions?.find(
      (x) => x?.pb_serial_no
    );
    return poweredBuildSerialNo?.pb_serial_no || undefined;
  };

  const onCreateTransaction = async () => {
    if (
      isLoading ||
      isCreateTransactionThunkLoading ||
      isAddTransactionLoadingWithRef.current
    ) {
      return;
    }

    isAddTransactionLoadingWithRef.current = true;
    // Checking if every branch is the same
    try {
      if (
        recentTransactions.some(
          (i) => i.branch_name !== recentTransactions[0].branch_name
        )
      ) {
        snackBar.show({
          severity: 'error',
          message:
            'Please ensure that all items are assigned to the same branch.',
          useSound: true
        });
        return;
      }

      const response = unwrapResult(
        await dispatch(
          transactionActions.createTransactionThunk({
            multipleListings: recentTransactions,
            transaction_remarks: transactionRemarks,
            pc_bundle_component_group_serial_no: getPcBundleComponentGroupSerialNoFromTempTransaction()
          })
        ).finally(() => setIsLoading(false))
      );

      if (response?.success && response?.message) {
        clearRecentTransactions();
        snackBar.show({
          severity: 'success',
          message: response.message
        });
        if (navigateTransactionDetails) {
          navigateToTransaction(response?.originalData?.transaction_no);
        }
      } else if (
        !response?.success &&
        !isEmpty(response?.originalData?.invalid_items)
      ) {
        setInvalidItems(response?.originalData?.invalid_items ?? []);
        snackBar.show({
          severity: 'error',
          message: response.message
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response.message
        });
      }
    } catch (error) {
      snackBar.show({
        severity: 'error',
        message: 'An unexpected error occurred. Please try again.'
      });
    } finally {
      isAddTransactionLoadingWithRef.current = false;
    }
  };

  const onCreateTransactionDebounce = debounce(onCreateTransaction, 1000);

  const isSerialNumberValid = () => {
    if (!serialNo) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_SERIAL_NO
      });
      return false;
    }

    const serial_no = serialNo?.trim();
    setSerialNo('');

    if (isEmptyOrSpaces(serial_no)) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_SERIAL_NO
      });
      setSerialNo('');
      return false;
    }

    if (serial_no && !isEmpty(recentTransactions)) {
      const exist = recentTransactions?.find((x) => x.serial_no === serial_no);
      if (exist) {
        snackBar.show({
          severity: 'error',
          message: `${localize.ERR_ADD_TEMP_LISTING_EXIST} ${serial_no}`
        });
        setSerialNo('');
        return false;
      }
    }

    if (!state?.id) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_NO_TRANSACTION
      });
      return false;
    }

    return true;
  };

  const addTransactionToTempList = (param: AddTempTransactionProp) => {
    // Note: on amount, please compute it before passing it here in `addTransactionToTempList` function

    let existingIndex = -1;
    const {
      serialNoArg,
      productNameArg,
      quantity,
      product_id,
      listing_id,
      amount,
      category_name,
      branch_name
    } = param;

    const newTransactionListingData: Transaction = {
      customer_id: state.id,
      serial_no: serialNoArg,
      product_name: productNameArg,
      quantity,
      product_id,
      listing_id,
      amount,
      category_name,
      branch_name
    };

    const clonedRecentTransactions = cloneDeep(recentTransactions);

    if (existingIndex <= -1) {
      clonedRecentTransactions.push(newTransactionListingData);
    }
    const sortedRecentTransaction = sortProductsByCategory(
      clonedRecentTransactions
    );
    setRecentTransactions(sortedRecentTransaction);
    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_TRANSACT}: ${newTransactionListingData?.product_name}`
    });
    setSerialNo('');
    setSelectedProduct(null);
    setProductNameInput('');
    serialNoTextFieldRef?.current?.focus();
  };

  const addTransactionToTempListBulk = (
    poweredBuildItems: Transaction[],
    pb_serial_no?: string
  ) => {
    const clonedRecentTransactions = cloneDeep(recentTransactions); // Clone current transactions

    // Loop through each item and add it to the cloned array
    poweredBuildItems?.forEach((item) => {
      const existingIndex = clonedRecentTransactions.findIndex(
        (tempTransaction) => tempTransaction.serial_no === item.serial_no
      );

      const newTransactionListingData = {
        customer_id: state.id,
        serial_no: item.serial_no,
        product_name: item.product_name,
        quantity: 1, // Adjust quantity based on your needs
        product_id: item?.product_id, // Add relevant fields
        listing_id: item?.id,
        amount: item.retail_price,
        category_name: item.category_name,
        pb_serial_no: pb_serial_no,
        branch_name: item.branch_name
      };

      if (existingIndex > -1) {
        // Update existing transaction
        clonedRecentTransactions[existingIndex] = newTransactionListingData;
      } else {
        // Add new transaction
        clonedRecentTransactions.push(newTransactionListingData);
      }
    });

    // Sort transactions by category
    const sortedRecentTransaction = sortProductsByCategory(
      clonedRecentTransactions
    );

    // Update the state once with all the accumulated transactions
    setRecentTransactions(sortedRecentTransaction);

    snackBar.show({
      severity: 'success',
      message: `${localize.SUC_ADD_TEMP_TRANSACT}: ${poweredBuildItems?.length} items added`
    });

    // Clear inputs after processing all items
    setSerialNo('');
    setSelectedProduct(null);
    setProductNameInput('');
    setPoweredBuildSerialNo('');
    serialNoTextFieldRef?.current?.focus();
  };

  const onAddToTempTransaction = async () => {
    const poweredBuildsIsBeingUsed = getPcBundleComponentGroupSerialNoFromTempTransaction();
    if (poweredBuildsIsBeingUsed) {
      snackBar.show({
        severity: 'error',
        message:
          'A powered build is already being used. Please remove them first.',
        useSound: true
      });
      return;
    }

    // If a product with SN is selected / inputted
    if (selectedProduct) {
      addTransactionToTempList({
        serialNoArg: selectedProduct?.serial_no,
        productNameArg: selectedProduct?.product_name,
        amount: selectedProduct?.retail_price || 0,
        category_name: selectedProduct?.category_name,
        branch_name: selectedProduct?.branch_name
      });
      return;
    }

    // If SN is selected / inputted
    if (serialNo) {
      if (loadingListingInfo) {
        return;
      }

      if (!isSerialNumberValid()) {
        return;
      }

      handleTransaction();
      setSerialNo('');
      serialNoTextFieldRef.current.focus();
      return;
    }

    if (poweredBuildSerialNo) {
      onHandlePoweredBuildsInput(poweredBuildSerialNo);
      return;
    }

    snackBar.show({
      severity: 'error',
      message: 'Please enter a valid serial number',
      useSound: true
    });
  };

  const handleOnRemarksChange = (event: CustomInputEvent) => {
    setTransactionRemarks(event.target.value);
  };

  const onChangeSerialNo = (serial_no: string) => {
    setProductNameInput('');
    setSerialNo(serial_no);
  };

  const onChangePoweredBuildSerialNo = (serial_no: string) => {
    setPoweredBuildSerialNo(serial_no);
  };

  const keyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      const poweredBuildsIsBeingUsed = getPcBundleComponentGroupSerialNoFromTempTransaction();
      if (poweredBuildsIsBeingUsed) {
        snackBar.show({
          severity: 'error',
          message:
            'A powered build is already being used. Please remove them first.',
          useSound: true
        });
        return;
      }

      handleTransaction();
      setSerialNo('');
      serialNoTextFieldRef.current.focus();
    }
  };

  const keyPressOnPoweredBuilds = (e: any) => {
    if (e.key === 'Enter') {
      if (recentTransactions?.length > 0) {
        snackBar.show({
          severity: 'error',
          message:
            'You can only add powered builds if there are no items in the list',
          useSound: true
        });
        return;
      }

      onHandlePoweredBuildsInput(poweredBuildSerialNo);
    }
  };

  const onPressDeleteRecentItem = (i: number) => {
    const clonedRecentTransactions = cloneDeep(recentTransactions);
    clonedRecentTransactions.splice(i, 1);
    setRecentTransactions(clonedRecentTransactions);
  };

  const navigateToTransaction = (transaction_no: string) => {
    navigate(`/app/transaction/${transaction_no}`);
  };

  const onGetProductsViaKeyword = useCallback(
    async (branchIds?: number[]) => {
      setIsProductsLoading(true);
      const clonedRecentTransactions = recentTransactions;
      const arrOfSerialTempAdded = clonedRecentTransactions?.map(
        (x) => x.serial_no || ''
      );
      const response = unwrapResult(
        await dispatch(
          productActions.getProductsWithSerialThunk({
            keyword: productNameInput,
            except_serial_no: arrOfSerialTempAdded,
            branch_ids: branchIds || selectedBranchIds
          })
        ).finally(() => setIsProductsLoading(false))
      );

      if (response?.success) {
        setProducts(response?.originalData?.products || []);
      }
    },
    [dispatch, productNameInput, recentTransactions, selectedBranchIds]
  );

  const onProductNameInputChange = (text?: string) => {
    setSerialNo('');
    setProductNameInput(text || '');
  };

  // Disable field that is not related to current field.
  const isFieldDisabled = useCallback(
    (field: TransactionInputField) => {
      switch (field) {
        case 'serialized':
          return loadingListingInfo || productNameInput || !selectedBranchIds
            ? true
            : false;
        case 'powered_builds':
          return loadingListingInfo || !selectedBranchIds ? true : false;
        case 'non-sn':
          return serialNo || !selectedBranchIds ? true : false;
        default:
          break;
      }
    },
    [loadingListingInfo, productNameInput, selectedBranchIds, serialNo]
  );

  const listHasItems = useMemo(() => !!recentTransactions[0], [
    recentTransactions
  ]);

  const totalPrice = useMemo(() => {
    const totalStr =
      recentTransactions?.reduce(
        (accumulator, item) => accumulator + (item?.amount || 0),
        0
      ) || 0;
    return totalStr;
  }, [recentTransactions]);

  // Clear field that is not related to current field onFocus.
  const onFocusOfField = (field: TransactionInputField) => {
    switch (field) {
      case 'serialized':
        setProductNameInput('');
        setSelectedProduct(null);
        break;
      case 'non-sn':
        setSerialNo('');
        break;
      case 'powered_builds':
        setSerialNo('');
        setSelectedProduct(null);
        setProductNameInput('');
        break;
      default:
        break;
    }
  };

  const onClearFields = () => {
    setSerialNo('');
    setSelectedProduct(null);
  };

  const onChangeBranch = useCallback(
    (branchIds?: number[]) => {
      onClearFields();
      setSelectedBranchIds(branchIds);
      onGetProductsViaKeyword(branchIds);
    },
    [onGetProductsViaKeyword]
  );

  useEffect(() => {
    if (!loadingListingInfo && serialNoTextFieldRef.current) {
      serialNoTextFieldRef.current.focus();
    }
  }, [loadingListingInfo]);

  useEffect(() => {
    //if branch ids is empty clear this fields
    if (isEmpty(selectedBranchIds)) {
      onClearFields();
    }
  }, [selectedBranchIds]);

  useDebouncedEffect(onGetProductsViaKeyword, 500, [productNameInput]);

  return (
    <Page className={classes.root} title="New Transaction">
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Transaction"
              subheader="The information about new transaction."
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    required
                    label="Customer"
                    variant="outlined"
                    value={
                      state ? `${state.first_name} ${state.last_name}` : ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <BranchListDropDown
                    disabled={listHasItems}
                    onHandleBranchChange={(branchIds?: number[]) =>
                      onChangeBranch(branchIds)
                    }
                    isElevated={false}
                    paperSpace={0}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    onFocus={() => onFocusOfField('serialized')}
                    fullWidth
                    helperText="e.g. GPU, CPU, RAM"
                    label="Serialized - Serial no."
                    name="serial_no"
                    onKeyDown={keyPress}
                    onChange={(e) => onChangeSerialNo(e.target.value)}
                    value={serialNo}
                    variant="outlined"
                    inputRef={serialNoTextFieldRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {loadingListingInfo ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          )}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={() => onChangeSerialNo('')}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NonSerializeProductDropdown
                    selectedProduct={selectedProduct}
                    setSelectedProduct={(product: ProductWithSerialNo | null) =>
                      setSelectedProduct(product)
                    }
                    productOptions={products}
                    productLoading={isProductsLoading}
                    isDisabled={() => isFieldDisabled('non-sn') || false}
                    onProdInputChange={(prodName?: string) => {
                      onProductNameInputChange(prodName);
                    }}
                    onFocus={() => onFocusOfField('non-sn')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    onFocus={() => onFocusOfField('powered_builds')}
                    fullWidth
                    disabled={isFieldDisabled('powered_builds')}
                    label="Powered Builds SN"
                    name="powered_builds_serial_no"
                    onKeyDown={keyPressOnPoweredBuilds}
                    onChange={(e) =>
                      onChangePoweredBuildSerialNo(e.target.value)
                    }
                    value={poweredBuildSerialNo}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {loadingListingInfo ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          )}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={() => onChangePoweredBuildSerialNo('')}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <LoadingButton
                color="primary"
                variant="contained"
                loading={loadingListingInfo}
                title="Add Item"
                onClick={onAddToTempTransaction}
              />
            </Box>
          </Card>
        </Box>

        <Card style={{ marginTop: '1rem' }}>
          <CardHeader title="Transaction Remarks" />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Notes about Transaction"
                  name="transaction_remarks"
                  onChange={handleOnRemarksChange}
                  value={transactionRemarks}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Box marginY={2}>
          <Card>
            {!isEmpty(invalidItems)
              ? invalidItems.map((item, i) => (
                  <Alert key={item?.serial_no ?? i} severity="warning">
                    {`${item?.serial_no ??
                      item?.product_name} is out of stock or does not exist or already purchased`}
                  </Alert>
                ))
              : null}
            <CardHeader title="Added Products" />

            <Divider />
            <TransactListError
              onClearAll={onClearAll}
              onRetry={onResyncErrorItem}
              onRemove={onRemoveErrorItem}
              transactionErrorList={errorSerialNos}
            />

            <Divider />
            <PerfectScrollbar>
              <Box className={classes.DesktopBox}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Branch</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Identification / Serial No.</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recentTransactions.map((item, i) => (
                      <TableRow hover key={i}>
                        <TableCell>{item.branch_name}</TableCell>
                        <TableCell>{item.category_name}</TableCell>
                        <TableCell>{item.product_name}</TableCell>
                        <TableCell>{item.serial_no}</TableCell>
                        <TableCell>{formatCurrency(item?.amount)}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => onPressDeleteRecentItem(i)}
                          >
                            <DeleteForeverIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                {recentTransactions?.length > 0 ? (
                  <div className={classes.extraInfo}>
                    <Typography variant="h4">
                      TOTAL PRICE:{formatCurrency(totalPrice)}
                    </Typography>
                  </div>
                ) : null}
              </Box>
            </PerfectScrollbar>
          </Card>
          <Box className={classes.MobileBox}>
            {recentTransactions?.length > 0 ? (
              <Box sx={{ height: '500px', overflow: 'hidden scroll' }}>
                {recentTransactions?.map(
                  (data, index) => (
                    <AddTransactionCard
                      key={index}
                      data={data}
                      onHandleDelete={() => onPressDeleteRecentItem(index)}
                    />
                  ),
                  []
                )}
              </Box>
            ) : null}

            {recentTransactions?.length > 0 ? (
              <div className={classes.extraInfo}>
                <Typography variant="h4">
                  TOTAL PRICE:{formatCurrency(totalPrice)}
                </Typography>
              </div>
            ) : null}
          </Box>
        </Box>

        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={navigateTransactionDetails}
                    name="navigateTransactionDetails"
                    color="primary"
                    onChange={(_, s) => setNavigateTransactionDetails(s)}
                  />
                }
                label="Navigate to Transaction Details After Saving"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {isLoading || isCreateTransactionThunkLoading ? (
              <LoadingButton
                color="primary"
                loading={true}
                disabled={true}
                variant="contained"
                title="Transaction is being processed..."
                onClick={onCreateTransactionDebounce}
                fullWidth
              />
            ) : (
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={isLoading || isCreateTransactionThunkLoading}
                loading={isLoading || isCreateTransactionThunkLoading}
                title="Add transaction to database"
                onClick={onCreateTransactionDebounce}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default AddTransactionView;
