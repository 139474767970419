/* eslint-disable no-unused-vars */
import React, { FC, memo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import StorefrontIcon from '@material-ui/icons/Storefront';

const useStyles = makeStyles((theme) => ({
  MainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  subBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    flexWrap: 'wrap',
    minWidth: '150px',
    maxWidth: '150px'
  }
}));

interface DecisionDialogProps {
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  originBranchName?: string;
  receivingBranchName?: string;
  okayButtonName?: string;
  onHandleConfirmAction: () => void;
  onHandleClose: () => void;
}

const component: FC<DecisionDialogProps> = ({
  isOpen,
  title,
  subTitle = `Important Notice: You are initiating a transfer of item(s). Please confirm this action.`,
  originBranchName,
  receivingBranchName,
  okayButtonName = 'Confirm',
  onHandleConfirmAction,
  onHandleClose
}) => {
  const classes = useStyles();

  const onHandleConfirm = () => {
    onHandleConfirmAction();
    onHandleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: '1rem' }}
      >
        <DialogContent>
          <Typography style={{ fontWeight: 700, fontSize: '20px' }}>
            {title}
          </Typography>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ fontSize: '16px' }}>{subTitle}</Typography>
          </DialogContentText>
          <Box className={classes.MainBox}>
            <Box className={classes.subBox}>
              <Typography>{`From`}</Typography>
              <StorefrontIcon color="primary" style={{ fontSize: '50px' }} />
              <Typography
                style={{ fontWeight: 700 }}
              >{`${originBranchName}`}</Typography>
            </Box>
            <Box className={classes.MainBox}>
              <ArrowRightAltIcon style={{ fontSize: '60px' }} />
              <LocalShippingIcon color="primary" style={{ fontSize: '50px' }} />
              <ArrowRightAltIcon style={{ fontSize: '60px' }} />
            </Box>
            <Box className={classes.subBox}>
              <Typography>{`To`}</Typography>
              <StorefrontIcon style={{ fontSize: '50px' }} />
              <Typography style={{ fontWeight: 700 }}>
                {receivingBranchName}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <Divider style={{ margin: '1rem' }} />
        <DialogActions>
          <Button onClick={onHandleConfirm} color="primary" autoFocus>
            {okayButtonName}
          </Button>
          <Button onClick={onHandleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const TransferStockDialog = memo(component);
