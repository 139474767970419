import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { InitialState, SelectedLocationTab } from './types';
const initialState: InitialState = {
  regions: [],
  provinces: [],
  cities: [],
  barangays: [],
  isLoading: false,
  errors: { isError: false, errorMsg: '' },
  tabLocationState: {
    region: { name: 'region' },
    province: { name: 'province' },
    city: { name: 'city' },
    barangay: { name: 'barangay' }
  }
};

const resetMapLocation = (state: InitialState) => {
  state.regions = [];
  state.provinces = [];
  state.cities = [];
  state.barangays = [];
  state.tabLocationState = {
    region: { name: 'region', id: undefined },
    province: { name: 'province' },
    city: { name: 'city' },
    barangay: { name: 'barangay' }
  };
};

const setTabLocationStateAction = (
  state: InitialState,
  { payload }: PayloadAction<SelectedLocationTab>
) => {
  switch (payload?.location_type) {
    case 'Region': {
      state.tabLocationState = {
        province: { name: 'province' },
        city: { name: 'city' },
        barangay: { name: 'barangay' }
      };
      state.tabLocationState.region = {
        ...(state.tabLocationState?.region || {}),
        ...payload
      };
      break;
    }
    case 'Province': {
      //has mini reset
      state.tabLocationState.city = { name: 'city' };
      state.tabLocationState.barangay = { name: 'barangay' };
      state.tabLocationState.province = {
        ...(state.tabLocationState?.province || {}),
        ...payload
      };
      break;
    }
    case 'City': {
      //has mini reset
      state.tabLocationState.barangay = { name: 'barangay' };
      state.tabLocationState.city = {
        ...(state.tabLocationState?.city || {}),
        ...payload
      };
      break;
    }
    case 'Barangay': {
      state.tabLocationState.barangay = {
        ...(state.tabLocationState?.barangay || {}),
        ...payload
      };
      break;
    }
  }
};

const slices = createSlice({
  name: 'location',
  initialState: initialState,
  reducers: { setTabLocationStateAction, resetMapLocation },
  extraReducers(builder) {
    //regions
    builder.addCase(thunks.getRegionsThunk.rejected, (state) => {
      state.isLoading = false;
      state.errors = {
        isError: true,
        errorMsg: "Can't fetch regions data, please try again later."
      };
    });
    builder.addCase(thunks.getRegionsThunk.pending, (state) => {
      state.errors = { isError: false, errorMsg: '' };
      state.isLoading = true;
    });
    builder.addCase(thunks.getRegionsThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = { isError: false, errorMsg: '' };
      state.regions = payload?.originalData?.data?.data || [];

      //reset the preceding tab list
      state.provinces = [];
      state.cities = [];
      state.barangays = [];
    });
    //provinces
    builder.addCase(thunks.getProvincesThunk.rejected, (state) => {
      state.isLoading = false;
      state.errors = {
        isError: true,
        errorMsg: "Can't fetch provinces data, please try again later."
      };
    });
    builder.addCase(thunks.getProvincesThunk.pending, (state) => {
      state.errors = { isError: false, errorMsg: '' };
      state.isLoading = true;
    });
    builder.addCase(
      thunks.getProvincesThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = { isError: false, errorMsg: '' };
        state.provinces = payload?.originalData?.data?.data || [];

        //reset the preceding tab list
        state.cities = [];
        state.barangays = [];
      }
    );
    //cities
    builder.addCase(thunks.getCitiesThunk.rejected, (state) => {
      state.isLoading = false;
      state.errors = {
        isError: true,
        errorMsg: "Can't fetch cities data, please try again later."
      };
    });
    builder.addCase(thunks.getCitiesThunk.pending, (state) => {
      state.errors = { isError: false, errorMsg: '' };
      state.isLoading = true;
    });
    builder.addCase(thunks.getCitiesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.errors = { isError: false, errorMsg: '' };
      state.cities = payload?.originalData?.data?.data || [];

      state.barangays = [];
    });
    //barangays
    builder.addCase(thunks.getBarangaysThunk.rejected, (state) => {
      state.isLoading = false;
      state.errors = {
        isError: true,
        errorMsg: "Can't fetch barangays data, please try again later."
      };
    });
    builder.addCase(thunks.getBarangaysThunk.pending, (state) => {
      state.errors = { isError: false, errorMsg: '' };
      state.isLoading = true;
    });
    builder.addCase(
      thunks.getBarangaysThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.errors = { isError: false, errorMsg: '' };
        state.barangays = payload?.originalData?.data?.data || [];
      }
    );
  }
});
export const actions = { ...slices.actions, ...thunks };
export const reducer = slices.reducer;
