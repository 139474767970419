import React, { FC, memo } from 'react';
import {
  Box,
  Card,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import { ProductToBeShipCart } from 'src/types';
import { formatCurrency } from 'src/utils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { toInteger } from 'lodash';
import { theme } from 'src/theme';

interface Props {
  products?: ProductToBeShipCart[];
  onRemoveProduct: (id?: number) => void;
  onHandleQuantity: (id?: number, newQuantity?: number) => void;
}

const Component: FC<Props> = ({
  products,
  onRemoveProduct,
  onHandleQuantity
}) => {
  return (
    <Box
      style={{ maxHeight: '450px', overflowY: 'scroll', overflowX: 'hidden' }}
    >
      {products?.map((product, index) => (
        <Card key={index}>
          <Box
            sx={{
              padding: '1rem',
              marginBottom: '1rem',
              display: 'flex',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }
            }}
          >
            <Box>
              <Typography variant="h4">{product?.name}</Typography>
              <Typography variant="subtitle2">
                {product?.category_name}
              </Typography>
              <Typography variant="h5">
                {formatCurrency(product?.item_price_base_qty)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 7,
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                  width: '100%'
                },
                mt: 3
              }}
            >
              <TextField
                value={product?.quantity}
                onChange={(e) =>
                  onHandleQuantity(product?.id, toInteger(e.target.value))
                }
                label="qty"
                type="number"
                inputProps={{
                  min: 1,
                  style: { textAlign: 'center', width: '4rem' }
                }}
                variant="outlined"
              />
              <IconButton onClick={() => onRemoveProduct(product?.id)}>
                <DeleteForeverIcon fontSize="large" color="secondary" />
              </IconButton>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export const ProdctsToCalculateList = memo(Component);
