import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { Customer, CustomerListDetails } from 'src/types';
import { CustomerListItem } from './components';
import useResolution from 'src/hooks/useResolution';
import { TextRow } from 'src/views/product/component/TextRow';
import { AlternateRowContainer } from 'src/views/product/component/AlternateRowContainer';
import { LinkComponent } from 'src/components';

interface Props {
  onCustomerPress: (customer: Customer) => void;
  customers?: CustomerListDetails[];
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  onCustomerPress,
  className,
  customers = [],
  ...rest
}: Props) => {
  const classes = useStyles();
  const { isMobile } = useResolution();

  return isMobile ? (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        {customers.map((i, ind) => (
          <AlternateRowContainer index={ind} key={i.id}>
            <div style={{ padding: '.5em' }}>
              <TextRow
                label="Name"
                value={i.full_name}
                component={
                  <LinkComponent
                    openInNewTab
                    href={`/app/customers/${i.id}`}
                    title={i.full_name ?? ''}
                    font="Roboto, Helvetica, Arial, sans-serif "
                  />
                }
              />
              <TextRow label="Contact" value={i.contact_no} noBorderBottom />
            </div>
          </AlternateRowContainer>
        ))}
      </PerfectScrollbar>
    </Card>
  ) : (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((item: Customer) => (
                <CustomerListItem
                  key={item?.id}
                  customer={item}
                  onCustomerPress={onCustomerPress}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Results;
