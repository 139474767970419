import React, { FC, memo } from 'react';
import { Box, Card, IconButton, Tooltip, Typography } from '@material-ui/core';
import { copyToClipboard, formatCurrency } from 'src/utils';
import { theme } from 'src/theme';
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface Props {
  img?: string;
  title?: string;
  details?: string[];
  shipping_fee?: number;
}

const Component: FC<Props> = ({ img, title, details, shipping_fee }) => {
  const onHandleCopyShippingFee = (sf?: number) => {
    copyToClipboard(`${title} shipping fee: ${formatCurrency(sf)}` || '');
  };

  return (
    <Card
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem',
        margin: '1rem',
        width: '100%'
      }}
    >
      <img
        src={img}
        style={{ width: 80, height: 'auto', objectFit: 'contain' }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center'
          }
        }}
      >
        <Box>
          <Typography variant="h6">{title}</Typography>
          {details?.map((details, index) => (
            <Typography key={index} variant="body2" color="textSecondary">
              {`-${details}`}
            </Typography>
          ))}
        </Box>
        <Tooltip title={'Click to copy shipping fee to clipboard'}>
          <Box
            sx={{
              mt: 1.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
            onClick={() => onHandleCopyShippingFee(shipping_fee)}
          >
            <Typography variant="h2" style={{ cursor: 'pointer' }}>
              {` SF: ${formatCurrency(shipping_fee) || '--'}`}
            </Typography>
            <IconButton
              style={{ marginLeft: 5 }}
              onClick={() => onHandleCopyShippingFee(shipping_fee)}
            >
              <FileCopyIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Card>
  );
};

export const ShippingFeeCard = memo(Component);
