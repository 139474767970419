import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

interface Props {
  label: string;
  value?: string;
  component?: JSX.Element;
  isColumn?: boolean; // special use case for product names
  noBorderBottom?: boolean; // special use case for product names
}

const useStyles = makeStyles(() => ({
  textRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8em 0 ',
    rowGap: '.8em'
  },
  label: {
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center'
  }
}));

// This component is made as mobile versions of table rows
// It looks like this: Label --- Text/Component
export const TextRow: FC<Props> = ({
  label,
  value,
  component,
  noBorderBottom = false,
  isColumn = false
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.textRow}
      style={{
        flexDirection: isColumn ? 'column' : 'row',
        borderBottom: noBorderBottom ? '0px' : '1px solid rgba(0,0,0,0.21)'
      }}
    >
      <Typography className={classes.label}>{label}:</Typography>
      {/* We can either pass a jsx component here or plain text */}
      {component ? component : <Typography>{value || ''}</Typography>}
    </div>
  );
};
