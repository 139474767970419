import { colors } from 'src/constants';
import { TapTapCustomerFormField, TapTapCustomerPaymentField } from '../types';
import { taptapOptionsBranchReleased } from './taptap-branch-released';
import { taptapOptionsCouriers } from './taptap-courier';
import { taptapCustomerField } from './taptap-fields';
import { taptapOptionsServiceType } from './taptap-service-type';
import { taptapOptionsSource, taptapSource } from './taptap-source';
import {
  taptapOptionsEcommTransactionType,
  taptapOptionsFBTransactionType,
  taptapOptionsViberTransactionType
} from './taptap-transaction-types';
import { taptapOptionsTrialOS } from './taptap-trial-os';

export const taptapBuildTags = {
  FLAGSHIP: 'FLAGSHIP',
  REGULAR_BUILD: 'REGULAR BUILD',
  ENTRY: 'ENTRY'
};

export const taptapOptionsBuildTags = [
  {
    key: 'flagship',
    label: taptapBuildTags.FLAGSHIP,
    value: taptapBuildTags.FLAGSHIP
  },
  {
    key: 'regular_build',
    label: taptapBuildTags.REGULAR_BUILD,
    value: taptapBuildTags.REGULAR_BUILD
  },
  { key: 'entry', label: taptapBuildTags.ENTRY, value: taptapBuildTags.ENTRY }
];

export const taptapStatuses = {
  DONE_PAYMENT: 'DONE PAYMENT',
  ONGOING_DELIVERY: 'ONGOING DELIVERY',
  BUILDING: 'BUILDING',
  FOR_PAYMENT: 'FOR PAYMENT',
  HOLD: 'HOLD',
  ON_QUEUE: 'ON QUEUE',
  TESTING: 'TESTING',
  FOR_CHECKING: 'FOR CHECKING',
  FOR_PACK: 'FOR PACK',
  PREPARING_ITEMS: 'PREPARING ITEMS',
  CANCELLED: 'CANCELLED',
  READY_FOR_PICKUP: 'READY FOR PICKUP',
  FOR_FULLPAYMENT: 'FOR FULLPAYMENT',
  READY_FOR_DELIVERY: 'READY FOR DELIVERY',
  COMPLETED: 'COMPLETED'
};

export const taptapOptionsStatuses = [
  {
    key: 'done_payment',
    label: taptapStatuses.DONE_PAYMENT,
    value: taptapStatuses.DONE_PAYMENT,
    bgColor: colors.lightBlue[100]
  },
  {
    key: 'ongoing_delivery',
    label: taptapStatuses.ONGOING_DELIVERY,
    value: taptapStatuses.ONGOING_DELIVERY,
    bgColor: colors.lime[300]
  },
  {
    key: 'building',
    label: taptapStatuses.BUILDING,
    value: taptapStatuses.BUILDING,
    bgColor: colors.orange[300]
  },
  {
    key: 'for_payment',
    label: taptapStatuses.FOR_PAYMENT,
    value: taptapStatuses.FOR_PAYMENT,
    bgColor: colors.lightBlue[300]
  },
  {
    key: 'hold',
    label: taptapStatuses.HOLD,
    value: taptapStatuses.HOLD,
    bgColor: colors.grey[100]
  },
  {
    key: 'on_queue',
    label: taptapStatuses.ON_QUEUE,
    value: taptapStatuses.ON_QUEUE,
    bgColor: colors.blue[300]
  },
  {
    key: 'testing',
    label: taptapStatuses.TESTING,
    value: taptapStatuses.TESTING,
    bgColor: colors.deepOrange[300]
  },
  {
    key: 'for_checking',
    label: taptapStatuses.FOR_CHECKING,
    value: taptapStatuses.FOR_CHECKING,
    bgColor: colors.amber[500]
  },
  {
    key: 'for_pack',
    label: taptapStatuses.FOR_PACK,
    value: taptapStatuses.FOR_PACK,
    bgColor: colors.blueGrey[300]
  },
  {
    key: 'preparing_items',
    label: taptapStatuses.PREPARING_ITEMS,
    value: taptapStatuses.PREPARING_ITEMS,
    bgColor: colors.skyBlue
  },
  {
    key: 'cancelled',
    label: taptapStatuses.CANCELLED,
    value: taptapStatuses.CANCELLED,
    bgColor: colors.red[300]
  },
  {
    key: 'ready_for_pickup',
    label: taptapStatuses.READY_FOR_PICKUP,
    value: taptapStatuses.READY_FOR_PICKUP,
    bgColor: colors.purple[300]
  },
  {
    key: 'for_fullpayment',
    label: taptapStatuses.FOR_FULLPAYMENT,
    value: taptapStatuses.FOR_FULLPAYMENT,
    bgColor: colors.pink[100]
  },
  {
    key: 'ready_for_delivery',
    label: taptapStatuses.READY_FOR_DELIVERY,
    value: taptapStatuses.READY_FOR_DELIVERY,
    bgColor: colors.pink[300]
  },
  {
    key: 'completed',
    label: taptapStatuses.COMPLETED,
    value: taptapStatuses.COMPLETED,
    bgColor: colors.green[300]
  }
];

export const taptapTypeOfAssembly = {
  FREE_ASSEMBLY_SET: 'FREE ASSEMBLY (SET)',
  PARTS_WITH_SERVICE: 'PARTS ONLY WITH SERVICE',
  PARTS_WITH_FREE_ASSEMBLY: 'PARTS ONLY FREE ASSEMBLY'
};

export const taptapOptionsTypeOfAssembly = [
  {
    key: 'free_assembly_set',
    label: taptapTypeOfAssembly.FREE_ASSEMBLY_SET,
    value: taptapTypeOfAssembly.FREE_ASSEMBLY_SET
  },
  {
    key: 'parts_with_service',
    label: taptapTypeOfAssembly.PARTS_WITH_SERVICE,
    value: taptapTypeOfAssembly.PARTS_WITH_SERVICE
  },
  {
    key: 'parts_with_free_assembly',
    label: taptapTypeOfAssembly.PARTS_WITH_FREE_ASSEMBLY,
    value: taptapTypeOfAssembly.PARTS_WITH_FREE_ASSEMBLY
  }
];

export const taptapSalesTransferTypes = {
  MAIN_TO_LPC: 'MAIN TO LPC',
  LPC_TO_MAIN: 'LPC TO MAIN',
  MAIN_TO_EXPERIENCE: 'MAIN TO EXPERIENCE',
  EXPERIENCE_TO_MAIN: 'EXPERIENCE TO MAIN',
};

export const taptapOptionsSalesTransferType = [
  {
    key: 'main_to_lpc',
    label: taptapSalesTransferTypes.MAIN_TO_LPC,
    value: taptapSalesTransferTypes.MAIN_TO_LPC
  },
  {
    key: 'lpc_to_main',
    label: taptapSalesTransferTypes.LPC_TO_MAIN,
    value: taptapSalesTransferTypes.LPC_TO_MAIN
  },
  {
    key: 'main_to_experience',
    label: taptapSalesTransferTypes.MAIN_TO_EXPERIENCE,
    value: taptapSalesTransferTypes.MAIN_TO_EXPERIENCE
  },
  {
    key: 'experience_to_main',
    label: taptapSalesTransferTypes.EXPERIENCE_TO_MAIN,
    value: taptapSalesTransferTypes.EXPERIENCE_TO_MAIN
  },
];

export const taptapAddCustomerFields: TapTapCustomerFormField[] = [
  {
    key: taptapCustomerField.INTERNAL_QUOTATION_ID,
    type: 'text',
    label: 'Quotation ID',
    grid: 'half',
    disabled: true,
    hidden: true
  },
  {
    key: taptapCustomerField.INTERNAL_TRANSACTION_NO,
    type: 'text',
    label: 'Transaction No.',
    grid: 'half',
    disabled: true,
    hidden: true
  },
  {
    key: taptapCustomerField.FIRST_NAME,
    type: 'text',
    label: 'First Name',
    grid: 'half',
    required: true
  },
  {
    key: taptapCustomerField.LAST_NAME,
    type: 'text',
    label: 'Last Name',
    grid: 'half'
  },
  {
    key: taptapCustomerField.STATUS,
    type: 'autocomplete',
    label: 'Status',
    options: taptapOptionsStatuses
  },
  { key: taptapCustomerField.CONTACT_NO, type: 'text', label: 'Contact No' },
  { key: taptapCustomerField.ADDRESS, type: 'longText', label: 'Address' },
  {
    key: taptapCustomerField.SOURCE,
    type: 'radio',
    label: 'Source',
    options: taptapOptionsSource
  },
  {
    key: taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
    type: 'profile',
    label: 'Online Sales Representative',
    options: [],
    conditional: {
      dependsOn: 'source',
      value: [
        taptapSource.FACEBOOK,
        taptapSource.VIBER,
        taptapSource.ECOMM,
        taptapSource.WALK_IN
      ]
    }
  },
  {
    key: taptapCustomerField.SALES_REP,
    type: 'profile',
    label: 'Sales Rep.',
    options: [],
    conditional: {
      dependsOn: 'source',
      value: [
        taptapSource.FACEBOOK,
        taptapSource.VIBER,
        taptapSource.ECOMM,
        taptapSource.WALK_IN
      ]
    }
  },
  {
    key: taptapCustomerField.FB_TRANSACTION_TYPE,
    type: 'radio',
    label: 'FB Transaction Type',
    options: taptapOptionsFBTransactionType,
    conditional: {
      dependsOn: 'source',
      value: [taptapSource.FACEBOOK]
    }
  },
  {
    key: taptapCustomerField.VIBER_TRANSACTION_TYPE,
    type: 'radio',
    label: 'Viber Transaction Type',
    options: taptapOptionsViberTransactionType,
    conditional: {
      dependsOn: 'source',
      value: [taptapSource.VIBER]
    }
  },
  {
    key: taptapCustomerField.ECOMM_TRANSACTION_TYPE,
    type: 'radio',
    label: 'Ecomm Transaction Type',
    options: taptapOptionsEcommTransactionType,
    conditional: {
      dependsOn: 'source',
      value: [taptapSource.ECOMM]
    }
  },
  {
    key: taptapCustomerField.BRANCH_RELEASED,
    type: 'radio',
    label: 'Branch Released',
    options: taptapOptionsBranchReleased
  },
  {
    key: taptapCustomerField.COURIER,
    type: 'radio',
    label: 'Courier',
    options: taptapOptionsCouriers
  },
  {
    key: taptapCustomerField.SERVICE_TYPE,
    type: 'radio',
    label: 'Service Type',
    options: taptapOptionsServiceType
  },
  {
    key: taptapCustomerField.TOTAL_AMOUNT,
    type: 'number',
    label: 'Total Amount'
  },
  {
    key: taptapCustomerField.SHIPPING_FEE,
    type: 'number',
    label: 'Shipping Fee',
    grid: 'half'
  },
  {
    key: taptapCustomerField.IS_SHIPPING_FEE_PAID,
    type: 'boolean',
    label: 'Is Shipping Fee Paid?',
    grid: 'half'
  },
  {
    key: taptapCustomerField.TRIAL_OS,
    type: 'radio',
    label: 'Trial OS',
    options: taptapOptionsTrialOS
  },
  { key: taptapCustomerField.REMARKS, type: 'longText', label: 'Remarks' },
  {
    key: taptapCustomerField.IS_AGREE_TNC,
    type: 'boolean',
    label: 'Agree to Terms and Conditions?',
    required: true
  }
];

export const taptapCustomerDetailsFields: TapTapCustomerFormField[] = [
  // Personal Information
  {
    key: taptapCustomerField.FIRST_NAME,
    type: 'text',
    label: 'First Name',
    grid: 'half',
    required: true
  },
  {
    key: taptapCustomerField.LAST_NAME,
    type: 'text',
    label: 'Last Name',
    grid: 'half'
  },
  {
    key: taptapCustomerField.CONTACT_NO,
    type: 'text',
    label: 'Contact No'
  },
  {
    key: taptapCustomerField.ADDRESS,
    type: 'longText',
    label: 'Address'
  },
  {
    key: taptapCustomerField.STATUS,
    type: 'autocomplete',
    label: 'Status',
    options: taptapOptionsStatuses
  },

  // Preferences and Source
  {
    key: taptapCustomerField.SOURCE,
    type: 'radio',
    label: 'Source',
    options: taptapOptionsSource
  },
  {
    key: taptapCustomerField.ONLINE_SALES_REPRESENTATIVE,
    type: 'profile',
    label: 'Online Sales Representative',
    options: []
  },
  {
    key: taptapCustomerField.SALES_REP,
    type: 'profile',
    label: 'Sales Rep',
    options: []
  },
  {
    key: taptapCustomerField.FB_TRANSACTION_TYPE,
    type: 'radio',
    label: 'FB Transaction Type',
    options: taptapOptionsFBTransactionType
  },
  {
    key: taptapCustomerField.VIBER_TRANSACTION_TYPE,
    type: 'radio',
    label: 'Viber Transaction Type',
    options: taptapOptionsViberTransactionType
  },
  {
    key: taptapCustomerField.ECOMM_TRANSACTION_TYPE,
    type: 'radio',
    label: 'Ecomm Transaction Type',
    options: taptapOptionsEcommTransactionType
  },

  // Order Details
  {
    key: taptapCustomerField.SERVICE_TYPE,
    type: 'radio',
    label: 'Service Type',
    options: taptapOptionsServiceType
  },
  {
    key: taptapCustomerField.TYPE_OF_BUILD,
    type: 'radio',
    label: 'Type Of Build',
    options: taptapOptionsTypeOfAssembly
  },
  {
    key: taptapCustomerField.BUILD_TAG,
    type: 'radio',
    label: 'Build Tag',
    options: taptapOptionsBuildTags
  },
  {
    key: taptapCustomerField.KIND_OF_SALES_TRANSFER,
    type: 'radio',
    label: 'Kind of Sales Transfer',
    options: taptapOptionsSalesTransferType
  },
  {
    key: taptapCustomerField.BRANCH_RELEASED,
    type: 'radio',
    label: 'Branch Released',
    options: taptapOptionsBranchReleased
  },
  {
    key: taptapCustomerField.TRIAL_OS,
    type: 'radio',
    label: 'Trial OS',
    options: taptapOptionsTrialOS
  },
  {
    key: taptapCustomerField.TECH,
    type: 'profile',
    label: 'Tech',
    options: []
  },

  // Payment Details
  {
    key: taptapCustomerField.COURIER,
    type: 'radio',
    label: 'Courier',
    options: taptapOptionsCouriers
  },
  {
    key: taptapCustomerField.SHIPPING_FEE,
    type: 'number',
    label: 'Shipping Fee',
    grid: 'half'
  },
  {
    key: taptapCustomerField.IS_SHIPPING_FEE_PAID,
    type: 'boolean',
    label: 'Is Shipping Fee Paid?',
    grid: 'half'
  },
  {
    key: taptapCustomerField.TOTAL_AMOUNT,
    type: 'number',
    label: 'Total Amount'
  },
  {
    key: taptapCustomerField.WARRANTY_SLIP_NO,
    type: 'text',
    label: 'Warranty Slip No'
  },

  // Optional/Conditional Details
  {
    key: taptapCustomerField.REMARKS,
    type: 'longText',
    label: 'Remarks'
  },
  {
    key: taptapCustomerField.IS_AGREE_MARKETING,
    type: 'boolean',
    label: 'Agree to receive newsletter and promotional from PC Worth?'
  },

  // Agreements
  {
    key: taptapCustomerField.IS_AGREE_TNC,
    type: 'boolean',
    label: 'Agree to Terms and Conditions?',
    required: true
  }
];

export const taptapCustomerPaymentFields: TapTapCustomerPaymentField[] = [
  {
    key: 'screenshots',
    type: 'image',
    label: 'Screen Shot'
  }
];
