import {
  Typography,
  Card,
  CardContent,
  Box,
  Grid,
  Button
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import useUserInfo from 'src/hooks/user/use-user-info';
import { toTitleCase } from 'src/utils';

const useStyles = makeStyles(() => ({
  avatar: {
    display: 'block',
    margin: '1em auto',
    width: 250,
    height: 250
  },
  navigation: {
    marginTop: 2,
    height: '180px'
  },
  nameAndDate: {
    fontSize: '1.7em'
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },

  flexRow: {
    width: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: '10px'
  },
  button: {
    marginTop: 'auto'
  }
}));

const WelcomePage = () => {
  const classes = useStyles();
  const { userDetails } = useUserInfo();

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box sx={{ p: 2, height: '90vh' }}>
      <Typography variant="h4" gutterBottom style={{ fontSize: '2em' }}>
        Welcome to the Internal Systems
      </Typography>
      <Box className={classes.flexRow}>
        <Typography variant="h6" className={classes.nameAndDate}>
          {toTitleCase(userDetails?.first_name ?? '')}
        </Typography>
        <Typography variant="h6" className={classes.nameAndDate}>
          {/* intended space */}
          {currentDateTime.toLocaleDateString()}{' '}
          {currentDateTime.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          })}
        </Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        This platform allows you to manage various aspects of the business,
        including customer management, transactions, inventory, and more. Use
        the navigation links below to get started.
      </Typography>

      {/* TODO: Make this recently visited in the future. */}
      <Grid container spacing={3} style={{ marginTop: 2 }}>
        <Grid item xs={12} md={4}>
          <Card className={classes.navigation}>
            <CardContent className={classes.flexCol}>
              <Typography variant="h6" gutterBottom>
                Products
              </Typography>
              <Typography variant="body2">
                Track and manage your product inventory, including stock levels
                and supplier information.
              </Typography>
              <Button
                href={'/app/products'}
                className={classes.button}
                variant="contained"
              >
                Go to Products
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.navigation}>
            <CardContent className={classes.flexCol}>
              <Typography variant="h6" gutterBottom>
                Inventory
              </Typography>
              <Typography variant="body2">
                Manage products, view total gross price, item status and more.
              </Typography>
              <Button
                href={'/app/inventory'}
                className={classes.button}
                variant="contained"
                style={{ marginTop: 'auto' }}
              >
                Go to Inventory
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.navigation}>
            <CardContent className={classes.flexCol}>
              <Typography variant="h6" gutterBottom>
                Transfer Stock
              </Typography>
              <Typography variant="body2">
                View and manage stocks for branch transfer
              </Typography>
              <Button
                href={'/app/transfer-stock'}
                className={classes.button}
                variant="contained"
              >
                Go to Transfer Stock
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.navigation}>
            <CardContent className={classes.flexCol}>
              <Typography variant="h6" gutterBottom>
                Shipping Fee Calculator
              </Typography>
              <Typography variant="body2">
                Calculate the total shipping cost based on the products and
                distance.
              </Typography>
              <Button
                href={'/app/shipping-fee-calculator'}
                className={classes.button}
                variant="contained"
              >
                Go to Shipping Fee Calculator
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h5" style={{ marginTop: '3em' }}>
        Navigation Guide
      </Typography>
      <Typography variant="body1">
        Explore the main sections: Sales, Inventory, Marketing, and Admin/User
        Sections. Each section provides essential tools and information to
        manage different aspects of our system.
      </Typography>

      <img
        alt="kachi"
        className={classes.avatar}
        src="/static/images/kachi/kachiSmile.png"
      />
    </Box>
  );
};

export default WelcomePage;
