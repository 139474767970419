/* eslint-disable no-unused-vars */

import React, { useState, FC } from 'react';
import { useSnackBar } from 'src/hooks';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CustomKeyboardEvent } from 'src/types';
import { LoadingButton, SelectImageButton } from 'src/components';
import { convertImageFileToBase64 } from 'src/utils';
import { Paper } from '@material-ui/core';
import { WarrantyDetails } from './WarrantyDetails';

interface Props {
  isVisible: boolean;
  loading: boolean;
  handleClose: () => void;
  onAddPress: (
    name: string,
    iconUrl: string,
    bannerUrl?: string,
    warrantyDetails?: string
  ) => void;
}

export const ManufacturerAddDialog: FC<Props> = ({
  isVisible = false,
  loading,
  handleClose,
  onAddPress
}) => {
  const snackBar = useSnackBar();

  const [name, setName] = useState<string>('');
  const [errName, setErrName] = useState<string>('');
  const [iconUrl, setIconUrl] = useState<string>('');
  const [bannerUrl, setBannerUrl] = useState<string>('');
  const [warrantyDetails, setWarrantyDetails] = useState<string>('');

  const onImageSelected = async (img: File[], action: string) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      switch (true) {
        case action === 'icon':
          setIconUrl(imgInBase64);
          break;
        case action === 'banner':
          setBannerUrl(imgInBase64);
          break;
      }
    }
  };

  const resetState = () => {
    setName('');
    setIconUrl('');
    setBannerUrl('');
    setErrName('');
  };

  const onAddBtnPress = () => {
    if (loading) {
      return;
    }
    if (!name) {
      setErrName('Invalid Value');
      return;
    }
    if (!iconUrl) {
      snackBar.show({
        severity: 'error',
        message: `Manufacturer Image Icon is Required`
      });
      return;
    }

    onAddPress(name, iconUrl, bannerUrl, warrantyDetails);
    resetState();
  };

  const onCancelBtnPress = () => {
    resetState();
    handleClose();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddBtnPress();
    }
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Manufacturer</DialogTitle>
      <DialogContent>
        <DialogContentText>Input Manufacturer</DialogContentText>
        <TextField
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label={'Manufacturer'}
          fullWidth
          required
          variant="outlined"
          value={name}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrName('');
            setName(e.target.value);
          }}
        />
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem'
          }}
        >
          {iconUrl && (
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              src={iconUrl}
            />
          )}
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="Select Manufacturer Icon"
            helperText="Size Up to 3MB and only jpeg, jpg and png is allowed"
            multiple={false}
            onImageSelected={(e) => onImageSelected(e, 'icon')}
          />
        </Paper>
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1em',
            padding: '1rem'
          }}
        >
          {bannerUrl && (
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              src={bannerUrl}
            />
          )}
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="(Optional) Select Manufacturer Banner"
            helperText="Size Up to 3MB and only jpeg, jpg and png is allowed,"
            multiple={false}
            onImageSelected={(e) => onImageSelected(e, 'banner')}
          />
        </Paper>
        <WarrantyDetails
          style={{ margin: '1em 0' }}
          warrantyDetails={warrantyDetails}
          onChange={(val: string) => setWarrantyDetails(val)}
        />
      </DialogContent>
      <DialogActions>
        <>
          <LoadingButton
            title="Add"
            loading={loading}
            onClick={onAddBtnPress}
            color="primary"
          />
          <Button onClick={onCancelBtnPress}>Cancel</Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
