// Enums
export enum DrawerItemLabelEnum {
  Account = 'Account',
  Audits = 'Audits',
  Branches = 'Branches',
  Sales_Section = 'Sales Section',
  Ecom_Orders = 'E-commerce Orders',
  TapTap = 'TapTap',
  Customers_Queueing = 'Customers Queueing',
  Customers = 'Customers',
  PcwUser = 'Pcw-Users',
  Quotation = 'Quotation',
  PC_Build_Gallery = 'Builds Gallery',
  Saved_Builds = 'User Saved Builds',
  Flash_Deals = 'Flash Deals',
  PC_Build = 'PC Build',
  Dashboard = 'Dashboard',
  Products = 'Products',
  Inventory = 'Inventory',
  Inventory_Audits = 'Inventory Audit',
  Inventory_Audits_Logs = 'Inventory Audit Logs',
  Categories = 'Categories',
  Suppliers = 'Suppliers',
  Manufacturers = 'Manufacturers',
  Sales = 'Sales',
  Roles_Permissions = 'Roles & Permissions',
  Price_List = 'Price List',
  Price_List_V2 = 'Price List v2',
  Manage_Users = 'Manage Users',
  Transactions = 'Transactions',
  Ecomm_Banners = 'Ecomm Banner',
  RMA = 'RMA',
  Logout = 'Logout',
  Available_Inventory = 'Available Inventory',
  PC_Package = 'PC Package',
  Options = 'Options',
  SMS_Blast = 'SMS Blast',
  Minigame = 'Minigame',
  EventCode = 'Event Code',
  KachiPoints = 'Kachi Points',
  Stock_Transfer = 'Transfer Stock',
  Transfer_Logs = 'Transfer Logs',
  Out_Of_Stock = 'Out Of Stock',
  Decaying_Stock = 'Decaying Stock',
  Fast_Moving_Products = 'Fast Moving Products',
  Blogs = 'Blogs',
  Games = 'Games',
  PC_Bundles = 'PC Bundles',
  Blog_Category = 'Blog Category',
  Shipping_Fee_Calculator = 'Shipping Calculator',
  Promotional = 'Promotionals'
}
