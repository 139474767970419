import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Product, ProductsThunkRequest } from 'src/types';
import { Search as SearchIcon } from 'react-feather';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';

const { actions: productActions } = slices.product;

interface Props {
  label?: string;
  placeholder?: string;
  debounceDelay?: number;
  branch_id?: number[];
  onOptionSelected: (option?: ProductsThunkRequest) => void;
}

const component: FC<Props> = ({
  label = 'Search Products',
  placeholder = '',
  debounceDelay = 1000,
  branch_id,
  onOptionSelected
}) => {
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<any[]>([]);

  const getProducts = useCallback(
    async (request: ProductsThunkRequest) => {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(productActions.getProductsThunk(request)).finally(() =>
          setIsLoading(false)
        )
      );
      if (response.success && response.originalData?.data) {
        setProducts(response.originalData.data || []);
      }
    },
    [dispatch]
  );

  const handleOptionSelected = (option?: ProductsThunkRequest) => {
    if (!options) {
      return;
    }
    onOptionSelected(option);
  };

  const onHanddleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !isLoading) {
      e.preventDefault(); // Prevent default behavior
      const selectedOption = products.find(
        (option) => option.product_name === inputValue
      );
      handleOptionSelected(selectedOption);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (inputValue === '') {
        setOptions([]);
        return;
      }
      if (inputValue !== '') {
        getProducts({ keyword: inputValue, branch_ids: branch_id });
        return;
      }
    };
    const debounceTimeout = setTimeout(fetchData, debounceDelay);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [branch_id, debounceDelay, getProducts, inputValue]);

  return (
    <Autocomplete
      options={products}
      getOptionLabel={(option) => `${option.name}`}
      onChange={(event, newValue: any) => handleOptionSelected(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onHanddleKeyDown}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          disabled={isLoading}
        />
      )}
    />
  );
};

export const SearchProductDetail = memo(component);
