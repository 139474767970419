import React, { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { copyToClipboard, formatDate } from 'src/utils';
import { colors } from 'src/constants';
import { QueueCustomer } from '../index';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
  customer: QueueCustomer;
  onDeleteQueueCustomer: () => void;
}

const CustomerQueueItem = ({ customer, onDeleteQueueCustomer }: Props) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Grid key={customer?.id} item xs={12} md={6}>
      <Card elevation={5}>
        <CardHeader
          style={{ backgroundColor: colors.grey[100] }}
          title={
            <Typography>
              {customer?.first_name} {customer?.last_name}
            </Typography>
          }
          action={
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={onDeleteQueueCustomer}
            >
              <DeleteIcon />
            </Button>
          }
          subheader={
            <Typography variant="caption">
              {customer?.branch_released} -{' '}
              {` ${formatDate(customer?.created_at, 'MMM d, yyyy h:mm a')}`}
            </Typography>
          }
        />
        <Divider />
        <CardContent>
          {customer?.initial_concern && (
            <Typography
              display="inline"
              variant="h5"
              onClick={toggleShowMore}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: showMore ? 'none' : 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Concern: {customer?.initial_concern || 'N/A'}
            </Typography>
          )}

          {customer?.address && (
            <Typography
              display="inline"
              variant="h5"
              onClick={toggleShowMore}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: showMore ? 'none' : 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              Address: {customer?.address}
            </Typography>
          )}

          {customer?.contact_no && (
            <Typography color="textSecondary" variant="h5">
              Contact No: {customer?.contact_no || 'N/A'}
            </Typography>
          )}
          {/*  */}
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<FingerprintIcon />}
            onClick={() => copyToClipboard(customer?.uuid || '')}
          >
            COPY UUID TO ADD TO TAPTAP: {customer?.uuid}
          </Button>
        </CardActions>
      </Card>
 
    </Grid>
  );
};

export default CustomerQueueItem;
