import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';
const selectPCBundlesState = (state: RootState) => state.location;
const selectIsLoading = createSelector(
  selectPCBundlesState,
  (state) => state.isLoading
);
const selectErrors = createSelector(
  selectPCBundlesState,
  (state) => state.errors
);
const selectRegions = createSelector(
  selectPCBundlesState,
  (state) => state.regions
);
const selectProvinces = createSelector(
  selectPCBundlesState,
  (state) => state.provinces
);
const selectCities = createSelector(
  selectPCBundlesState,
  (state) => state.cities
);
const selectBarangays = createSelector(
  selectPCBundlesState,
  (state) => state.barangays
);
const selectLocationState = createSelector(
  selectPCBundlesState,
  (state) => state.tabLocationState
);

export const selectors = {
  selectIsLoading,
  selectErrors,
  selectRegions,
  selectProvinces,
  selectCities,
  selectBarangays,
  selectLocationState
};
