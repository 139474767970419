import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Box,
  Button,
  Card,
  makeStyles,
  Typography
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';

import { AlertDialog, LoaderBar, Page } from 'src/components';
import {
  chunkArrayInGroups,
  forEachPromise,
  getCategoryNameViaId,
  isValidCurrency,
  normalizeFieldText
} from 'src/utils';
import { CategoriesEnum, ProductTypeEnum } from 'src/enums';
import { slices, useAppDispatch } from 'src/redux';
import { colors, localize } from 'src/constants';

import {
  ProductPartDetailsCategory,
  CustomInputEvent,
  WarrantyDuration,
  warranties
} from 'src/types';
import {
  Product,
  ProductCustomSpecifications,
  ProductImage
} from 'src/types/product';

import {
  useAlertGlobal,
  useIsMount,
  useSnackBar,
  usePermissions,
  useProductPartDetailsLogic
} from 'src/hooks';
import {
  ProductGeneralInfo,
  ProductImagesInfo,
  ProductInfoCustomSpecs,
  ProductInfoFooter,
  ProductInfoPricing,
  ProductInfoSummary
} from './components';
import { ProductPartDetails } from '../component';
import { AddProductReferences } from '../product-add/component';
import { cloneDeep, isEqual } from 'lodash';
import usePrompt from 'src/utils/navigation-prompt';
import { ProductDescriptionModal } from './components/ProductDescriptionModal';

const { actions: productActions } = slices.product;
const { actions: productPartActions } = slices.productPartDetails;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.common.white,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const ProductInformationView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const alertGlobal = useAlertGlobal();
  const permissions = usePermissions();
  const isFirstMount = useIsMount();
  const dispatch = useAppDispatch();
  const { transformToValidDataForUpdate } = useProductPartDetailsLogic();

  const { id } = useParams();
  const { canUpdateProductDescription } = usePermissions();

  const {
    resetProductPartsStates,
    isProductDetailOfKeyValid
  } = useProductPartDetailsLogic();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteTransactionDialog, setDeleteTransactionDialog] = useState<
    boolean
  >(false);
  const [productDetailsOrig, setProductDetailsOrig] = useState<
    Product | undefined
  >();
  const [productDetails, setProductDetails] = useState<Product | undefined>();
  const [
    selectedWarranty,
    setSelectedWarranty
  ] = useState<WarrantyDuration | null>(null);
  const [invalidProductOpen, setInvalidProductOpen] = useState(false);

  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [productImagesLoading, setProductImagesLoading] = useState<boolean>(
    false
  );
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [imgUploadProgress, setImgUploadProgress] = useState<number>(0);

  const [isOpenProdDescription, setIsOpenProdDescription] = useState<boolean>(
    false
  );

  const isProductConsumable = useMemo(
    () => productDetails?.product_type === ProductTypeEnum.Consumable,
    [productDetails]
  );

  const decodedHtmlDescription = useMemo(() => {
    if (productDetails?.description_html_url && productDetails) {
      const decodeBase64 = decodeURIComponent(
        escape(window.atob(productDetails?.description_html_url))
      );
      return decodeBase64;
    }
    return '';
  }, [productDetails]);

  //base 64 found or no image file path, meaning it has new upload disable drag image function
  const hasBase64Image = useMemo(() => {
    return productImages.some((item) => item.image_filepath === '');
  }, [productImages]);

  const isSelectedCategoryRAM = useMemo(() => {
    // RAM - 13 on stage, prod and dev
    // NOTE: RAM's ID on database should always be 13
    if (productDetails?.category_id === CategoriesEnum.RAM) {
      return true;
    }
    return false;
  }, [productDetails]);

  const memoizedProductId = useMemo(() => {
    if (id) {
      return +id;
    }
    return 0;
  }, [id]);

  const getProductPartDetails = useCallback(
    async (product_id: number, category_name: ProductPartDetailsCategory) => {
      if (id) {
        await dispatch(
          productPartActions.getProductPartDetailsThunk({
            product_id,
            category_name
          })
        );
      }
    },
    [dispatch, id]
  );

  const getProductImages = useCallback(
    async (product_id: number) => {
      setProductImagesLoading(true);
      const imgsRes = unwrapResult(
        await dispatch(
          productActions.getProductImagesThunk(product_id)
        ).finally(() => setProductImagesLoading(false))
      );
      if (imgsRes?.success && imgsRes?.originalData?.data?.images) {
        const imgs = imgsRes?.originalData?.data?.images;
        if (imgs?.length > 0) {
          setProductImages(imgs);
        }
      }
    },
    [dispatch]
  );

  const getProductDetails = useCallback(async () => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        productActions.getProductsViaIdThunk({ id: +id })
      ).finally(() => setIsLoading(false))
    );
    if (response.originalData.product === null) {
      setInvalidProductOpen(true);
    }
    if (response?.success && response.originalData.product) {
      const productDetails = response.originalData.product;
      setProductDetails(response.originalData.product);
      setProductDetailsOrig(response.originalData.product);
      // getting product part details
      const categoryName = getCategoryNameViaId(productDetails?.category_id);
      if (productDetails?.id && productDetails?.category_id && categoryName) {
        getProductPartDetails(productDetails.id, categoryName);
      }

      // getting product images
      if (productDetails?.id) {
        getProductImages(productDetails?.id);
      }
    }
  }, [dispatch, getProductImages, getProductPartDetails, id]);

  const onInvalidProductAlertClose = () => {
    setInvalidProductOpen(false);
    navigate(-1);
  };

  const updateUploadProgress = (newNum: number = 0) => {
    setImgUploadProgress(newNum);
  };

  const onImagesUpdateApi = () => {
    // no images found.
    const clonedImage = cloneDeep(productImages);
    const photosStrArr = clonedImage
      ?.filter((x) => x?.image_filepath === '')
      ?.map((y) => y.url);

    if (photosStrArr?.length <= 0) {
      return;
    }

    setIsImageUploading(true);
    snackBar.show({ severity: 'info', message: localize.IMG_UPLOADING });
    if (photosStrArr && photosStrArr?.length > 0) {
      const groupedByTwo = chunkArrayInGroups(photosStrArr, 2);
      const groupLength = groupedByTwo?.length;
      forEachPromise(groupedByTwo, (item, uploadedIndex) => {
        return new Promise((resolve) => {
          process.nextTick(() => {
            dispatch(
              productActions.createProductImagesThunk({
                product_id: memoizedProductId,
                images: item
              })
            )
              .then(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              })
              .catch(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              });
          });
        });
      }).then(() => {
        setImgUploadProgress(0);
        setIsImageUploading(false);
        snackBar.show({
          severity: 'success',
          message: localize.SUC_IMG_UPLOAD,
          useSound: true
        });

        // After upload success get images again.
        if (productDetails?.id) {
          getProductImages(productDetails?.id);
        }
      });
    }
  };

  const onUpdateProductPartsApi = async (
    newProductInfo: Product,
    categoryKey: ProductPartDetailsCategory
  ) => {
    const newProductDetail = transformToValidDataForUpdate(categoryKey);
    setIsLoading(true);
    await dispatch(
      productPartActions.updateProductDetailsThunk({
        category_name: categoryKey,
        product_id: newProductInfo?.id,
        ...newProductDetail
      })
    ).finally(() => setIsLoading(false));
  };

  const onUpdateProductInfoApi = async (newProductInfo: Product) => {
    setIsLoading(true);

    // Update product info only
    const updateProductRes = unwrapResult(
      await dispatch(
        productActions.updateProductsThunk(newProductInfo)
      ).finally(() => setIsLoading(false))
    );

    // If product basic info update is success..
    // Try to update product parts also if it is a product part
    if (updateProductRes?.success && updateProductRes?.message) {
      // checks if its a product part category.
      // 'casing' | 'cpu' | 'gpu' | 'hdd' | 'motherboard' | 'psu' | 'ram' | 'ssd'
      const categoryIsPartKey = getCategoryNameViaId(
        newProductInfo?.category_id
      );
      if (categoryIsPartKey) {
        onUpdateProductPartsApi(newProductInfo, categoryIsPartKey);
      }
    }

    // We will try to asynchronously call products images update api.
    onImagesUpdateApi();
  };

  const onSaveDetailPress = async (
    newProductInfo: Product,
    priceChecker: boolean = true
  ) => {
    if (priceChecker) {
      if (
        newProductInfo?.dealers_price !== productDetails?.dealers_price ||
        newProductInfo?.retail_price !== productDetails?.retail_price
      ) {
        setIsLoading(false);
        if (!permissions.canEditPriceListPrice) {
          alert(':P No to inspect element');
          return;
        }
        alertGlobal.show({
          title: 'Price Change',
          subtitle:
            'Retail price and/or Dealer price is updated. Are you sure?',
          buttons: [
            {
              text: 'Yes',
              onClick: () => {
                onSaveDetailPress(newProductInfo, false);
                alertGlobal.hide();
              },
              color: 'secondary'
            },
            {
              text: 'Cancel',
              onClick: () => alertGlobal.hide()
            }
          ]
        });
        return;
      }
    }
    if (!isProductConsumable) {
      newProductInfo.consumable_unit = undefined;
    }
    onUpdateProductInfoApi(newProductInfo);
  };

  const onDeleteProduct = async (productInfo?: Product) => {
    // TODO: Add a checker if is on transaction. (Check trello) / Should be on backend
    if (productInfo?.id) {
      const response = unwrapResult(
        await dispatch(productActions.deleteProductThunk(productInfo.id))
      );
      if (response?.success && response?.message) {
        snackBar.show({ severity: 'success', message: response.message });
        dispatch(productActions.removeProduct(productInfo.id));
        await dispatch(
          productPartActions.deleteProductDetailsThunk({
            category_name: getCategoryNameViaId(productInfo?.category_id),
            product_id: productInfo.id
          })
        );
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (isFirstMount) {
      getProductDetails();
    }
    // intended disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstMount]);

  const deleteThisItem = () => {
    onDeleteProduct(productDetails);
  };

  const onDeleteProductPress = () => {
    setDeleteTransactionDialog(true);
  };

  const handleClose = () => {
    setDeleteTransactionDialog(false);
  };

  const onSaveDetailValidate = async () => {
    setIsLoading(true);
    if (!productDetails?.ec_display_name) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide ecomm product display name'
      });
      setIsLoading(false);
      return;
    }

    if (!selectedWarranty) {
      snackBar.show({
        severity: 'error',
        message: 'Please select a valid warranty option'
      });
      setIsLoading(false);
      return;
    }

    if (!productDetails?.name) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide product name'
      });
      setIsLoading(false);
      return;
    }

    if (!isValidCurrency(productDetails?.dealers_price, true)) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_INVALID_DEALERS_PRICE
      });
      setIsLoading(false);
      return;
    }

    if (selectedCategoryNameLowerCase) {
      if (!isProductDetailOfKeyValid(selectedCategoryNameLowerCase)) {
        setIsLoading(false);
        return;
      }
    }

    const newProductInfo: Product = {
      id: productDetails?.id,
      ec_display_name: productDetails?.ec_display_name,
      name: productDetails?.name,
      retail_price: +(productDetails?.retail_price || 0),
      dealers_price: +(productDetails?.dealers_price || 0),
      category_id: productDetails?.category_id,
      manufacturer_id: productDetails?.manufacturer_id,
      ram_kit: isSelectedCategoryRAM ? productDetails?.ram_kit : undefined,
      is_hidden_pricelist: productDetails?.is_hidden_pricelist,
      srp_exempted: productDetails?.srp_exempted,
      product_type: productDetails?.product_type,
      warranty_duration: selectedWarranty?.value,
      consumable_unit: productDetails?.consumable_unit || undefined,
      specification: productDetails?.specification,
      description: productDetails?.description || '',
      addtl_non_bundle_price: productDetails?.addtl_non_bundle_price,
      is_bundle: productDetails?.is_bundle,
      sku: productDetails?.sku,
      references:
        productDetails?.references && productDetails?.references?.length > 0
          ? productDetails?.references
          : undefined
    };

    if (newProductInfo?.product_type !== ProductTypeEnum.Consumable) {
      newProductInfo.consumable_unit = undefined;
    }

    onSaveDetailPress(newProductInfo);
  };

  const selectedCategoryNameLowerCase = useMemo(() => {
    return getCategoryNameViaId(productDetails?.category_id);
  }, [productDetails]);

  const onChangeProductInfo = (e: CustomInputEvent) => {
    const { value, name } = e.target;

    if (name === 'description') {
      // hotfix: so edit product description can work
      setProductDetails((prev) => ({
        ...prev,
        [name]: value
      }));
      return;
    }

    if (name === 'ec_display_name') {
      //for updating display name of products
      setProductDetails((prev) => ({
        ...prev,
        [name]: value
      }));
      return;
    }

    setProductDetails((prev) => ({
      ...prev,
      [name]: normalizeFieldText(value) || value
    }));
  };

  const onChangeReferences = (newReferences: string[]) => {
    const referencesToInput: any = {
      target: {
        value: newReferences || [],
        name: 'references'
      }
    };
    onChangeProductInfo(referencesToInput);
  };

  const onChangeCustomSpecs = (newCustomSpecs: ProductCustomSpecifications) => {
    const referencesToInput: any = {
      target: {
        value: newCustomSpecs,
        name: 'specification'
      }
    };
    onChangeProductInfo(referencesToInput);
  };

  useEffect(() => {
    return () => {
      resetProductPartsStates();
    };
  }, [resetProductPartsStates]);

  usePrompt(
    'There are some unsaved changes. Are you sure you want to leave?',
    !isEqual(productDetailsOrig, productDetails)
  );

  const saveImgInChronologicalOrder = useCallback(
    async (prodImages?: ProductImage[]) => {
      const imageFilePathArr: any = cloneDeep(prodImages)?.map(
        (item) => item?.image_filepath
      );

      try {
        const response = unwrapResult(
          await dispatch(
            productActions?.patchChronologicalOrderImageThunk({
              product_id: memoizedProductId,
              images: imageFilePathArr || []
            })
          )
        );

        if (response.success) {
          snackBar.show({
            severity: 'success',
            message: 'Successfully change image order'
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, memoizedProductId, snackBar]
  );

  useEffect(() => {
    const warranty = productDetails?.warranty_duration;
    if (warranty !== undefined && warranty >= 0) {
      setSelectedWarranty(warranties.find((i) => i.value === warranty) || null);
    }
  }, [productDetails]);

  return (
    <Page className={classes.root} title="Product Information">
      <Container maxWidth={false}>
        <Typography variant="h2">Product Information</Typography>
        <LoaderBar isLoading={isLoading} />
        <Box hidden={isLoading} my={2}>
          <Card className={classes.root}>
            <ProductInfoSummary productInfo={productDetails} />

            <ProductGeneralInfo
              productInfo={productDetails}
              onChangeValue={onChangeProductInfo}
            />

            <ProductInfoPricing
              setSelectedWarranty={setSelectedWarranty}
              selectedWarranty={selectedWarranty}
              productInfo={productDetails}
              onChangeValue={onChangeProductInfo}
            />

            {selectedCategoryNameLowerCase ? (
              <div>
                <ProductPartDetails
                  productCategory={selectedCategoryNameLowerCase}
                />
              </div>
            ) : null}

            <AddProductReferences
              data={productDetails?.references}
              onReferenceChange={(data) => onChangeReferences(data)}
            />

            <ProductImagesInfo
              productId={memoizedProductId}
              data={productImages || []}
              isLoading={productImagesLoading}
              isImageUploading={isImageUploading}
              imgUploadProgress={imgUploadProgress}
              onPhotosChange={(imgs) => setProductImages(imgs)}
              onChangeImgOrder={(imgList: ProductImage[]) =>
                saveImgInChronologicalOrder(imgList)
              }
              hasNewImageAdded={hasBase64Image}
            />

            <ProductInfoCustomSpecs
              onChangeCustomSpecs={onChangeCustomSpecs}
              customSpecs={productDetails?.specification}
            />

            {canUpdateProductDescription && (
              <Box sx={{ p: 3 }}>
                <Button
                  onClick={() =>
                    setIsOpenProdDescription(!isOpenProdDescription)
                  }
                  variant="outlined"
                  color="primary"
                  fullWidth
                  style={{ padding: '1rem' }}
                >
                  CREATE/UPDATE PRODUCT DESCRIPTION
                </Button>
              </Box>
            )}
            <ProductInfoFooter
              onDeleteProduct={onDeleteProductPress}
              onSaveDetail={onSaveDetailValidate}
            />
          </Card>
        </Box>
      </Container>

      <AlertDialog
        title={`Delete ${productDetails?.name}`}
        customButtons={
          <>
            <Button onClick={deleteThisItem} color="secondary" autoFocus>
              Delete
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </>
        }
        subTitle={`Are you sure you want to delete ${productDetails?.name}`}
        isVisible={deleteTransactionDialog}
      />

      <ProductDescriptionModal
        isOpen={isOpenProdDescription}
        productId={productDetails?.id}
        description={decodedHtmlDescription}
        reloadProductDetails={getProductDetails}
        handleClose={() => setIsOpenProdDescription(false)}
      />

      <AlertDialog
        title="Invalid Product"
        isVisible={invalidProductOpen}
        subTitle={'We dont have this on our system'}
        handleClose={onInvalidProductAlertClose}
      />
    </Page>
  );
};

export default ProductInformationView;
