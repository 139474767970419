import React, { FC, memo } from 'react';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';

import { Transaction } from 'src/types';
import { formatCurrency } from 'src/utils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.up('xs')]: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'lightgray'
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'white'
      }
    }
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  label: {
    fontWeight: 'bold'
  },
  value: {
    textAlign: 'right'
  }
}));
interface Props {
  data: Transaction;
  onHandleDelete: () => void;
}
const Component: FC<Props> = ({ data, onHandleDelete }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Branch:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.branch_name || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Category:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.category_name || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Product Name:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.product_name || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Serial No.:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {data?.serial_no || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Amount:
          </Typography>
          <Typography variant="subtitle1" className={classes.value}>
            {formatCurrency(data?.amount) || '---'}
          </Typography>
        </div>
        <div className={classes.tableRow}>
          <Typography variant="subtitle1" className={classes.label}>
            Acction:
          </Typography>

          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton onClick={onHandleDelete}>
              <DeleteForeverIcon color="secondary" />
            </IconButton>
          </Box>
        </div>
      </CardContent>
    </Card>
  );
};
export const AddTransactionCard = memo(Component);
