import React, { useCallback } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  Typography
} from '@material-ui/core';
import {
  AlternatingColorTableRow,
  ConditionalVisibleCell,
  LinkComponent
} from 'src/components';
import {
  copyToClipboard,
  formatCurrency,
  marginColorViaGross
} from 'src/utils';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  FastMovingProductsData,
  SortData
} from 'src/redux/slices/fast-moving-products';
import { fastMovingProductRowHeader } from 'src/constants/table';
import { SortFlag } from 'src/enums/fast-moving-products';

interface Props {
  className?: string;
  columnToHide?: number[];
  sortFlag?: SortData;
  fastMovingProducts?: FastMovingProductsData[];
  setSortFlag: (val: SortData) => void;
  productsCopy?: FastMovingProductsData[];
  setFastMovingProducts: (val: FastMovingProductsData[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 30
  },
  tableCell: {
    border: 'none',
    padding: '1rem'
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  }
}));

const component = ({
  className,
  fastMovingProducts,
  sortFlag,
  columnToHide,
  productsCopy,
  setSortFlag,
  setFastMovingProducts,
  ...rest
}: Props) => {
  const classes = useStyles();

  const columnKeyMap: { [key: string]: string } = {
    'Branches Sold': 'branches_sold',
    'Dealer Price': 'last_dealer_price',
    'Retail Price': 'last_retail_price',
    'Product Name': 'product_name',
    Gross: 'gross',
    Margin: 'margin_percentage',
    'Sold Quantity': 'sold_quantity',
    'Available Stock': 'available_stocks',
    Suppliers: 'suppliers'
  };

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  const sortNumericallyByProperty = useCallback((array, property, sortFlag) => {
    const isAscending = sortFlag === SortFlag.Ascending;
    return array.sort((a: any, b: any) =>
      isAscending ? a[property] - b[property] : b[property] - a[property]
    );
  }, []);

  const sortAlphabeticallyByProperty = useCallback((array, key, flag) => {
    const isAZ = flag === SortFlag.Ascending;
    return array.sort((a: string, b: string) => {
      const aVal = a[key]?.toLowerCase() || '';
      const bVal = b[key]?.toLowerCase() || '';
      if (aVal < bVal) return isAZ ? -1 : 1;
      if (aVal > bVal) return isAZ ? 1 : -1;
      return 0;
    });
  }, []);

  const handleSortFlags = useCallback((flag: SortFlag) => {
    switch (flag) {
      case SortFlag.Ascending:
        return SortFlag.Default;
      case SortFlag.Descending:
        return SortFlag.Ascending;
      case SortFlag.Default:
        return SortFlag.Descending;
      default:
        return SortFlag.Ascending;
    }
  }, []);

  const handleSortBy = useCallback(
    (column: string) => {
      const key = columnKeyMap[column];
      let sortedData = [...(fastMovingProducts || [])];
      let newFlagVal = handleSortFlags(sortFlag?.[key] || SortFlag.Default);
      let updatedSortFlag: { [key: string]: SortFlag } = { [key]: newFlagVal };

      const sortFunctions = {
        alphabetic: sortAlphabeticallyByProperty,
        numeric: sortNumericallyByProperty
      };

      // Columns requiring numeric sorting
      const numericColumns = [
        'Dealer Price',
        'Retail Price',
        'Gross',
        'Margin',
        'Sold Quantity',
        'Available Stock'
      ];

      const sortType = numericColumns.includes(column)
        ? 'numeric'
        : 'alphabetic';

      sortedData =
        newFlagVal === SortFlag.Default
          ? [...(productsCopy || [])]
          : sortFunctions[sortType](sortedData, key, newFlagVal);

      setSortFlag(updatedSortFlag);
      setFastMovingProducts(sortedData);
    },
    [
      columnKeyMap,
      fastMovingProducts,
      setSortFlag,
      handleSortFlags,
      productsCopy,
      setFastMovingProducts,
      sortAlphabeticallyByProperty,
      sortNumericallyByProperty,
      sortFlag
    ]
  );

  const SortIcon = useCallback(
    (column) => {
      const [key, value] = Object.entries(sortFlag || {})[0] || [null, null];

      // Return no icon for columns not listed in columnKeyMap
      if (!columnKeyMap[column]) return null;

      // Check if the current column matches the sort key
      if (key === columnKeyMap[column]) {
        switch (value) {
          case SortFlag.Ascending:
            return <ArrowDownwardIcon onClick={() => handleSortBy(column)} />;
          case SortFlag.Descending:
            return <ArrowUpwardIcon onClick={() => handleSortBy(column)} />;
          default:
            return <SwapVertIcon onClick={() => handleSortBy(column)} />;
        }
      }

      // Default icon for unsorted columns
      return <SwapVertIcon onClick={() => handleSortBy(column)} />;
    },
    [sortFlag, columnKeyMap, handleSortBy]
  );

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                {fastMovingProductRowHeader.map(
                  (rowHeader, index) =>
                    // meaning if the cell is not included in array it is visible
                    !columnToHide?.includes(index) && (
                      <TableCell
                        key={index}
                        style={{
                          padding: '1rem'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '.5em'
                          }}
                        >
                          {rowHeader}
                          {SortIcon(rowHeader)}
                        </div>
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {fastMovingProducts?.map(
                (item: FastMovingProductsData, index) => (
                  <AlternatingColorTableRow hover key={index}>
                    {/* 0, 1, 2, 3, and so on ,must be equal to the index of fastMovingProductRowHeader */}
                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(0)}
                      RenderItem={item?.branches_sold ?? '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(1)}
                      RenderItem={
                        <Box
                          display={'flex'}
                          style={{ alignItems: 'center', columnGap: '.5em' }}
                        >
                          <FileCopyIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              onHandleCopyProductName(item?.product_name)
                            }
                          />
                          <Box>
                            <LinkComponent
                              href={`/app/products/${item.product_id}`}
                              title={`${item?.product_name ?? '--'}`}
                            />
                          </Box>
                        </Box>
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(2)}
                      RenderItem={
                        formatCurrency(item?.last_dealer_price) || '--'
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(3)}
                      RenderItem={
                        formatCurrency(item?.last_retail_price) || '--'
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(4)}
                      RenderItem={formatCurrency(item?.gross) || '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(5)}
                      RenderItem={
                        <Typography
                          style={{
                            color: marginColorViaGross(item?.margin_percentage)
                          }}
                        >
                          {item?.margin_percentage}
                        </Typography>
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(6)}
                      RenderItem={item?.suppliers}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(7)}
                      RenderItem={item?.sold_quantity ?? '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(8)}
                      RenderItem={item?.available_stocks}
                    />
                  </AlternatingColorTableRow>
                )
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const FastMovingProductTableList = React.memo(component);
