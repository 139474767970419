import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { FixMeLaterApi } from 'src/types';
import { usePermissions } from 'src/hooks';

interface Props {
  manufacturers: FixMeLaterApi;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Manufacturers = ({ className, manufacturers, ...rest }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { canEditManufacturer } = usePermissions();

  const onManufacturerPress = (item: FixMeLaterApi) => {
    canEditManufacturer &&
      navigate(`/app/manufacturers/${item.id}`, {
        replace: false,
        state: item
      });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Manufacturer Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manufacturers.map((item: FixMeLaterApi) => (
                <TableRow
                  onClick={() => onManufacturerPress(item)}
                  hover
                  key={item.id}
                >
                  <TableCell>{item.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Manufacturers;
