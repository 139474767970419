import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent, FixMeLater } from 'src/types';

interface Props {
  className?: string;
  onClick: FixMeLater;
  input: string;
  onSearchCustomer: (text: string) => void;
  onToolbarInputChange: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className,
  onClick,
  input,
  onSearchCustomer,
  onToolbarInputChange,
  ...rest
}: Props) => {
  const classes = useStyles();

  const handleChange = (event: CustomInputEvent) => {
    onToolbarInputChange(event);
  };

  const onSearchClick = () => {
    onSearchCustomer(input);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchCustomer(input);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Customers
        </Typography>
        <Button onClick={onClick} color="primary" variant="contained">
          Add customer
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={12} md={3} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    value={input}
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search customer"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item lg={1} xs={4}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
