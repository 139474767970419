import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { AlertDialog, LoaderBar, Page } from 'src/components';
import { SupabaseClientService } from 'src/services';
import Toolbar from './Toolbar';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  TapTapCustomerData,
  TapTapCustomerPayment
} from 'src/redux/slices/taptap-customer/types';
import TapTapCustomerRow from './components/TapTapCustomerRow';
import AddCustomerPaymentDialog from './AddCustomerPaymentDialog';
import TapTapCustomerPaymentDetailsDialog from './TapTapCustomerPaymentDetailsDialog';
import {
  currentTapTapVersion,
  findSelectedQuickFilterViaParam,
  quickFilterViewsLabels,
  taptapGetProfilesThunk
} from 'src/redux/slices/taptap-customer';
import { snakeCaseToTitleCase } from 'src/utils';
import { colors } from 'src/constants';
import { TapTapEmptySearchView } from './components/TapTapEmptySearchView';
import TapTapCashierCustomerItem from './components/TapTapCashierCustomerItem';
import TapTapForBuildCustomerItem from './components/TapTapForBuildCustomerItem';
import TapTapReleasingCustomerItem from './components/TapTapReleasingCustomerItem';

const useStyles = makeStyles(() => ({
  root: {},
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  clearText: {
    color: 'rgb(244, 67, 54)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const {
  selectors: taptapCustomerSelectors,
  actions: taptapCustomerActions
} = slices.taptapCustomer;

const TapTapView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const customers = useAppSelector(taptapCustomerSelectors.selectCustomers);
  const taptapColumns = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerColumns
  );
  const isCustomersLoading = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerLoading
  );
  const taptapVersion = useAppSelector(
    taptapCustomerSelectors.selectTapTapVersion
  );
  const isNextPageLoading = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerNextPageLoading
  );
  const selectedFilter = useAppSelector(
    taptapCustomerSelectors.selectTaptapCustomerFilter
  );

  const [
    addCustomerPaymentViaCustomerIDDialogVisible,
    setAddCustomerPaymentViaCustomerIDDialogVisible
  ] = useState<TapTapCustomerData>();

  const [
    editCustomerPaymentViaCustomerIDDialogVisible,
    setEditCustomerPaymentViaCustomerIDDialogVisible
  ] = useState<TapTapCustomerPayment>();

  /** ============================
   * START OF SUPABASE STUFF
   ============================ */

  //  TODO: Fix any
  const onUpdateLocalCustomerPaymentDetail = async (data: any) => {
    const updatedCustomerPaymentData: TapTapCustomerPayment = data?.new ?? {};
    dispatch(
      taptapCustomerActions.updateTaptapCustomerPaymentDetails(
        updatedCustomerPaymentData
      )
    );
  };

  const onDeleteLocalCustomerPayment = (data: any) => {
    const deletedCustomerData: TapTapCustomerData = data?.old ?? {};
    dispatch(
      taptapCustomerActions.removeTaptapCustomerPayment(deletedCustomerData)
    );
  };

  //  TODO: Fix any
  const onUpdateLocalCustomerDetail = async (data: any) => {
    const updatedCustomerData: TapTapCustomerData = data?.new ?? {};
    dispatch(taptapCustomerActions.updateTaptapCustomer(updatedCustomerData));
  };

  //  TODO: Fix any
  const onUpdateLocalCustomerPaymentList = async (data: any) => {
    const newCustomerPaymentData: TapTapCustomerPayment = data?.new ?? {};

    dispatch(
      taptapCustomerActions.insertNewTaptapCustomerPayment(
        newCustomerPaymentData
      )
    );
  };

  const onInsertOnLocalCustomerList = async (data: any) => {
    const newCustomerData: TapTapCustomerData = data?.new ?? {};
    dispatch(taptapCustomerActions.insertNewTaptapCustomers(newCustomerData));
  };

  const onDeleteLocalCustomer = (data: any) => {
    const deletedCustomerData: TapTapCustomerData = data?.old ?? {};
    dispatch(taptapCustomerActions.removeTaptapCustomer(deletedCustomerData));
  };

  /** ============================
   * END OF SUPABASE STUFF
   ============================ */

  /** ============================
   * START OF COMPONENT STUFF
   ============================ */
  const fetchCustomers = () => {
    dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
  };

  const fetchCustomersNextPage = () => {
    if (isCustomersLoading || isNextPageLoading) {
      return;
    }

    dispatch(taptapCustomerActions.taptapFetchCustomersNextPageThunk());
  };

  const fetchTapTapProfiles = () => {
    dispatch(taptapGetProfilesThunk());
  };

  /** ============================
   * END OF COMPONENT STUFF
   ============================ */

  useEffect(() => {
    fetchCustomers();
    fetchTapTapProfiles();

    const customerTableChanges = SupabaseClientService.channel(
      'customers_channel'
    ) // TODO: constantize this
      .on(
        'postgres_changes', // TODO: constantize this
        {
          event: '*', // you can specify 'INSERT', 'UPDATE', 'DELETE' etc. // TODO: constantize this
          schema: 'public', // TODO: constantize this
          table: 'customers' // TODO: constantize this
        },
        (payload) => {
          if (payload?.table === 'customers') {
            if (payload?.eventType === 'INSERT') {
              onInsertOnLocalCustomerList(payload);
            }
            if (payload?.eventType === 'UPDATE') {
              onUpdateLocalCustomerDetail(payload);
            }
            if (payload?.eventType === 'DELETE') {
              onDeleteLocalCustomer(payload);
            }
          }
        }
      )
      .subscribe();

    const customersPaymentsTableChanges = SupabaseClientService.channel(
      'customers_payments_channel'
    ) // TODO: constantize this
      .on(
        'postgres_changes', // TODO: constantize this
        {
          event: '*', // you can specify 'INSERT', 'UPDATE', 'DELETE' etc. // TODO: constantize this
          schema: 'public', // TODO: constantize this
          table: 'customers_payments'
        },
        (payload) => {
          if (payload?.table === 'customers_payments') {
            console.log('customers_payments_payload', payload);
            if (payload?.eventType === 'INSERT') {
              onUpdateLocalCustomerPaymentList(payload);
            }
            if (payload?.eventType === 'UPDATE') {
              // onUpdateLocalCustomerDetail(payload);
              onUpdateLocalCustomerPaymentDetail(payload);
            }
            if (payload?.eventType === 'DELETE') {
              // TODO: Implement this
              onDeleteLocalCustomerPayment(payload);
            }
          }
        }
      )
      .subscribe();

    // Cleanup function to unsubscribe when the component unmounts
    return () => {
      SupabaseClientService.removeChannel(customerTableChanges);
      SupabaseClientService.removeChannel(customersPaymentsTableChanges);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedFilterIndicator = useMemo(() => {
    const selectedQuickFilter = findSelectedQuickFilterViaParam(selectedFilter);
    return selectedQuickFilter?.label || 'ALL ?';
  }, [selectedFilter]);

  // eslint-disable-next-line no-unused-vars
  const handleScroll = (element: any) => {
    const bottom =
      Math.abs(
        element.target.scrollHeight -
          (element.target.scrollTop + element.target.clientHeight)
      ) <= 1;

    if (bottom) {
      fetchCustomersNextPage();
    }
  };

  return (
    <Page className={classes.root} title="TapTap Customer">
      <LoaderBar isLoading={isCustomersLoading} />
      <Toolbar />

      {selectedFilterIndicator === quickFilterViewsLabels.CASHIER && (
        <div style={{ padding: 20 }}>
          <Grid
            onScroll={handleScroll}
            container
            spacing={2}
            style={{
              maxHeight: '85vh',
              overflow: 'auto',
              opacity: isCustomersLoading ? 0.3 : 1
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            {customers?.map((customer: TapTapCustomerData, index: number) => (
              <TapTapCashierCustomerItem key={`${index}`} customer={customer} />
            ))}
          </Grid>
        </div>
      )}

      {selectedFilterIndicator === quickFilterViewsLabels.FOR_BUILD && (
        <div style={{ padding: 20 }}>
          <Grid
            onScroll={handleScroll}
            container
            spacing={2}
            style={{
              maxHeight: '85vh',
              overflow: 'auto',
              opacity: isCustomersLoading ? 0.3 : 1
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            {customers?.map((customer: TapTapCustomerData, index: number) => (
              <TapTapForBuildCustomerItem
                key={`${index}`}
                customer={customer}
              />
            ))}
          </Grid>
        </div>
      )}

      {selectedFilterIndicator === quickFilterViewsLabels.RELEASING && (
        <div style={{ padding: 20 }}>
          <Grid
            onScroll={handleScroll}
            container
            spacing={2}
            style={{
              maxHeight: '85vh',
              overflow: 'auto',
              opacity: isCustomersLoading ? 0.3 : 1
            }}
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
          >
            {customers?.map((customer: TapTapCustomerData, index: number) => (
              <TapTapReleasingCustomerItem
                key={`${index}`}
                customer={customer}
              />
            ))}
          </Grid>
        </div>
      )}

      {selectedFilterIndicator !== quickFilterViewsLabels.CASHIER &&
      selectedFilterIndicator !== quickFilterViewsLabels.FOR_BUILD &&
      selectedFilterIndicator !== quickFilterViewsLabels.RELEASING ? (
        <TableContainer
          onScroll={handleScroll}
          style={{
            maxHeight: '85vh',
            overflow: 'auto',
            opacity: isCustomersLoading ? 0.3 : 1
          }}
        >
          <Table size="small">
            <TableHead
              style={{
                borderBottom: `1px solid ${colors.grey[300]}`
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    position: 'sticky',
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    backgroundColor: colors.grey[300],
                    borderRight: `1px solid ${colors.grey[300]}`
                  }}
                ></TableCell>
                {taptapColumns?.map((column) => {
                  if (column?.visible) {
                    return (
                      <TableCell
                        style={{
                          top: 0,
                          position: 'sticky',
                          left: column.sticky ? 80 : 'auto',
                          backgroundColor: column.sticky
                            ? colors.grey[300]
                            : colors.common.white,
                          zIndex: column.sticky ? 2 : 1,
                          borderBottom: `1px solid ${colors.grey[300]}`,
                          borderRight: `1px solid ${colors.grey[300]}`
                        }}
                        key={column?.field}
                      >
                        {snakeCaseToTitleCase(column?.field)}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {customers?.map((customer: TapTapCustomerData) => (
                <TapTapCustomerRow
                  key={customer?.id}
                  customer={customer}
                  onClickEditPayment={(payment) =>
                    setEditCustomerPaymentViaCustomerIDDialogVisible(payment)
                  }
                />
              ))}
              {customers?.length === 0 && <TapTapEmptySearchView />}
            </TableBody>
          </Table>
          <LoaderBar isLoading={isNextPageLoading} />
        </TableContainer>
      ) : null}

      <AddCustomerPaymentDialog
        customer={addCustomerPaymentViaCustomerIDDialogVisible}
        isVisible={addCustomerPaymentViaCustomerIDDialogVisible !== undefined}
        handleClose={() =>
          setAddCustomerPaymentViaCustomerIDDialogVisible(undefined)
        }
      />

      <TapTapCustomerPaymentDetailsDialog
        paymentDetails={editCustomerPaymentViaCustomerIDDialogVisible}
        isVisible={editCustomerPaymentViaCustomerIDDialogVisible !== undefined}
        handleClose={() =>
          setEditCustomerPaymentViaCustomerIDDialogVisible(undefined)
        }
      />

      <AlertDialog
        isVisible={!taptapVersion || taptapVersion < currentTapTapVersion}
        title="TapTap Version Outdated"
        subTitle={`Press "OK" to update. Then refresh the page please :D`}
        handleClose={() => {
          dispatch(taptapCustomerActions.resetAll());
          dispatch(taptapCustomerActions.taptapFetchCustomersThunk());
        }}
      />
    </Page>
  );
};

export default TapTapView;
