export enum ProductStatusEnum {
  Purchased = 'purchased',
  On_Hold = 'on hold',
  Available = 'available',
  Defective = 'defective'
}

export enum FilterStockEnum {
  All = 'all',
  WithStocks = 'with_stocks',
  NoStocks = 'no_stocks'
}
